import styled from "styled-components";
import { colors, fonts } from "../enums";
import { formatDate } from "../services/utils";
import Button from "./ui/Button";
import { Space, VerticalAlign } from "./ui/flex/enums";
import Flex from "./ui/flex/Flex";
import positive from '../assets/icons/positive.svg';
import negative from '../assets/icons/negative.svg';
import helpful from '../assets/icons/helpful.svg';
import share from '../assets/icons/share.svg';
import report from '../assets/icons/report.svg';
import Rating from "./ui/Rating";
import { routes } from "../App";
import CompanyService from "../services/api/CompanyService";
import { getCurrentUserId } from "../services/api/ApiConnection";

const ReviewCardComponent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: ${colors.cardBodyBackgroundGray};
`;
const ReviewCardText = styled.div<{ color?: string }>`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 57px;
    margin: 3px;
    text-transform: uppercase;
    color: ${props => props.color || colors.commentGray}; 
`;
const ReviewCardProIcon = styled.div<{ isPositive?: boolean }>`
    width: 36px;
    height: 36px;
    background-image: url(${props => props.isPositive ? positive : negative});
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center;
`;
const ReviewCardHr = styled.hr`
    height: 6px;
    background-color: ${colors.primaryYellow};
    border: none;
`;

/** Карточка отзыва о чем/ком-то */
const ReviewCard: React.FC<{
    rating: number | null | undefined,
    createdOn: Date | string | null | undefined,
    jobTitle: string | null | undefined,
    pros: string[] | null | undefined,
    cons: string[] | null | undefined,
    review: string | null | undefined,
    id: string | null | undefined,
    companyId: string,
    likedUsers: string[] | null | undefined,
    onLike?: (id: string) => void,
    /** Тип компонента для названия */
    textComponent?: keyof JSX.IntrinsicElements;
}> = ({
    rating,
    createdOn,
    jobTitle,
    pros,
    cons,
    review,
    id,
    companyId,
    likedUsers,
    onLike,
    textComponent
}) => {
        const amILiked = !!likedUsers?.find(x => x == getCurrentUserId());

        /** Скопировать URL в буфер обмена */
        const shareUrl = () => {
            navigator.clipboard.writeText(`${window.location.origin}${routes.companyById(companyId)}`);
        };

        /** Поставить лайк */
        const like = async () => {
            if (amILiked)
                return;

            await CompanyService.like(companyId ?? '', id ?? '');
            onLike && onLike(id ?? '');
        };

        return (
            <ReviewCardComponent>
                <Flex.Column margin={40}>
                    <Flex.Row vertical={VerticalAlign.center} gap={30}>
                        <ReviewCardText color={colors.primaryYellow}>{rating}</ReviewCardText>
                        <Rating value={rating} />
                    </Flex.Row>
                    <Flex.Row marginTop={20}>
                        <ReviewCardText as={textComponent ?? 'div'} color={colors.primaryBlue}>{formatDate(createdOn)} - {jobTitle}</ReviewCardText>
                    </Flex.Row>
                    <Flex.Row marginTop={20} wrap>
                        {pros?.map(x => (
                            <Flex.Row key={x} vertical={VerticalAlign.center}>
                                <ReviewCardProIcon isPositive />
                                <ReviewCardText as='h4'>{x}</ReviewCardText>
                            </Flex.Row>))}
                        {cons?.map(x => (
                            <Flex.Row key={x} vertical={VerticalAlign.center}>
                                <ReviewCardProIcon />
                                <ReviewCardText as='h4'>{x}</ReviewCardText>
                            </Flex.Row>))}
                    </Flex.Row>
                    <Flex.Row marginTop={20}>
                        <ReviewCardText as="p" color={colors.commentGray}>{review}</ReviewCardText>
                    </Flex.Row>
                    <Flex.Row marginTop={20}>
                        <ReviewCardText color={colors.primaryBlue}>Continue reading</ReviewCardText>
                    </Flex.Row>
                </Flex.Column>
                <ReviewCardHr />
                <Flex.Column margin={40}>
                    <ReviewCardText>{likedUsers?.length ?? 0} people found this review helpful</ReviewCardText>
                    <Flex.Row marginTop={20} horizontal={Space.between} vertical={VerticalAlign.center}>
                        <Button icon={helpful} variant="transparent" onClick={like}>{amILiked ? 'Helpful (liked)' : 'Helpful'}</Button>
                        <Button icon={share} variant="transparent" onClick={shareUrl}>Share</Button>
                        <Button icon={report} variant="transparent">Report</Button>
                    </Flex.Row>
                </Flex.Column>
            </ReviewCardComponent >
        );
    }
export default ReviewCard;