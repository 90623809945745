import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../enums';
import up from '../../assets/icons/up.svg';

const AccordionComponent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.cardBodyBackgroundGray};
    border-radius: 4px;
    border-radius: 20px;
`;
const AccordionHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.cardHeaderBackgroundGray};
    padding: 16px 24px;
    border-radius: 20px;
`;
const AccordionHeaderText = styled.div`
    font-family: ${fonts.main};
    color: ${colors.white};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 28.6772px;
    line-height: 42px;
`;
const AccordionHeaderIcon = styled.div<{ isOpen: boolean }>`
    transform: rotate(${props => (props.isOpen ? '0deg' : '180deg')});
    background-image: url(${up});
    height: 20px;
    width: 35px;
    background-position: center;
    background-size: cover;
`;
const AccordionBody = styled.div`
    display: flex;
    flex-direction: column;
    color: #5E5E5E;
    padding: 10px 25px;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
`;

interface AccordionProps {
    /** Отображаемые элементы */
    children: React.ReactNode;
    /** Скрыть ли стрелку */
    hiddenArrow?: boolean;
    /** Заголовок плашки */
    title: string | null | undefined;
    /** По клику - что */
    onClick?: () => void;
    /** Тип компонента для названия */
    textComponent?: keyof JSX.IntrinsicElements;
}

const Accordion: React.FC<AccordionProps> = ({
    children,
    title,
    onClick,
    hiddenArrow,
    textComponent
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <AccordionComponent onClick={onClick}>
            <AccordionHeader onClick={() => setIsOpen(!isOpen)}>
                <AccordionHeaderText as={textComponent ?? 'div'}>{title ?? ''}</AccordionHeaderText>
                {!hiddenArrow && (<AccordionHeaderIcon isOpen={isOpen} />)}
            </AccordionHeader>
            {isOpen && <AccordionBody>
                {children}
            </AccordionBody>}
        </AccordionComponent>
    );
};

export default Accordion;
