import { Space, VerticalAlign } from "../components/ui/flex/enums";
import { useEffect, useState } from "react";
import Flex from "../components/ui/flex/Flex";
import { Catalogs, timeZones } from "../enums";
import { SelectOptionsType } from "../components/ui/Select";
import { Size } from "../components/ui/Input";
import Modal, { ModalRow, ModalSelect, ModalInput, ModalFooterButton } from "../components/Modal";
import CatalogService from "../services/api/CatalogService";
import DriverService, { UpdateDriverRequest } from "../services/api/DriverService";

const timeZonesOptions = timeZones.map(x => ({ label: x.name, value: x.id.toString() }));

const DriverAccountPersonalInfoEditModal: React.FC<{
    id: string,
    setIsOpen?: (isOpen: boolean) => void
}> = (props) => {
    // Список штатов
    const [regions, setRegions] = useState<SelectOptionsType[] | undefined>();

    // Информация об аккаунте водителя
    const [entity, setEntity] = useState<UpdateDriverRequest>({});
    const [confirmedPassword, setConfirmedPassword] = useState<string | undefined>();

    /**
     * Метод инициализации страницы.
     */
    const initialize = async () => {
        // Подгружаем список штатов
        const response = await CatalogService.getById(Catalogs.Regions);
        setRegions(response?.values?.map(x => ({ label: x.name || '', value: x.id || '' })));

        // Подгружаем профиль пользователя
        const entityResponse = await DriverService.getById(props.id);
        setEntity(entityResponse);
    };

    /**
     * Обновить данные о профиле пользователя
     */
    const update = async () => {
        try {
            const validationError = validateConfirmedPassword();
            if (validationError)
                throw validationError;

            // setFormState(x => x.clear());
            await DriverService.update(entity);
            props.setIsOpen && props.setIsOpen(false);
        } catch (error) {
            // setFormState(x => x.setError(error));
        } finally {
            // setFormState(x => x.setLoading(false));
        }
    };

    /**
     * Валидировать совпадение пароля и его подтверждения.
     * @returns Ошибка.
     */
    const validateConfirmedPassword = () => {
        // Если не вводили значения обоих полей с паролем, не проверяем ничего
        if (!entity || !entity.password && !confirmedPassword)
            return;

        if (entity.password !== confirmedPassword)
            return "Pasword and confirmed password does not match";
    };

    useEffect(() => { initialize(); }, []);

    return (
        <Modal headerText="Edit driver information" setIsOpen={props.setIsOpen}>
            <Flex.Column vertical={Space.between} margin={20}>
                <Flex.Column>
                    <ModalRow>
                        <ModalInput
                            label='First name'
                            placeholder='First name'
                            value={entity?.firstName ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, firstName: x })}
                            size={Size.S} />

                        <ModalInput
                            label='Phone number'
                            placeholder='Phone number'
                            value={entity?.phone ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, phone: x })}
                            size={Size.S} />

                        <ModalInput
                            label='City'
                            placeholder='City'
                            value={entity?.city ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, city: x })}
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <ModalInput
                            label='Last name'
                            placeholder='Last name'
                            value={entity?.lastName ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, lastName: x })}
                            size={Size.S} />

                        <ModalInput
                            label='E-mail address'
                            placeholder='E-mail address'
                            value={entity?.email ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, email: x })}
                            size={Size.S} />

                        <ModalSelect
                            label="State"
                            variant="primary"
                            options={regions ?? []}
                            value={regions?.find(x => x.value == entity.regionId)}
                            onChangeValue={x => setEntity({ ...entity, regionId: x?.value })}
                            placeholder='State'
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <ModalInput
                            inputType="date"
                            label='Date of birth'
                            value={entity.bornOn instanceof Date ? entity.bornOn.toDateString() : entity.bornOn || ''}
                            onChangeValue={x => setEntity({ ...entity, bornOn: x ? new Date(x) : undefined })}
                            size={Size.S} />

                        <ModalInput
                            label='Address'
                            placeholder='Address'
                            value={entity?.address ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, address: x })}
                            size={Size.S} />

                        <ModalInput
                            label='Zip'
                            placeholder='Zip'
                            value={entity?.zip ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, zip: x })}
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <ModalSelect
                            label="Timezone"
                            variant="primary"
                            options={timeZonesOptions ?? []}
                            value={timeZonesOptions?.find(x => x.value == entity.timeZone?.toString())}
                            onChangeValue={x => setEntity({ ...entity, timeZone: x && parseInt(x.value) || undefined })}
                            placeholder='Timezone'
                            size={Size.S} />

                        <ModalInput
                            inputType="password"
                            label='New password'
                            placeholder='New password'
                            value={entity?.password ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, password: x })}
                            size={Size.S} />

                        <ModalInput
                            inputType="password"
                            label='Confirm new password'
                            placeholder='Confirm new password'
                            value={confirmedPassword}
                            onChangeValue={x => setConfirmedPassword(x)}
                            size={Size.S} />
                    </ModalRow>

                </Flex.Column>

                <Flex.Row horizontal={Space.between} vertical={VerticalAlign.center} flex={0}>
                    <ModalFooterButton variant="dark" onClick={() => props.setIsOpen && props.setIsOpen(false)}>Cancel</ModalFooterButton>
                    <ModalFooterButton variant="primary" onClick={update}>Save</ModalFooterButton>
                </Flex.Row>
            </Flex.Column>
        </Modal>
    );
};

export default DriverAccountPersonalInfoEditModal;