import { ApiConnection } from './ApiConnection';

/** Сервис для запросов справочников */
class CatalogService {
    /** Базовый URL для сервиса */
    static get RoutePrefix(): string {
        return '/api/v1.0/Catalog';
    }

    /** Получить список сущностей по фильтру */
    static async getById(id: string): Promise<GetCatalogByIdResponse> {
        const response = await ApiConnection.get<GetCatalogByIdResponse>(`${this.RoutePrefix}/${id}`);
        return response.data;
    }
}
export default CatalogService;

/** Ответ на запрос получения справочника */
export interface GetCatalogByIdResponse {
    /**
     * Список значений
     * @type {Array<GetCatalogByIdResponseValue>}
     * @memberof GetCatalogByIdResponse
     */
    values?: Array<GetCatalogByIdResponseValue> | null;
    /**
     * Название
     * @type {string}
     * @memberof GetCatalogByIdResponse
     */
    name?: string | null | undefined;
}

/**
 * Ответ на запрос списка вGetCatalogByIdResponseValueакансий
 * @export
 * @interface 
 */
export interface GetCatalogByIdResponseValue {
    /**
     * ИД
     * @type {string}
     * @memberof GetCatalogByIdResponseValue
     */
    id?: string;
    /**
     * Название
     * @type {string}
     * @memberof GetCatalogByIdResponseValue
     */
    name?: string;
}
