import { HorizontalAlign, Space, VerticalAlign } from "./ui/flex/enums";
import Flex from "./ui/flex/Flex";
import styled from "styled-components";
import { cdlTypes, colors, fonts, jobTypes, truckTypes } from "../enums";
import close from '../assets/icons/close.svg';
import Button from "./ui/Button";
import { useEffect } from 'react';
import Select, { SelectOptionsType } from "../components/ui/Select";
import Radio from "../components/ui/Radio";
import Input, { Size } from "../components/ui/Input";

const ModalBackground = styled.div`
    background: rgba(33, 30, 30, 0.501961);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
`;

const ModalWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
`;

const ModalConainer = styled.div<{ width?: number, height?: number }>`
    width: ${props => props.width ? `${props.width}px` : '1000px'};
    height: ${props => props.height ? `${props.height}px` : '600px'};
    background: white;
    border-radius: 20px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.04);
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px;
    align-items: center;
    justify-content: space-between;
`;

const ModalHeaderText = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-transform: uppercase;
    color: ${colors.primaryBlue};
`;

const ModalHeaderCloseButton = styled.div`
    cursor: pointer;
    border: none;
    background-image: url(${close});
    background-size: contain;
    height: 30px;
    width: 30px;
`;

export const ModalRow = styled(Flex.Row)`
    flex: 0;
    margin: 0 0 20px 0;
    justify-content: space-between;
    align-items: center;

    & > * {
        margin: 0 20px;
    }
`;

export const ModalButton = styled(Button)`
    font-size: 16px;
    line-height: normal;
    padding-top: 6px;
    padding-bottom: 6px;
    align-self: end;
`;

export const ModalFooterButton = styled(Button)`
    flex: 1;
    margin: 20px;
`;

export const ModalSelect = styled(Select)`
    margin: 0 20px;
`;

export const ModalInput = styled(Input)`
    margin: 0 20px;
    max-width: none;
`;

const Modal: React.FC<{
    setIsOpen?: (isOpen: boolean) => void,
    headerText: string | null | undefined,
    children: React.ReactNode
}> = (props) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        const close = (e: any) => {
            if (e.keyCode === 27) {
                props.setIsOpen && props.setIsOpen(false);
            }
        };
        window.addEventListener('keydown', close);

        return function () {
            document.body.style.overflow = 'unset';
            window.removeEventListener('keydown', close);
        }
    }, []);

    return (
        <>
            <ModalBackground onClick={() => props.setIsOpen && props.setIsOpen(false)}></ModalBackground>
            <ModalWrapper>
                <ModalConainer>
                    <ModalHeader>
                        <ModalHeaderText>{props.headerText}</ModalHeaderText>
                        <ModalHeaderCloseButton onClick={() => props.setIsOpen && props.setIsOpen(false)}></ModalHeaderCloseButton>
                    </ModalHeader>

                    {props.children}

                </ModalConainer>
            </ModalWrapper>
        </>
    );

};

export default Modal;