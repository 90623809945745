import { Space, VerticalAlign } from "../components/ui/flex/enums";
import { useEffect, useState } from "react";
import Flex from "../components/ui/flex/Flex";
import { Catalogs, cdlTypes, colors, jobTypes, routeTypes, truckTypes, endorsements } from "../enums";
import { SelectOptionsType } from "../components/ui/Select";
import { Size } from "../components/ui/Input";
import Modal, { ModalRow, ModalSelect, ModalInput, ModalFooterButton } from "../components/Modal";
import { BooleanRadio, RadioOptionsAlign } from "../components/ui/Radio";
import CatalogService from "../services/api/CatalogService";
import DriverService, { UpdateDriverRequest } from "../services/api/DriverService";

const endorsementsOptions = endorsements.map(x => ({ label: x.name, value: x.id.toString() }));
const drivingLicensesOptions = cdlTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const jobTypesOptions = jobTypes.map(x => ({ label: x.name, value: x.id.toString() }));

const DriverAccountDriverInfoEditModal: React.FC<{
    id: string,
    setIsOpen?: (isOpen: boolean) => void
}> = (props) => {
    // Список штатов
    const [regions, setRegions] = useState<SelectOptionsType[] | undefined>();

    // Информация об аккаунте водителя
    const [entity, setEntity] = useState<UpdateDriverRequest>({});

    /**
     * Метод инициализации страницы.
     */
    const initialize = async () => {
        // Подгружаем список штатов
        const response = await CatalogService.getById(Catalogs.Regions);
        setRegions(response?.values?.map(x => ({ label: x.name || '', value: x.id || '' })));

        // Подгружаем профиль пользователя
        const entityResponse = await DriverService.getById(props.id);
        setEntity(entityResponse);
    };

    /**
     * Обновить данные о профиле пользователя
     */
    const update = async () => {
        try {
            // setFormState(x => x.clear());
            await DriverService.update(entity);
            props.setIsOpen && props.setIsOpen(false);
        } catch (error) {
            // setFormState(x => x.setError(error));
        } finally {
            // setFormState(x => x.setLoading(false));
        }
    };

    useEffect(() => { initialize(); }, []);

    return (
        <Modal headerText="Edit driver information" setIsOpen={props.setIsOpen}>
            <Flex.Column vertical={Space.between} margin={20}>
                <Flex.Column>
                    <ModalRow>
                        <ModalSelect
                            variant="primary"
                            label='Driver type'
                            options={jobTypesOptions ?? []}
                            value={jobTypesOptions?.find(x => x.value == entity.jobType?.toString())}
                            onChangeValue={x => setEntity({ ...entity, jobType: x && parseInt(x.value) || undefined })}
                            placeholder='Driver type'
                            size={Size.S} />

                        <ModalSelect
                            label='Endorsements'
                            variant="primary"
                            options={endorsementsOptions ?? []}
                            value={endorsementsOptions?.find(x => x.value == entity.endorsements?.toString())}
                            onChangeValue={x => setEntity({ ...entity, endorsements: x && parseInt(x.value) || undefined })}
                            placeholder='Endorsements'
                            size={Size.S} />

                        <ModalInput
                            label='License number'
                            placeholder='License number'
                            value={entity?.licenseNumber ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, licenseNumber: x })}
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <ModalSelect
                            label="License state"
                            variant="primary"
                            options={regions ?? []}
                            value={regions?.find(x => x.value == entity.licenseRegionId)}
                            onChangeValue={x => setEntity({ ...entity, licenseRegionId: x?.value })}
                            placeholder='License state'
                            size={Size.S} />

                        <ModalSelect
                            variant="primary"
                            label='License class'
                            options={drivingLicensesOptions ?? []}
                            value={drivingLicensesOptions?.find(x => x.value == entity.drivingLicenseCategory?.toString())}
                            onChangeValue={x => setEntity({ ...entity, drivingLicenseCategory: x && parseInt(x.value) || undefined })}
                            placeholder='License class'
                            size={Size.S} />

                        <ModalInput
                            inputType="number"
                            label='Preventable accidents (past 3 years)'
                            placeholder='Preventable accidents'
                            value={entity?.preventableAccidents ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, preventableAccidents: x && parseInt(x) || null })}
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <ModalInput
                            inputType="number"
                            label='Moving violations (past 3 years)'
                            placeholder='Moving violations'
                            value={entity?.movingViolations ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, movingViolations: x && parseInt(x) || null })}
                            size={Size.S} />

                        <ModalInput
                            inputType="number"
                            label='Year of last license suspension'
                            placeholder='Year of last license suspension'
                            value={entity?.licenseSuspensionYear ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, licenseSuspensionYear: x && parseInt(x) || null })}
                            size={Size.S} />

                        <ModalInput
                            inputType="number"
                            label='Year of last DUI/DWI conviction'
                            placeholder='Year of last DUI/DWI conviction'
                            value={entity?.convictionYear ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, convictionYear: x && parseInt(x) || null })}
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <BooleanRadio
                            label="Do you have a twic card"
                            size={Size.S}
                            value={entity?.hasTwicCard ?? false}
                            onChange={(x) => setEntity({ ...entity, hasTwicCard: x })}
                            align={RadioOptionsAlign.horizontal}
                        ></BooleanRadio>
                    </ModalRow>
                </Flex.Column>

                <Flex.Row horizontal={Space.between} vertical={VerticalAlign.center} flex={0}>
                    <ModalFooterButton variant="dark" onClick={() => props.setIsOpen && props.setIsOpen(false)}>Cancel</ModalFooterButton>
                    <ModalFooterButton variant="primary" onClick={update}>Save</ModalFooterButton>
                </Flex.Row>
            </Flex.Column>
        </Modal>
    );
};

export default DriverAccountDriverInfoEditModal;