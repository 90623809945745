import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../enums';

interface Props {
    /** Текѝт ошибки */
    text: string | undefined;
}

/** Стилизациѝ компонента */
const ErrorMessageComponent = styled.div<Omit<Props, 'text'>>`
  margin: 8px 0px;
  color: ${colors.errorTextColor};
  font: ${(props) => {
        // switch (props.size) {
        // case 'l':
        //     return `16px/20px ${fonts.main};`;
        // default:
        return `11px/13px  ${fonts.main};`;
    }
    }};
  font-weight: 500;
  text-align: left;
`;

/** Компонент длѝ отображениѝ текѝта ошибки */
const ErrorMessage: React.FC<Props> = ({
    text
}) => (
    <ErrorMessageComponent>
        {text}
    </ErrorMessageComponent>
);

export default ErrorMessage;
