import styled from "styled-components";
import { useEffect, useState } from "react";
import { Catalogs, cdlTypes, colors, fonts, jobTypes, routeTypes, truckTypes } from "../enums";
import Input from "../components/ui/Input";
import Flex from "../components/ui/flex/Flex";
import Button from "../components/ui/Button";
import { Space, VerticalAlign } from "../components/ui/flex/enums";
import { FormState } from "../types";
import AuthenticationService, { LoginRequest } from "../services/api/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { routes } from "../App";
import CompanyService, { CreateCompanyRequest } from "../services/api/CompanyService";
import Select, { SelectOptionsType } from "../components/ui/Select";
import CatalogService from "../services/api/CatalogService";
import DriverService, { CreateDriverRequest } from "../services/api/DriverService";
import { formatDate } from "../services/utils";

const RegisterAsDriverPageComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
const RegisterAsDriverPageCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    max-width: 1200px;
    flex: 1;
`;
const RegisterAsDriverPageError = styled.div`
    display: flex;
    color: ${colors.errorTextColor};
    text-transform: uppercase;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
`;

const jobTypesOptions = jobTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const drivingLicensesOptions = cdlTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const routeTypesOptions = routeTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const truckTypesOptions = truckTypes.map(x => ({ label: x.name, value: x.id.toString() }));


/** Страница с регистрацией водителя */
const RegisterAsDriverPage: React.FC = () => {
    const navigate = useNavigate();
    const [regions, setRegions] = useState<SelectOptionsType[] | undefined>();
    const [entity, setEntity] = useState<CreateDriverRequest>({});
    const [formState, setFormState] = useState<FormState>(FormState.Empty());

    const initialize = async () => {
        const response = await CatalogService.getById(Catalogs.Regions);
        setRegions(response?.values?.map(x => ({ label: x.name || '', value: x.id || '' })));
    };

    /** Сохранить водителя */
    const save = async () => {
        try {
            setFormState(x => x.clear());
            await DriverService.create({
                ...entity,
                name: `${entity.firstName} ${entity.lastName}`
            });
            navigate(routes.main);
        } catch (error) {
            setFormState(x => x.setError(error));
        } finally {
            setFormState(x => x.setLoading(false));
        }
    };

    useEffect(() => { initialize(); }, []);

    return (
        <RegisterAsDriverPageComponent>
            <RegisterAsDriverPageCard>
                <Flex.Column gap={20} flex={1}>
                    <Input
                        label="Login"
                        isRequired
                        value={entity.login || ''}
                        onChangeValue={x => setEntity({ ...entity, login: x })} />
                    <Input
                        label="Password"
                        inputType="password"
                        isRequired
                        value={entity.password || ''}
                        onChangeValue={x => setEntity({ ...entity, password: x })} />
                    <Input
                        label="First Name"
                        value={entity.firstName || ''}
                        onChangeValue={x => setEntity({ ...entity, firstName: x })} />
                    <Input
                        label="Last Name"
                        value={entity.lastName || ''}
                        onChangeValue={x => setEntity({ ...entity, lastName: x })} />
                    <Input
                        label="Born on"
                        inputType="date"
                        value={entity.bornOn instanceof Date ? entity.bornOn.toDateString() : entity.bornOn || ''}
                        onChangeValue={x => setEntity({ ...entity, bornOn: x })} />
                    <Select
                        label="States"
                        value={regions?.find(x => x.value == entity.regionId)}
                        options={regions ?? []}
                        onChangeValue={x => setEntity({ ...entity, regionId: x?.value })} />
                    <Input
                        label="City"
                        value={entity.city || ''}
                        onChangeValue={x => setEntity({ ...entity, city: x })} />
                    <Input
                        label="Zip"
                        value={entity.zip || ''}
                        onChangeValue={x => setEntity({ ...entity, zip: x })} />
                    <Select
                        label="License state"
                        value={regions?.find(x => x.value == entity.licenseRegionId)}
                        options={regions ?? []}
                        onChangeValue={x => setEntity({ ...entity, licenseRegionId: x?.value })} />
                    <Input
                        inputType="number"
                        label="License suspension year"
                        value={entity.licenseSuspensionYear || ''}
                        onChangeValue={x => setEntity({ ...entity, licenseSuspensionYear: x && parseInt(x) || null })} />
                    <Input
                        label="License Number"
                        value={entity.licenseNumber || ''}
                        onChangeValue={x => setEntity({ ...entity, licenseNumber: x })} />

                    <Select
                        label="Job type"
                        value={jobTypesOptions?.find(x => x.value == entity.jobType?.toString())}
                        options={jobTypesOptions ?? []}
                        onChangeValue={x => setEntity({ ...entity, jobType: x && parseInt(x.value) || undefined })} />
                    <Select
                        label="Driving License Category"
                        value={drivingLicensesOptions?.find(x => x.value == entity.drivingLicenseCategory?.toString())}
                        options={drivingLicensesOptions ?? []}
                        onChangeValue={x => setEntity({ ...entity, drivingLicenseCategory: x && parseInt(x.value) || undefined })} />
                    <Select
                        label="Preferred route types"
                        value={routeTypesOptions?.filter(x => entity.preferredRouteTypes?.find(rt => x.value == rt.toString()))}
                        options={routeTypesOptions ?? []}
                        onMultiSelect={x => setEntity({ ...entity, preferredRouteTypes: x && x.map(rt => parseInt(rt.value)) || undefined })} />
                    <Select
                        label="Preferred truck types"
                        value={truckTypesOptions?.filter(x => entity.preferredTruckTypes?.find(rt => x.value == rt.toString()))}
                        options={truckTypesOptions ?? []}
                        onMultiSelect={x => setEntity({ ...entity, preferredTruckTypes: x && x.map(rt => parseInt(rt.value)) || undefined })} />
                    <Input
                        label="Phone"
                        value={entity.phone || ''}
                        onChangeValue={x => setEntity({ ...entity, phone: x })} />
                    <Input
                        label="Email"
                        value={entity.email || ''}
                        onChangeValue={x => setEntity({ ...entity, email: x })} />
                    <Input
                        label="Address"
                        value={entity.address || ''}
                        onChangeValue={x => setEntity({ ...entity, address: x })} />
                    {formState.error && <RegisterAsDriverPageError>{formState.error}</RegisterAsDriverPageError>}
                    <Flex.Row horizontal={Space.between} gap={80}>
                        <Button variant="primary" onClick={save}>Register</Button>
                        <Button variant="transparent" onClick={() => navigate(routes.login)}>Sign in</Button>
                    </Flex.Row>
                </Flex.Column>
            </RegisterAsDriverPageCard>
        </RegisterAsDriverPageComponent >
    );
};

export default RegisterAsDriverPage;