import React, {
    FC, forwardRef, useImperativeHandle, useMemo, useState,
} from 'react';
import ReactSelect, {
    GroupBase, MenuListProps, SingleValue, StylesConfig,
} from 'react-select';
import styled from 'styled-components';
import { colors, fonts } from '../../enums';
import ErrorMessage from './ErrorMessage';
import Flex from './flex/Flex';
import { Label, Size, SizeUtility, WithValidation } from './Input';
import CreatableSelect from 'react-select/creatable';

/** Типы данных в объекте для опции компонента SelectInput */
export type SelectOptionsType = {
    /** Текст заголовка опции */
    label: string;
    /** применяем как идентификатор опции */
    value: string;
    /** Остальные вспомогательные поля */
    [x: string]: string | number | boolean | null | undefined;
};

export const InputContainer = styled(Flex.Row) <{ showError?: boolean; isButton?: boolean, variant: Variants }>`
    flex: initial;
    position: relative;
    align-items: center;
    border-radius: 3px;
    background-color: ${props => props.variant == 'primary' ? colors.selectBackgroundGray : colors.primaryBlue};
    padding-right: 15px;
    border: ${(props) => (props.showError ? `1px solid ${colors.inputErrorBorder}` : 'none')};
`;

type Variants = 'primary' | 'dark';

interface Props {
    /** Цвета */
    variant?: Variants;
    /** Размер */
    size?: Size | null | undefined;
    /** Все опции */
    options?: SelectOptionsType[] | undefined;
    /** При создании нового тэга */
    onCreateOption?: (option: SelectOptionsType) => any | undefined;
    /** Валидация */
    validateValue?: () => string | undefined;
    /** Заполнитель */
    placeholder?: string;
    /** Действие на выбор опции */
    onChangeValue?: (value: SelectOptionsType | null) => void;
    /** Если мультиселект */
    onMultiSelect?: (value: SelectOptionsType[]) => void;
    /** Значение */
    value?: SelectOptionsType | SelectOptionsType[] | null | undefined;
    /** Отображение с чекбоксами или без */
    isCheckboxVariant?: boolean;
    /** Кнопка с выпадающим селектом */
    isButtonSelect?: boolean;
    /** обработчик фокуса */
    onFocus?: () => void;
    /** обработчик выхода из фокуса */
    onBlur?: () => void;
    /** Заголовок селекта */
    label?: string | React.ReactNode;
    /** Селект используется в таблице */
    isTableSelect?: boolean;
    /** кастомное меню */
    customMenuList?: FC<MenuListProps<SelectOptionsType, boolean, GroupBase<SelectOptionsType>>>;
    /** только для чтения */
    isDisabled?: boolean;
    /** Видимость кнопок */
    showDropdownButton?: boolean;
    /** Используется в качестве кнопки фильтрации */
    isFilter?: boolean;
    /** можно ли очистить */
    isClearable?: boolean;
    /** цвет обводки селекта */
    selectBorderColor?: string;
    /** Показывать рамку ошибки */
    showErrorBorder?: boolean;
    /** Класс компонента для возможности переопределения */
    className?: string;
}

/** Компонент обычного селекта */
const Select = forwardRef<WithValidation, Props>(
    (
        {
            variant = 'primary',
            size,
            options = [],
            validateValue,
            placeholder,
            onChangeValue,
            value,
            isCheckboxVariant,
            isButtonSelect,
            onMultiSelect,
            onCreateOption,
            showDropdownButton,
            onFocus,
            isTableSelect = false,
            onBlur,
            label,
            customMenuList,
            isDisabled = false,
            isFilter,
            isClearable = false,
            selectBorderColor,
            showErrorBorder,
            className,
        },
        ref,
    ) => {
        /** Видимость ошибки */
        const [showError, setShowError] = useState(false);
        /** Текст ошибки, если есть ошибка */
        const [error, setError] = useState<string | undefined>(
            validateValue ? validateValue() : undefined,
        );

        /** Функция для изменения видимости ошибки, если есть ошибка */
        const refreshError = () => {
            const err = validateValue ? validateValue() : undefined;
            setError(err);
            setShowError(!!err);
            return err;
        };

        /** Кастомное поведение для ref */
        useImperativeHandle(ref, () => ({
            validate() {
                return refreshError();
            },
        }));

        /** Кастомная стилизация компонента react-select */
        const InputStyles = (props: Props): StylesConfig<SelectOptionsType, boolean> => ({
            option: (styles) => ({
                ...styles,
                color: colors.commentGray,
                padding: '5px 14px',
                whiteSpace: 'pre-wrap',
                borderBottom: `none`,
                fontWeight: 400,
                textTransform: 'uppercase',
                fontSize: SizeUtility.getInputFontSize(size),
                '&:hover': {
                    color: colors.primaryYellow,
                    backgroundColor: '#ECECEC'
                },
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            clearIndicator: (styles) => ({
                ...styles,
                padding: 0,
                display: isButtonSelect ? 'none' : 'initial',
            }),
            menu: (styles, state) => {
                if (state.options.length || state.isLoading || state.selectProps.inputValue) {
                    return {
                        ...styles,
                        marginTop: 1,
                        paddingTop: 0,
                        display: 'initial',
                        boxShadow: 'none',
                        overflow: 'hidden',
                        minWidth: isButtonSelect ? '250px' : 'max(100%, 233px)',
                        bottom: isTableSelect ? 'unset' : 'initial',
                        top: isTableSelect ? '100%' : 'unset',
                        backgroundColor: '#ECECEC',
                        borderRadius: 0,
                    };
                }
                return {
                    display: 'none',
                    marginTop: 0
                };
            },
            menuList: (styles) => ({
                ...styles,
                marginTop: 0,
                paddingTop: 0,
                '::-webkit-scrollbar-thumb': {
                    width: '5px',
                    height: '5px',
                    background: colors.shadowGray,
                },
                '::-webkit-scrollbar': {
                    width: '5px',
                    height: '5px',
                    backgroundColor: 'transparent',
                },
            }),
            placeholder: (styles) => ({
                ...styles,
                whiteSpace: 'nowrap',
                color: props.variant == 'primary' ? colors.commentGray : colors.white,
                font: isButtonSelect ? `400 14px/17px ${fonts.main}` : 'inherit',
            }),
            input: (styles) => ({
                ...styles,
                padding: 0,
                margin: 0,
                whiteSpace: 'nowrap',
                textOverflow: 'initial',
            }),
            singleValue: (styles) => ({
                ...styles,
                whiteSpace: 'nowrap',
                color: props.variant == 'primary' ? colors.commentGray : colors.white,
            }),
            valueContainer: (styles) => ({
                ...styles,
                padding: 0,
            }),
            container: (styles) => ({
                ...styles,
                flex: 1,
                margin: '1px',
            }),
            multiValue: (styles) => ({
                ...styles,
                padding: 0,
                backgroundColor: colors.white,
                borderRadius: '5px',
            }),
            multiValueRemove: (styles) => ({
                ...styles,
            }),
            control: (styles) => ({
                ...styles,
                borderRadius: '3px',
                padding: isButtonSelect ? 0 : '5px 0px 5px 5px',
                backgroundColor:
                    isButtonSelect || isTableSelect ? colors.transparent : (props.variant == 'primary' ? colors.selectBackgroundGray : colors.primaryBlue),
                font: `400 ${SizeUtility.getInputFontSize(size)}px ${fonts.main}`,
                textTransform: 'uppercase',
                border: 'none',
                minHeight: 'initial',
                cursor: 'pointer',
                boxShadow: 'none',
                whiteSpace: 'nowrap',
            }),
            indicatorSeparator: (styles) => ({
                ...styles,
                display: 'none',
            }),
            group: styles => ({
                ...styles,
                marginTop: 0,
                paddingTop: 0,
            }),
            indicatorsContainer: (styles) => ({
                ...styles,
                marginRight: '-4px',
                marginLeft: '5px',
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                padding: 0,
                svg: {
                    fill: props.variant == 'primary' ? '#5E5E5E' : colors.white
                },
                display:
                    (isButtonSelect || isDisabled || !showDropdownButton) && !options ? 'none' : 'initial',
            }),
        });

        const components = useMemo(() => {
            let result = {};
            if (customMenuList) {
                result = { ...result, MenuList: customMenuList };
            }
            if (!Object.keys(result)) {
                return undefined;
            }
            return result;
        }, [isCheckboxVariant, customMenuList, isFilter]);

        return (
            <Flex.Column className={className}>
                {label && <Label size={size}>{label}</Label>}
                <InputContainer
                    variant={variant}
                    isButton={isButtonSelect}
                    showError={showError || showErrorBorder}
                    style={{ border: selectBorderColor ? `2px solid ${selectBorderColor}` : undefined }}
                >
                    <CreatableSelect
                        closeMenuOnScroll={(e) => !!e.bubbles}
                        closeMenuOnSelect={!onMultiSelect}
                        components={components}
                        defaultValue={isButtonSelect && options ? options[0] : value}
                        isClearable={isClearable}
                        isDisabled={isDisabled}
                        isMulti={!!onMultiSelect}
                        isSearchable={!isButtonSelect}
                        menuPosition={isTableSelect ? 'fixed' : 'absolute'}
                        noOptionsMessage={() => 'No options...'}
                        variant={variant}
                        onCreateOption={x => onCreateOption && onCreateOption({ label: x, value: x })}
                        // @ts-ignore
                        onBlur={() => {
                            if (onBlur) {
                                onBlur();
                            }
                            refreshError();
                        }}
                        // @ts-ignore
                        onChange={(e: SingleValue<SelectOptionsType> | SelectOptionsType[]) => {
                            if (onMultiSelect) {
                                onMultiSelect(e as SelectOptionsType[]);
                                refreshError();
                                return;
                            }
                            if (onChangeValue) {
                                onChangeValue(e as SingleValue<SelectOptionsType>);
                                refreshError();
                            }
                        }}
                        onFocus={onFocus}
                        options={options}
                        placeholder={placeholder}
                        styles={InputStyles({ variant, options })}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 8,
                            colors: {
                                ...theme.colors,
                                primary25: colors.selectBackgroundGray,
                                primary: colors.selectBackgroundGray,
                                border: 'none',
                            },
                        })}
                        value={value ?? null}
                    />
                </InputContainer>
                {showError && !!error && (<ErrorMessage text={error} />)}
            </Flex.Column>
        );
    },
);

Select.displayName = 'Select';

export default Select;
