import styled from "styled-components";
import { colors, fonts } from "../enums";
import up from '../assets/icons/up.svg';
import { useState } from "react";

const FilterBoxComponent = styled.div`
    display: flex;
    flex-direction: column;
`;
const FilterBoxHeader = styled.div<{ size: string }>`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-bottom: ${props => props.size == 'large' ? 25 : 12}px;
    margin-top: ${props => props.size == 'large' ? 0 : 25}px;
    white-space: nowrap;
`;
const FilterBoxHeaderText = styled.div<{ size: string }>`
    font-family: ${fonts.main};
    color: ${colors.primaryBlue};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    flex: 0;
    font-size: ${props => props.size == 'large' ? 37 : 24}px;
    line-height: ${props => props.size == 'large' ? 55 : 35}px;
`;
const FilterBoxHeaderIcon = styled.div<{ isOpen: boolean, size: string }>`
    transform: rotate(${props => (props.isOpen ? '0deg' : '180deg')});
    background-color: ${colors.primaryBlue};
    -webkit-mask-image: url(${up});
    mask-image: url(${up});
    height: ${props => props.size == 'large' ? 20 : 13}px;
    width: ${props => props.size == 'large' ? 34 : 21}px;
    mask-size: contain;
    flex: 0 0 ${props => props.size == 'large' ? 34 : 21}px;
    background-position: center;
    background-size: cover;
`;

/** Пропсы */
interface FilterBoxprops {
    /** Заголовок */
    title: string;
    /** Размер */
    size?: 'large' | 'small';
    /** Отображаемые элементы */
    children?: React.ReactNode;
    /** Тип компонента для названия */
    textComponent?: keyof JSX.IntrinsicElements;
}

/** Компонент для фильтров в поиске чего-нибудь */
const FilterBox: React.FC<FilterBoxprops> = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    const sizeValue = props.size || 'small';
    return (
        <FilterBoxComponent>
            <FilterBoxHeader size={sizeValue} onClick={() => setIsOpen(!isOpen)}>
                <FilterBoxHeaderText as={props.textComponent ?? 'div'} size={sizeValue}>{props.title}</FilterBoxHeaderText>
                <FilterBoxHeaderIcon size={sizeValue} isOpen={isOpen} />
            </FilterBoxHeader>
            {isOpen && props.children}
        </FilterBoxComponent>
    );
};

export default FilterBox;