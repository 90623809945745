import { ApiConnection } from './ApiConnection';

/** Сервис для запросов вакансий */
class JobService {
    /** Базовый URL для сервиса */
    static get RoutePrefix(): string {
        return '/api/v1.0/Job';
    }

    /** Получить список сущностей по фильтру */
    static async get(request: {
        regionId?: Array<string> | null | undefined,
        companyId?: string | null | undefined,
        salaryPerMile?: number | null | undefined,
        salaryGross?: number | null | undefined,
        salaryAsPercent?: number | null | undefined,
        experienceFrom?: number | null | undefined,
        experienceTo?: number | null | undefined,
        minCompanyRating?: number | null | undefined,
        drivingLicenseCategory?: Array<number | string> | null | undefined,
        truckType?: Array<number | string> | null | undefined,
        routeType?: Array<number | string> | null | undefined,
        searchText?: string | null | undefined,
        isTeam?: boolean | null | undefined,
        jobType?: Array<number | string> | null | undefined,
        pageNumber?: number | null | undefined,
        pageSize?: number | null | undefined,
        orderBy?: string | null | undefined,
        isAscending?: boolean | null | undefined
    }): Promise<GetJobsResponse> {
        const response = await ApiConnection.get<GetJobsResponse>(this.RoutePrefix, { params: request });
        return response.data;
    }

    /** Получить сущность по ИД */
    static async getById(id: string): Promise<GetJobByIdResponse> {
        const response = await ApiConnection.get<GetJobByIdResponse>(`${this.RoutePrefix}/${id}`);
        return response.data;
    }

    /** Подать заявку на работу */
    static async apply(request: {
        jobId?: string | null | undefined,
    }): Promise<void> {
        const response = await ApiConnection.put(`${this.RoutePrefix}/Apply`, request);
        return response.data;
    }
}
export default JobService;

export interface GetJobsResponse {
    /**
     * Список сущностей
     * @type {Array<GetJobsResponseItem>}
     * @memberof GetJobsResponse
     */
    entities?: Array<GetJobsResponseItem> | null;
    /**
     * Общее количество сущностей
     * @type {number}
     * @memberof GetJobsResponse
     */
    totalCount?: number;
}

/**
 * Ответ на запрос списка вакансий
 * @export
 * @interface GetJobsResponseItem
 */
export interface GetJobsResponseItem {
    /**
     * ИД
     * @type {string}
     * @memberof GetJobsResponseItem
     */
    id?: string;
    /**
     * ИД компании
     * @type {string}
     * @memberof GetJobsResponseItem
     */
    companyId?: string;
    /**
     * ЗП по милям
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    salaryPerMile?: number | null;
    /**
     * ЗП Gross
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    salaryGross?: number | null;
    /**
     * ЗП процентом
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    salaryAsPercent?: number | null;
    /**
     * Тип трака
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    truckType?: number;
    /**
     * Тип позиции
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    jobType?: number;
    /**
     * Тип маршрутов
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    routeType?: number;
    /**
     * Преимущества
     * @type {Array<string>}
     * @memberof GetJobsResponseItem
     */
    benefits?: Array<string> | null;
    /**
     * Требования
     * @type {Array<string>}
     * @memberof GetJobsResponseItem
     */
    requirements?: Array<string> | null;
    /**
     * Название позиции
     * @type {string}
     * @memberof GetJobsResponseItem
     */
    name?: string | null;
    /**
     * Описание
     * @type {string}
     * @memberof GetJobsResponseItem
     */
    description?: string | null;
    /**
     * Регионы
     * @type {Array<string>}
     * @memberof GetJobsResponseItem
     */
    regions?: string[] | null;
    /**
     * Требуемый опыт
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    experienceRequired?: number | null;
    /**
     * Требуемая категория прав
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    drivingLicenseCategory?: number | null;
    /**
     * Название компании
     * @type {string}
     * @memberof GetJobsResponseItem
     */
    companyName?: string | null;
    /**
     * ИД файла-аватара компании
     * @type {string}
     * @memberof GetJobsResponseItem
     */
    companyAvatarFileId?: string | null;
    /**
     * ИД файла-бэкграунда компании
     * @type {string}
     * @memberof GetJobsResponseItem
     */
    companyBackgroundFileId?: string | null;
    /**
     * Количество вакансий
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    companyJobsCount?: number;
    /**
     * Количество оценок
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    companyRatingsCount?: number;
    /**
     * Рейтинг компании
     * @type {number}
     * @memberof GetJobsResponseItem
     */
    averageCompanyRating?: number;
}

/**
 * Ответ на запрос вакансий по ИД
 * @export
 * @interface GetJobByIdResponse
 */
export interface GetJobByIdResponse {
    /**
     * ИД
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    id?: string;
    /**
     * ИД компании
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    companyId?: string;
    /**
     * ЗП по милям
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    salaryPerMile?: number | null;
    /**
     * ЗП Gross
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    salaryGross?: number | null;
    /**
     * ЗП процентом
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    salaryAsPercent?: number | null;
    /**
     * Тип трака
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    truckType?: number;
    /**
     * Тип позиции
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    jobType?: number;
    /**
     * Тип маршрутов
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    routeType?: number;
    /**
     * Преимущества
     * @type {Array<string>}
     * @memberof GetJobByIdResponse
     */
    benefits?: Array<string> | null;
    /**
     * Требования
     * @type {Array<string>}
     * @memberof GetJobByIdResponse
     */
    requirements?: Array<string> | null;
    /**
     * Название позиции
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    name?: string | null;
    /**
     * Описание
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    description?: string | null;
    /**
     * Требуемый опыт
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    experienceRequired?: number | null;
    /**
     * Требуемая категория прав
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    drivingLicenseCategory?: number | null;
    /**
     * Название компании
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    companyName?: string | null;
    /**
     * Сайт компании
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    companySite?: string | null;
    /**
     * ИД файла-аватара компании
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    companyAvatarFileId?: string | null;
    /**
     * ИД файла-бэкграунда компании
     * @type {string}
     * @memberof GetJobByIdResponse
     */
    companyBackgroundFileId?: string | null;
    /**
     * Дата создания отзыва
     * @type {Date}
     * @memberof GetJobByIdResponse
     */
    createdOn?: Date | null;
    /**
     * Количество вакансий
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    companyJobsCount?: number;
    /**
     * Количество оценок
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    companyRatingsCount?: number;
    /**
     * Рейтинг компании
     * @type {number}
     * @memberof GetJobByIdResponse
     */
    averageCompanyRating?: number;
    /**
     * Названия регионов
     * @type {Array<string>}
     * @memberof GetJobByIdResponse
     */
    regions?: Array<string> | null;
    /**
     * Рейтинги
     * @type {Array<GetJobByIdResponseRating>}
     * @memberof GetJobByIdResponse
     */
    ratings?: Array<GetJobByIdResponseRating> | null;
    /**
     * Рейтинги
     * @type {Array<GetJobByIdResponseImageFile>}
     * @memberof GetJobByIdResponse
     */
    imageFiles?: Array<GetJobByIdResponseImageFile> | null;
}

/**
 * Файл в ответе на запрос вакансии по ИД
 * @export
 * @interface GetJobByIdResponseImageFile
 */
export interface GetJobByIdResponseImageFile {
    /**
     * ИД файла
     * @type {string}
     * @memberof GetJobByIdResponseImageFile
     */
    fileId?: string;
    /**
     * Название файла
     * @type {string}
     * @memberof GetJobByIdResponseImageFile
     */
    fileName?: string | null;
    /**
     * Размер файла в байтах
     * @type {number}
     * @memberof GetJobByIdResponseImageFile
     */
    size?: number;
    /**
     * Mime-тип
     * @type {string}
     * @memberof GetJobByIdResponseImageFile
     */
    contentType?: string | null;
}
/**
 * Рейтинг в ответе на запрос вакансии по ИД
 * @export
 * @interface GetJobByIdResponseRating
 */
export interface GetJobByIdResponseRating {
    /**
     * ИД 
     * @type {string}
     * @memberof GetJobByIdResponseRating
     */
    id?: string;
    /**
     * Дата создания рейтинга
     * @type {Date}
     * @memberof GetJobByIdResponseRating
     */
    createdOn?: Date;
    /**
     * Рейтинг
     * @type {number}
     * @memberof GetJobByIdResponseRating
     */
    rating?: number;
    /**
     * Является ли сотрудник текущим сотрудником компании
     * @type {boolean}
     * @memberof GetJobByIdResponseRating
     */
    isEmployeeCurrent?: boolean;
    /**
     * Дата начала работы на компанию
     * @type {Date}
     * @memberof GetJobByIdResponseRating
     */
    employedOn?: Date | null;
    /**
     * Дата окончания работы на компанию
     * @type {Date}
     * @memberof GetJobByIdResponseRating
     */
    firedOn?: Date | null;
    /**
     * Название работы, которую исполнял сотрудник
     * @type {string}
     * @memberof GetJobByIdResponseRating
     */
    jobTitle?: string | null;
    /**
     * Отзыв
     * @type {string}
     * @memberof GetJobByIdResponseRating
     */
    review?: string | null;
    /**
     * Плюсы
     * @type {Array<string>}
     * @memberof GetJobByIdResponseRating
     */
    pros?: Array<string> | null;
    /**
     * Минусы
     * @type {Array<string>}
     * @memberof GetJobByIdResponseRating
     */
    cons?: Array<string> | null;
    /**
     * Пользователи, которые залайкали ревью
     * @type {Array<string>}
     * @memberof GetJobByIdResponseRating
     */
    likedUsers?: Array<string> | null;
}

