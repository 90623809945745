import styled from "styled-components";
import { useEffect, useState } from "react";
import { colors, fonts } from "../enums";
import Input from "../components/ui/Input";
import Flex from "../components/ui/flex/Flex";
import Button from "../components/ui/Button";
import { VerticalAlign } from "../components/ui/flex/enums";
import { FormState } from "../types";
import AuthenticationService, { LoginRequest } from "../services/api/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { routes } from "../App";

const LoginPageComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
const LoginPageCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 30px;
    background-color: ${colors.cardBodyBackgroundGray};
`;
const LoginPageError = styled.div`
    display: flex;
    color: ${colors.errorTextColor};
    text-transform: uppercase;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
`;
/** Страница с логином */
const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [entity, setEntity] = useState<LoginRequest>({});
    const [formState, setFormState] = useState<FormState>(FormState.Empty());

    const initialize = async () => {
    };

    /** Сохранить отзыв */
    const save = async () => {
        try {
            setFormState(x => x.clear());
            await AuthenticationService.login(entity);
            navigate(routes.main);
        } catch (error) {
            setFormState(x => x.setError(error));
        } finally {
            setFormState(x => x.setLoading(false));
        }
    };

    useEffect(() => { initialize(); }, []);

    return (
        <LoginPageComponent>
            <LoginPageCard>
                <Flex.Column gap={20}>
                    {formState.error && <LoginPageError>{formState.error}</LoginPageError>}
                    <Input
                        variant="bigwhite"
                        label="Login"
                        value={entity.login || ''}
                        onChangeValue={x => setEntity({ ...entity, login: x })} />
                    <Input
                        variant="bigwhite"
                        label="Password"
                        inputType="password"
                        value={entity.password}
                        onChangeValue={x => setEntity({ ...entity, password: x })} />
                    <Flex.Row vertical={VerticalAlign.center} gap={60}>
                        <Button variant="primary" onClick={save}>Login</Button>
                        <Flex.Row vertical={VerticalAlign.center} gap={10}>
                            <Button
                                variant="transparent"
                                onClick={() => navigate(routes.registerAsDriver)}>
                                Register as driver
                            </Button>
                            <Button
                                variant="transparent"
                                onClick={() => navigate(routes.registerAsCompany)}>
                                Register as Company
                            </Button>
                        </Flex.Row>
                    </Flex.Row>
                </Flex.Column>
            </LoginPageCard>
        </LoginPageComponent>
    );
};

export default LoginPage;