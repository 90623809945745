import styled from 'styled-components';
import React, { useState } from "react";
import { VerticalAlign } from "./ui/flex/enums";
import Flex from "./ui/flex/Flex";
import logo from '../assets/icons/logo.svg';
import { colors, fonts } from '../enums';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../App';
import Button from './ui/Button';
import { getCurrentUserId } from "../services/api/ApiConnection";
import AuthenticationService from "../services/api/AuthenticationService";

const HeaderLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 20px 50px 50px;
    align-items: center;
`;
const MainMenu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    max-width: 600px;
`;
const MainMenuItem = styled(Link)`
    display: flex;
    align-items: center;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: ${colors.primaryBlue};
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
`;
const LeftMenu = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;
const RightMenu = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 50px;
`;
const Logo = styled.img`
    height: 50px;
    margin-right: 50px;
`;

/** Основной компонент с контентом */
const Header: React.FC = () => {
    const navigate = useNavigate();

    return (
        <HeaderLayout>
            <LeftMenu>
                <Link to={'/'}><Logo src={logo} alt="Логотип"></Logo></Link>
                <MainMenu>
                    <MainMenuItem to={routes.jobs}>Cdl jobs</MainMenuItem>
                    <MainMenuItem to={routes.companies}>Trucking companies</MainMenuItem>
                    <MainMenuItem to={routes.rate}>Leave a review</MainMenuItem>
                </MainMenu>
            </LeftMenu>
            {!getCurrentUserId()
                ? <Flex.Row flexInitial gap={20}>
                    <Button variant="transparent" onClick={() => navigate(routes.registerAsCompany)}>Post a job</Button>
                    <Button variant="primary" onClick={() => navigate(routes.registerAsDriver)}>Apply for a job</Button>
                </Flex.Row>
                : <Flex.Row flexInitial gap={20}>
                    <Button variant="primary" onClick={() => navigate(routes.driverAccount(getCurrentUserId()))}>Account</Button>
                    <Button variant="transparent" onClick={() => { AuthenticationService.logout(); navigate(routes.main) }}>Log out</Button>
                </Flex.Row>}
        </HeaderLayout>
    );
};

export default Header;