import Button from "./ui/Button";
import { VerticalAlign } from "./ui/flex/enums";
import Flex from "./ui/flex/Flex";
import noCardBackgroundImage from '../assets/icons/noCardBackgroundImage.svg';
import styled from "styled-components";
import { formatNumber } from "../services/utils";
import noCardLogo from '../assets/icons/noCardLogo.svg';
import { cdlTypes, colors, fonts, jobTypes, truckTypes } from "../enums";
import star from '../assets/icons/star.svg';
import { isUserDriver } from "../services/api/ApiConnection";
import JobService from "../services/api/JobService";

const JobCardComponent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    background-color: ${colors.cardBodyBackgroundGray};
    min-width: 650px;
    max-width: 800px;
    flex: 1;
    width: 100%;
`;
const JobCardHeader = styled.div<{ background?: string | null | undefined }>`
    display: flex;
    background-color: ${colors.cardHeaderBackgroundGray};
    background-image: ${props => props.background ? `url(${props.background})` : 'none'};
    background-size: cover;
    background-position: center;
    height: 200px;
    border-radius: 7px 7px 0 0;
    justify-content: space-between;
`;
const JobCardBody = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`;
const JobCardHeaderLogo = styled.img`
    height: 130px;
    width: 130px;
    margin: 30px;
    border-radius: 50%;
`;
const JobCardHeaderNoBackground = styled.img`
    margin: 50px;
`;
const JobCardTitle = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    color: ${colors.primaryBlue};
    margin-right: 20px;
`;
const JobCardRegion = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    color: ${colors.commentGray};
`;
const JobCardRating = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    color: ${colors.primaryYellow};
`;
const JobCardRatingStar = styled.div`
    background-image: url(${star});
    background-size: contain;
    height: 30px;
    width: 30px;
    margin-left: 7px;
`;
const JobCardCounter = styled.div`
    display: flex;
    color: ${colors.commentGray};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
`;
const JobCardDescription = styled.p`
    text-overflow: ellipsis;
    margin-top: 16px;
    overflow: hidden;
    color: ${colors.cardDescriptionBlack};
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
const JobCardBr = styled.hr`
    border: none;
    background-color: ${colors.primaryYellow};
    height: 3px;
    margin: 25px -20px 25px -20px;
`;
const JobCardDetails = styled.div`
    color: ${colors.commentGray};
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
`;
const JobCard: React.FC<{
    id: string | null | undefined,
    logo?: string | null | undefined,
    background?: string | null | undefined,
    name: string | null | undefined,
    regionName?: string | null | undefined,
    description?: string | null | undefined,
    rating?: number,
    jobsCount?: number,
    reviewsCount?: number,
    salariesCount?: number,
    jobType?: number | null | undefined,
    truckType?: number | null | undefined,
    drivingLicenseCategory?: number | null | undefined,
    experienceRequired?: number | null | undefined,
    textComponent?: keyof JSX.IntrinsicElements,
}> = (props) => {
    const years = (props.experienceRequired ?? 0) >= 3
        ? '> 3' :
        props.experienceRequired?.toString();

    /** Податься на вакансию */
    const apply = async () => {
        if (!isUserDriver()) {
            console.log('Пользовтель не водитель');
            return;
        }
        if (!props.id) {
            console.log('Не задан ид вакансии');
            return;
        }

        await JobService.apply({
            jobId: props.id
        });
        console.log('Заявка на вакансию отправлена');
    };

    return (
        <JobCardComponent>
            <JobCardHeader background={props.background}>
                <JobCardHeaderLogo src={props.logo ?? noCardLogo} />
                {!props.background && <JobCardHeaderNoBackground src={noCardBackgroundImage} />}
            </JobCardHeader>
            <JobCardBody>
                <Flex.Row gap={20} vertical={VerticalAlign.center}>
                    <Flex.Column>
                        <Flex.Row>
                            <JobCardTitle as={props.textComponent ?? 'div'}>{props.name}</JobCardTitle>
                            <JobCardRegion as={props.textComponent ?? 'div'}>{props.regionName}</JobCardRegion>
                        </Flex.Row>
                        <Flex.Row vertical={VerticalAlign.center}>
                            <JobCardRating>{formatNumber(props.rating, 1)}</JobCardRating>
                            <JobCardRatingStar />
                        </Flex.Row>
                    </Flex.Column>
                    <Button variant="primary" height={45} onClick={apply} isDisabled={!isUserDriver()}>Apply</Button>
                </Flex.Row>
                <Flex.Row gap={40} marginTop={10} vertical={VerticalAlign.center}>
                    <JobCardCounter>{props.jobsCount} jobs</JobCardCounter>
                    <JobCardCounter>{props.reviewsCount} reviews</JobCardCounter>
                    <JobCardCounter>{props.salariesCount} salaries</JobCardCounter>
                </Flex.Row>
                <JobCardDescription>{props.description}</JobCardDescription>
                <JobCardBr />
                <JobCardDetails>Position Type: {jobTypes.find(x => x.id == props.jobType)?.name || 'any'}</JobCardDetails>
                <JobCardDetails>CDL Types : {cdlTypes.find(x => x.id == props.drivingLicenseCategory)?.name || 'any'}</JobCardDetails>
                <JobCardDetails>Experience: {`${years} years` || 'any'}</JobCardDetails>
                <JobCardDetails>Trailer type: {truckTypes.find(x => x.id == props.truckType)?.name || 'any'}</JobCardDetails>
            </JobCardBody>
        </JobCardComponent>);
};

export default JobCard;