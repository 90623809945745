import { ApiConnection, setToken, removeToken } from './ApiConnection';

/** Сервис аутентификации */
class AuthenticationService {
    /** Базовый URL для сервиса */
    static get RoutePrefix(): string {
        return '/api/v1.0/Authentication';
    }

    /** Залогиниться */
    static async login(request: LoginRequest): Promise<LoginResponse> {
        const response = await ApiConnection.post<LoginResponse>(`${this.RoutePrefix}/Login`, request);
        if (response.data?.token) {
            setToken(response.data?.token);
        }
        return response.data;
    }

    /** Выйти из аккаунта */
    static logout() {
        removeToken();
    }
}
export default AuthenticationService;

/**
 * Запрос логина в приложение
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * Логин
     * @type {string}
     * @memberof LoginRequest
     */
    login?: string | null;
    /**
     * Пароль
     * @type {string}
     * @memberof LoginRequest
     */
    password?: string;
}

/**
 * Ответ на запрос логина в приложение
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * Токен аутентификации
     * @type {string}
     * @memberof LoginResponse
     */
    token?: string;
    /**
     * ИД пользователя
     * @type {string}
     * @memberof LoginResponse
     */
    userId?: string;
}
