import styled from "styled-components";
import { useEffect, useState } from "react";
import Tabpanel from "../components/ui/Tabpanel";
import { colors, fonts } from "../enums";
import edit from "../assets/icons/edit.svg";
import billing from "../assets/icons/billing.svg";
import noAvatar from "../assets/icons/noAvatar.svg";
import Flex from "../components/ui/flex/Flex";
import { HorizontalAlign, Space, VerticalAlign } from "../components/ui/flex/enums";
import Button from "../components/ui/Button";
import JobCard from "../components/JobCard";
import JobService, { GetJobsResponse } from "../services/api/JobService";
import FileService from "../services/api/FileService";
import CompanyService, { GetCompaniesResponse } from "../services/api/CompanyService";
import Accordion from "../components/ui/Accordion";
import Gallery from "react-photo-gallery";
import ReviewCard from "../components/ReviewCard";
import DriverCard from "../components/DriverCard";

const CompanyAccountPageComponent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 90px;
`;
const CompanyAccountTab = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 40px;
    gap: 20px;
    background-color: ${colors.cardBodyBackgroundGray};
    border-radius 0 0 30px 30px;
`;
const CompanyAccountTabCard = styled.div<{ noTopPadding?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${props => props.noTopPadding ? '0 40px 40px 40px' : '40px'};
    background-color: ${colors.white};
    border-radius 20px;
`;
const CompanyAccountTabCardHr = styled.hr`
    background-color: ${colors.cardBodyBackgroundGray};
    height: 6px;
    border: none;
    margin-top: 10px;
`;
const CompanyAccountHeader = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: ${colors.primaryBlue};
`;
const CompanyAccountSubHeader = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: ${colors.commentGray};
`;
const CompanyAccountCompleteness = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 35px;
    align-items: center;
    background-color: ${colors.cardBodyBackgroundGray};
`;
const CompanyAccountCompletenessReady = styled.div<{ completed: number }>`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    flex: ${props => props.completed}% 0 0;
    background-color: ${colors.primaryYellow};
    color: ${colors.white};
    text-align: center;
`;

const CompanyAccountTableHeader = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    background-color: ${colors.cardHeaderBackgroundGray};
    color: ${colors.white};
    text-align: center;
    text-transform: uppercase;
    padding: 20px;
`;
const CompanyAccountTableColumn = styled.div`
    display: flex;
    flex: 1;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    background-color: ${colors.cardBodyBackgroundGray};
    color: ${colors.commentGray};
    text-transform: uppercase;
    padding: 20px;
`;



/** Страница с аккаунтом водителя */
const CompanyAccountPage: React.FC = () => {
    const [jobs, setJobs] = useState<GetJobsResponse | undefined>();
    const [companies, setCompanies] = useState<GetCompaniesResponse | undefined>();

    const initialize = async () => {
        const jobsResponse = await JobService.get({
            pageSize: 4,
            pageNumber: 1
        });
        setJobs(jobsResponse);
        const companiesResponse = await CompanyService.get({
            pageSize: 4,
            pageNumber: 1
        });
        setCompanies(companiesResponse);
        // const response = await CompanyAccountService.getById(id);
        // setEntity(response);
        // setDocumentTitle(response?.name ?? `Company account ${id}`, `Page with Company account ${response?.name ?? id}`);
    };

    useEffect(() => { initialize(); }, []);

    return (
        <CompanyAccountPageComponent>

            <Tabpanel stickToTop={false}>
                <div data-label='My profile'>
                    <CompanyAccountTab>
                        <Flex.Column flex={1}>
                            <CompanyAccountTabCard>
                                <Flex.Row gap={20} horizontal={Space.between}>
                                    <Flex.Row gap={40}>
                                        <img src={noAvatar} height={120} width={120} />
                                        <CompanyAccountHeader>Company name</CompanyAccountHeader>
                                    </Flex.Row>
                                    <Button icon={edit} size="nopadding" height={30} width={30} />
                                </Flex.Row>
                                <CompanyAccountTabCardHr />
                                <Flex.Column gap={15} flexInitial>
                                    <CompanyAccountHeader>Personal information</CompanyAccountHeader>
                                    <CompanyAccountStats name="Address" value="25.07.1993" />
                                    <CompanyAccountStats name="Email address" value="25.07.1993" />
                                    <CompanyAccountStats name="Phone number" value="25.07.1993" />
                                    <CompanyAccountStats name="Date of birth" value="25.07.1993" />
                                </Flex.Column>
                                <CompanyAccountTabCardHr />
                                <Button variant="transparent">Delete account</Button>
                            </CompanyAccountTabCard>
                            <Button variant="transparent" icon={billing}>Billing settings</Button>
                        </Flex.Column>
                        <Flex.Column flex={1} gap={20}>
                            <Flex.Row flexInitial>
                                <CompanyAccountTabCard>
                                    <CompanyAccountHeader>Profile completeness</CompanyAccountHeader>
                                    <CompanyAccountCompleteness>
                                        <CompanyAccountCompletenessReady completed={60}>60%</CompanyAccountCompletenessReady>
                                    </CompanyAccountCompleteness>
                                </CompanyAccountTabCard>
                            </Flex.Row>
                            <Flex.Row>
                                <CompanyAccountTabCard noTopPadding>
                                    <Flex.Column margin={'0 25px'}>
                                        <Tabpanel stickToTop={true}>
                                            <div data-label='Company information'>
                                                <Flex.Column gap={10}>
                                                    <Flex.Row horizontal={HorizontalAlign.right} marginTop={20}>
                                                        <Button icon={edit} size="nopadding" height={30} width={30} />
                                                    </Flex.Row>
                                                    <CompanyAccountStats hasBorder name="Company type" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="Endorsements" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="CDLNumber" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="CDL State" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="CDL Class" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="Accidents" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="Moving violations" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="Suspended" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="DUI" value="25.07.1993" />
                                                    <CompanyAccountStats hasBorder name="Twic card" value="25.07.1993" />
                                                </Flex.Column>
                                            </div>
                                            <div data-label='FAQ'>
                                                <Flex.Column gap={10}>
                                                    <Flex.Row horizontal={HorizontalAlign.right} marginTop={20}>
                                                        <Button icon={edit} size="nopadding" height={30} width={30} />
                                                    </Flex.Row>
                                                    <Accordion title='Question 1'>Answer 1</Accordion>
                                                    <Accordion title='Question 2'>Answer 2</Accordion>
                                                    <Accordion title='Question 3'>Answer 3</Accordion>
                                                </Flex.Column>
                                            </div>
                                            <div data-label='Photo'>
                                                <Gallery photos={[{ fileId: '123' }]?.map(x => ({
                                                    src: FileService.downloadLink(x.fileId)!,
                                                    width: 2,
                                                    height: 2
                                                })) || []} />
                                            </div>
                                        </Tabpanel>
                                    </Flex.Column>
                                </CompanyAccountTabCard>
                            </Flex.Row>
                        </Flex.Column>
                    </CompanyAccountTab>
                </div>
                <div data-label='Reviews'>
                    <CompanyAccountTab>
                        <CompanyAccountTabCard>
                            <Flex.Column gap={20} horizontal={HorizontalAlign.center} marginTop={40}>
                                {jobs?.entities?.map(x => <ReviewCard
                                    cons={['123']}
                                    createdOn={new Date()}
                                    id={x.id}
                                    jobTitle={x.name}
                                    pros={['123']}
                                    rating={123}
                                    review={'t23t 24 4y h3rh e hwr 4'}
                                    likedUsers={[]}
                                    companyId={x.companyId ?? ''}
                                    key={x.id} />)}
                            </Flex.Column>
                        </CompanyAccountTabCard>
                    </CompanyAccountTab>
                </div>
                <div data-label='MyJobs'>
                    <CompanyAccountTab>
                        <CompanyAccountTabCard>
                            <Flex.Column gap={20} horizontal={HorizontalAlign.center} marginTop={40}>
                                {jobs?.entities?.map(x => (
                                    <JobCard
                                        key={x.id}
                                        id={x.id}
                                        name={x.name}
                                        logo={FileService.downloadLink(x.companyAvatarFileId)}
                                        background={FileService.downloadLink(x.companyBackgroundFileId)}
                                        regionName={x.regions?.join(', ')}
                                        rating={x.averageCompanyRating}
                                        jobsCount={x.companyJobsCount}
                                        reviewsCount={x.companyRatingsCount}
                                        salariesCount={x.companyJobsCount}
                                        jobType={x.jobType}
                                        truckType={x.truckType}
                                        drivingLicenseCategory={x.drivingLicenseCategory}
                                        experienceRequired={x.experienceRequired}
                                        description={x.description} />
                                ))}
                            </Flex.Column>
                        </CompanyAccountTabCard>
                    </CompanyAccountTab>
                </div>
                <div data-label='Job applications received'>
                    <CompanyAccountTab>
                        <CompanyAccountTabCard>
                            <Flex.Column gap={20} horizontal={HorizontalAlign.center} marginTop={40}>
                                {jobs?.entities?.map(x => (
                                    <DriverCard
                                        id={x.id || ''}
                                        key={x.id}
                                        name={x.name}
                                        icon={FileService.downloadLink(x.companyAvatarFileId)}
                                        region={x.regions?.join(', ')}
                                        truckTypes={[x.truckType || 1]}
                                        drivingLicenseCategory={x.drivingLicenseCategory}
                                        experience={x.experienceRequired}
                                        header={(new Date()).toString()}
                                        routePreference={1}
                                        isTeam={true} />
                                ))}
                            </Flex.Column>
                        </CompanyAccountTabCard>
                    </CompanyAccountTab>
                </div>
            </Tabpanel>
        </CompanyAccountPageComponent>
    );
};

const CompanyAccountStat = styled.div<{ hasBorder: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    border-bottom: ${props => props.hasBorder ? `3px solid ${colors.cardBodyBackgroundGray}` : 'none'};
`;
const CompanyAccountStatName = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    color: ${colors.commentGray};
    flex: 50% 0 0;
`;
const CompanyAccountStatValue = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: ${colors.commentGray};
    text-overflow: ellipsis;
    overflow: hidden;
`;
const CompanyAccountStats: React.FC<{ name: string, value: string | null | undefined, hasBorder?: boolean }> = (props) => (
    <CompanyAccountStat hasBorder={props.hasBorder ?? false}>
        <CompanyAccountStatName>{props.name}</CompanyAccountStatName>
        <CompanyAccountStatValue>{props.value}</CompanyAccountStatValue>
    </CompanyAccountStat>);

export default CompanyAccountPage;