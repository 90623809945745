import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import MainLayout from './views/MainLayout';
import MainPage from './views/MainPage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CompaniesPage from './views/CompaniesPage';
import CommonLayout from './views/CommonLayout';
import JobsPage from './views/JobsPage';
import JobPage from './views/JobPage';
import CompanyPage from './views/CompanyPage';
import RateCompanyPage from './views/RateCompanyPage';
import DriverAccountPage from './views/DriverAccountPage';
import CompanyAccountPage from './views/CompanyAccountPage';
import LoginPage from './views/LoginPage';
import RegisterAsDriverPage from './views/RegisterAsDriverPage';
import RegisterAsCompanyPage from './views/RegisterAsCompanyPage';

dayjs.extend(utc);

const ErrorHandler: React.FC = (props) => {
  const navigate = useNavigate();

  // @ts-ignore
  // eslint-disable-next-line react/destructuring-assignment
  const stack = props?.error?.stack ?? '';

  // @ts-ignore
  // eslint-disable-next-line react/destructuring-assignment
  const message = props?.error?.message ?? '';

  const goToMainPage = () => {
    // @ts-ignore
    // eslint-disable-next-line react/destructuring-assignment
    props?.resetErrorBoundary();
    navigate(routes.main);
  };

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <div style={{
        textAlign: 'center',
        margin: 50,
        marginBottom: 0,
      }}
      >
        Во время работы сайта произошла ошибка. Пожалуйста, отправьте эту информацию администратору.
        <br />
        <br />
        {message}
        <br />
        {stack}
      </div>
      <div style={{ margin: 50 }}>
        <button type='button' onClick={goToMainPage}>
          Вернуться на главную
        </button>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorHandler}
      >
        <Routes>
          {/** Роутинг внутри главной страницы */}
          <Route element={<MainLayout />}>
            <Route
              element={<MainPage />}
              path={routes.main}
            />
            <Route
              element={<LoginPage />}
              path={routes.login}
            />
            <Route
              element={<RegisterAsDriverPage />}
              path={routes.registerAsDriver}
            />
            <Route
              element={<RegisterAsCompanyPage />}
              path={routes.registerAsCompany}
            />
            <Route element={<CommonLayout />}>
              <Route
                element={<JobsPage />}
                path={routes.jobs}
              />
              <Route
                element={<CompaniesPage />}
                path={routes.companies}
              />
              <Route
                element={<CompaniesPage />}
                path={routes.companiesWithTag(':tag')}
              />
              <Route
                element={<JobPage />}
                path={routes.jobById(':id')}
              />
              <Route
                element={<JobPage />}
                path={routes.jobByIdAndName(':id', ':name')}
              />
              <Route
                element={<CompanyPage />}
                path={routes.companyById(':id')}
              />
              <Route
                element={<RateCompanyPage />}
                path={routes.rate}
              />
              <Route
                element={<RateCompanyPage />}
                path={routes.rateCompany(':id')}
              />
              <Route
                element={<DriverAccountPage />}
                path={routes.driverAccount(':id')}
              />
              <Route
                element={<CompanyAccountPage />}
                path={routes.companyAccount}
              />
            </Route>
          </Route>
          <Route
            element={(
              <Navigate
                to={routes.main}
                replace
              />
            )}
            path="*"
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

/** Маршруты */
export const routes = {
  login: `/login`,
  registerAsDriver: `/register-as-driver`,
  registerAsCompany: `/register-as-company`,
  main: `/main`,
  driverAccount: (id: string) => `/driver-account/${id}`,
  companyAccount: `/company-account`,
  companies: `/trucking-companies`,
  companiesWithTag: (tag: string) => `/trucking-companies/${tag}`,
  companyById: (id: string) => `/trucking-company/${id}`,
  jobs: `/truck-driving-jobs`,
  jobsWithTag: (tag: string) => `/truck-driving-jobs/${tag}`,
  jobById: (id: string) => `/truck-driving-job/${id}`,
  jobByIdAndName: (id: string, name: string) => `/truck-driving-job/${id}/${name}`,
  rate: `/rate`,
  rateCompany: (id: string) => `/rate/${id}`,
};

export default App;
