import { HorizontalAlign, VerticalAlign } from "./ui/flex/enums";
import Flex from "./ui/flex/Flex";

/** Футер */
const Footer: React.FC = () => {
    return (
        <Flex.Row vertical={VerticalAlign.center} horizontal={HorizontalAlign.center}></Flex.Row>
    );
};

export default Footer;