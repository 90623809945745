import { Space, VerticalAlign } from "../components/ui/flex/enums";
import { useEffect, useState } from "react";
import Flex from "../components/ui/flex/Flex";
import { Catalogs, soloTeamTypes, employmentTypes, freightTypes, routeTypes, travelPreferences } from "../enums";
import { SelectOptionsType } from "../components/ui/Select";
import { Size } from "../components/ui/Input";
import Modal, { ModalRow, ModalSelect, ModalInput, ModalFooterButton } from "../components/Modal";
import { BooleanRadio, RadioOptionsAlign } from "../components/ui/Radio";
import CatalogService from "../services/api/CatalogService";
import DriverService, { UpdateDriverRequest } from "../services/api/DriverService";

const routeTypesOptions = routeTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const employmentTypesOptions = employmentTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const freightTypesOptions = freightTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const travelPreferencesOptions = travelPreferences.map(x => ({ label: x.name, value: x.id.toString() }));

const DriverAccountPreferencesEditModal: React.FC<{
    id: string,
    setIsOpen?: (isOpen: boolean) => void
}> = (props) => {
    // Информация об аккаунте водителя
    const [entity, setEntity] = useState<UpdateDriverRequest>({});
    const [soloTeamOptions, setSoloTeamOptions] = useState<SelectOptionsType[] | undefined>();

    /**
     * Метод инициализации страницы.
     */
    const initialize = async () => {
        const soloTeamOptionsList = soloTeamTypes.map(x => ({ label: x.name || '', value: x.id }));
        setSoloTeamOptions(soloTeamOptionsList);

        // Подгружаем профиль пользователя
        const entityResponse = await DriverService.getById(props.id);
        setEntity(entityResponse);
    };

    /**
     * Обновить данные о профиле пользователя
     */
    const update = async () => {
        try {
            // setFormState(x => x.clear());
            await DriverService.update(entity);
            props.setIsOpen && props.setIsOpen(false);
        } catch (error) {
            // setFormState(x => x.setError(error));
        } finally {
            // setFormState(x => x.setLoading(false));
        }
    };

    useEffect(() => { initialize(); }, []);

    return (
        <Modal headerText="Edit preferences" setIsOpen={props.setIsOpen}>
            <Flex.Column vertical={Space.between} margin={20}>
                <Flex.Column>
                    <ModalRow>
                        <ModalSelect
                            variant="primary"
                            label='Employment types (select all that apply)'
                            options={employmentTypesOptions ?? []}
                            // value={employmentTypesOptions?.filter(x => entity.employments?.find(e => x.value == e.toString()))}
                            // onMultiSelect={x => setEntity({ ...entity, employments: x && x.map(e => parseInt(e.value)) || undefined })}
                            placeholder='Employment types'
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <ModalSelect
                            variant="primary"
                            label='Preferred freight types (select all that apply)'
                            options={freightTypesOptions ?? []}
                            value={freightTypesOptions?.filter(x => entity.preferredTruckTypes?.find(rt => x.value == rt.toString()))}
                            onMultiSelect={x => setEntity({ ...entity, preferredTruckTypes: x && x.map(rt => parseInt(rt.value)) || undefined })}
                            placeholder='Preferred freight types'
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <ModalSelect
                            label="Route preference (select all that apply)"
                            variant="primary"
                            options={routeTypesOptions ?? []}
                            value={routeTypesOptions?.filter(x => entity.preferredRouteTypes?.find(rt => x.value == rt.toString()))}
                            onMultiSelect={x => setEntity({ ...entity, preferredRouteTypes: x && x.map(rt => parseInt(rt.value)) || undefined })}
                            placeholder='Route preference'
                            size={Size.S} />

                        <ModalSelect
                            variant="primary"
                            label='Travel preference (select all that apply)'
                            options={travelPreferencesOptions ?? []}
                            // value={travelPreferencesOptions?.filter(x => entity.preferredRouteTypes?.find(rt => x.value == rt.toString()))}
                            // onMultiSelect={x => setEntity({ ...entity, preferredRouteTypes: x && x.map(rt => parseInt(rt.value)) || undefined })}
                            placeholder='Travel preference'
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <BooleanRadio
                            label="Interested in lease purchase"
                            size={Size.S}
                            value={entity?.isInterestedInLease ?? false}
                            onChange={(x) => setEntity({ ...entity, isInterestedInLease: x })}
                            align={RadioOptionsAlign.horizontal}
                        ></BooleanRadio>

                        <ModalSelect
                            variant="primary"
                            options={soloTeamOptions || []}
                            value={soloTeamOptions?.filter(x => x.value == (entity.isTeam ? "true" : entity.isTeam === false ? "false" : "null"))}
                            onChangeValue={(x) => setEntity({ ...entity, isTeam: (x?.value === "true" ? true : x?.value === "false" ? false : null) })}
                            label='Solo/team'
                            placeholder='Solo/team'
                            size={Size.S} />
                    </ModalRow>
                </Flex.Column>

                <Flex.Row horizontal={Space.between} vertical={VerticalAlign.center} flex={0}>
                    <ModalFooterButton variant="dark" onClick={() => props.setIsOpen && props.setIsOpen(false)}>Cancel</ModalFooterButton>
                    <ModalFooterButton variant="primary" onClick={update}>Save</ModalFooterButton>
                </Flex.Row>
            </Flex.Column>
        </Modal>
    );
};

export default DriverAccountPreferencesEditModal;