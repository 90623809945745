import { useState } from 'react';
import styled from 'styled-components';
import Button from '../components/ui/Button';
import { VerticalAlign } from '../components/ui/flex/enums';
import Flex from '../components/ui/flex/Flex';
import Input from '../components/ui/Input';
import { colors, fonts } from '../enums';
import searchIcon from '../assets/icons/search.svg';
import { Outlet, createSearchParams, useNavigate } from "react-router-dom";
import { routes } from '../App';
import { SearchFilterKeys } from '../types';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const LayoutHeader = styled.div`
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
const LayoutBody = styled.div`
    display: flex;
    flex: 1;
`;
const LayoutHeaderHr = styled.hr`
    display: flex;
    flex: 1;
    height: 6px;
    background-color: ${colors.primaryYellow};
    border: none;
`;

/** Общий компонент с контентом, кроме основной строницы - с поиском */
const CommonLayout: React.FC = () => {
    const [search, setSearch] = useState<string | undefined>();
    const navigate = useNavigate();

    return (
        <Layout>
            <LayoutHeader>
                <LayoutHeaderHr />
                <Flex.Row margin="0 20px" vertical={VerticalAlign.center} flex={'1 1 300px'}>
                    <Input value={search} onChangeValue={x => setSearch(x)} placeholder="Search for job titles, companies, or keywords" />
                    <Button
                        icon={searchIcon}
                        iconPosition='center'
                        size='small'
                        variant="primary"
                        height={45}
                        width={45}
                        onClick={() => search && navigate({
                            pathname: routes.jobs,
                            search: createSearchParams({
                                [SearchFilterKeys.searchText]: search
                            }).toString()
                        })}></Button>
                </Flex.Row>
                <LayoutHeaderHr />
            </LayoutHeader>
            <LayoutBody>
                <Outlet />
            </LayoutBody>
        </Layout>
    );
};

export default CommonLayout;
