import styled from "styled-components";
import mainTruck from '../assets/images/mainTruck.svg';
import manInCap from '../assets/images/manInCap.svg';
import manInTheCar from '../assets/images/manInTheCar.svg';
import sdlDriverJobs from '../assets/icons/sdlDriverJobs.svg';
import ownerOperatorJobs from '../assets/icons/ownerOperatorJobs.svg';
import findJobFitCriterias from '../assets/icons/findJobFitCriterias.svg';
import readDriverReviews from '../assets/icons/readDriverReviews.svg';
import researchAndCompareCompanies from '../assets/icons/researchAndCompareCompanies.svg';
import applyForCdl from '../assets/icons/applyForCdl.svg';
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import { colors, fonts, JobTypes, jobTypes, truckTypes } from "../enums";
import Flex from "../components/ui/flex/Flex";
import { HorizontalAlign, VerticalAlign } from "../components/ui/flex/enums";
import Carousel from "../components/ui/Carousel";
import Rating from "../components/ui/Rating";
import Accordion from "../components/ui/Accordion";
import JobCard from "../components/JobCard";
import CompanyCard from "../components/CompanyCard";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import JobService, { GetJobsResponse } from "../services/api/JobService";
import CompanyService, { GetCompaniesResponse } from "../services/api/CompanyService";
import FileService from "../services/api/FileService";
import { setDocumentTitle } from "../services/utils";
import { routes } from "../App";
import { SearchFilterKeys } from "../types";
import { Helmet } from "react-helmet";

const MainPageComponent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

/** Главная страница с кучей рекламы */
const MainPage: React.FC = () => {
    return (
        <MainPageComponent>
            <Adv />
            <MainPageSubtitle>How cdl jobs near me works for you</MainPageSubtitle>
            <HowWorks />
            <MainPageSubtitle>Find top paid trucking jobs</MainPageSubtitle>
            <TopPaidJobs />
            <SearchByTrailer />
            <MainPageSubtitle>
                <div>Read trucking companies’ reviews</div>
                <div style={{ color: colors.commentGray }}>Only real reviews of truck drivers</div>
            </MainPageSubtitle>
            <Reviews />
            <MainPageSubtitle>Frequently asked questions about cdl jobs</MainPageSubtitle>
            <Faq />
        </MainPageComponent>
    )
};

const Advertisement = styled.div`
    background-image: url(${mainTruck});
    background-size: cover;
    background-position: center;
    padding: 50px 100px 100px;
`;
const AdvertisementTitle = styled.h1`
    color: ${colors.primaryBlue};
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 77px;
    text-transform: uppercase;
`;
const AdvertisementSubTitle = styled.h2`
    color: ${colors.commentGray};
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    text-transform: uppercase;
`;
const AdvertisementSearch = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    align-items: center;
    width: 600px;
    gap: 20px;
`;
const AdvertisementLinks = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 250px;
    gap: 50px;
`;
const AdvertisementLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
`;
const AdvertisementLinkIcon = styled.img`
    height: 55px;
    margin-right: 25px;
`;
const AdvertisementLinkText = styled.h3`
    text-decoration: underline;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: ${colors.primaryBlue};
    cursor: pointer;
`;

const Adv: React.FC = () => {
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();

    return (
        <Advertisement>
            <AdvertisementTitle>Find better paying cdl job</AdvertisementTitle>
            <AdvertisementSubTitle>Let the best trucking companies compete for you</AdvertisementSubTitle>
            <AdvertisementSearch>
                <Input variant="bigwhite" value={search} onChangeValue={x => setSearch(x)} />
                <Button variant="primary" onClick={() => navigate({
                    pathname: routes.jobs,
                    search: createSearchParams({
                        [SearchFilterKeys.searchText]: search
                    }).toString()
                })}>Search</Button>
            </AdvertisementSearch>
            <AdvertisementLinks>
                <AdvertisementLink onClick={() => navigate({
                    pathname: routes.jobs,
                    search: createSearchParams({
                        [SearchFilterKeys.jobType]: JobTypes.CompanyDriver.toString()
                    }).toString()
                })}>
                    <AdvertisementLinkIcon src={sdlDriverJobs}></AdvertisementLinkIcon>
                    <AdvertisementLinkText>Cdl driver jobs</AdvertisementLinkText>
                </AdvertisementLink>
                <AdvertisementLink onClick={() => navigate({
                    pathname: routes.jobs,
                    search: createSearchParams({
                        [SearchFilterKeys.jobType]: JobTypes.OwnerOperator.toString()
                    }).toString()
                })}>
                    <AdvertisementLinkIcon src={ownerOperatorJobs}></AdvertisementLinkIcon>
                    <AdvertisementLinkText>Owner operator jobs</AdvertisementLinkText>
                </AdvertisementLink>
            </AdvertisementLinks>
        </Advertisement>)
};


const MainPageSubtitleTitle = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
const MainPageSubtitleHr = styled.hr`
    display: flex;
    flex: 1;
    height: 6px;
    background-color: ${colors.primaryYellow};
    border: none;
`;
const MainPageSubtitleText = styled.h2`
    margin: 0 70px;
    text-transform: uppercase;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 75px;
    color: ${colors.primaryBlue};
`;
const MainPageSubtitle: React.FC<{ children?: React.ReactNode }> = (props) => (
    <MainPageSubtitleTitle>
        <MainPageSubtitleHr />
        <MainPageSubtitleText>{props.children}</MainPageSubtitleText>
        <MainPageSubtitleHr />
    </MainPageSubtitleTitle>);


const HowWorksComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
    margin: 90px 0;
`;
const HowWorksItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const HowWorksItemIcon = styled.img`
    height: 127px;
    margin-bottom: 40px;
`;
const HowWorksItemText = styled.h3`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    color: ${colors.commentGray};
    text-align: center;
`;
const HowWorks: React.FC = () => (
    <HowWorksComponent>
        <HowWorksItem>
            <HowWorksItemIcon src={findJobFitCriterias} />
            <HowWorksItemText>Find cdl jobs that fit <span style={{ color: colors.primaryYellow }}>your</span> criterias</HowWorksItemText>
        </HowWorksItem>
        <HowWorksItem>
            <HowWorksItemIcon src={researchAndCompareCompanies} />
            <HowWorksItemText>Research and compare trucking companies</HowWorksItemText>
        </HowWorksItem>
        <HowWorksItem>
            <HowWorksItemIcon src={readDriverReviews} />
            <HowWorksItemText>Read real drivers’ reviews</HowWorksItemText>
        </HowWorksItem>
        <HowWorksItem>
            <HowWorksItemIcon src={applyForCdl} />
            <HowWorksItemText>Apply for cdl jobs with one click</HowWorksItemText>
        </HowWorksItem>
    </HowWorksComponent>);

const TopPaidJobsComponent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 100px;   
    justify-content: center;
    gap: 90px;
`;
const TopPaidJobs: React.FC = () => {
    const [jobs, setJobs] = useState<GetJobsResponse | undefined>();
    const onLoad = async () => {
        const response = await JobService.get({
            pageSize: 4,
            pageNumber: 1
        });
        setJobs(response);
    };

    useEffect(() => { onLoad(); }, []);

    return (<TopPaidJobsComponent>
        {jobs?.entities?.map(x => (
            <JobCard
                key={x.id}
                id={x.id}
                name={x.name}
                logo={FileService.downloadLink(x.companyAvatarFileId)}
                background={FileService.downloadLink(x.companyBackgroundFileId)}
                regionName={x.regions?.join(', ')}
                rating={x.averageCompanyRating}
                jobsCount={x.companyJobsCount}
                reviewsCount={x.companyRatingsCount}
                salariesCount={x.companyJobsCount}
                jobType={x.jobType}
                truckType={x.truckType}
                drivingLicenseCategory={x.drivingLicenseCategory}
                experienceRequired={x.experienceRequired}
                description={x.description}
                textComponent="h4" />
        ))}
    </TopPaidJobsComponent>);
};

const SearchByTrailerComponent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.cardBodyBackgroundGray};
    border-radius: 30px;
    margin-top: 100px;
`;
const SearchByTrailerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.primaryYellow};
    border-radius: 30px;
    padding: 30px;
    align-items: center;
    justify-content: center;
`;
const SearchByTrailerHeaderTitle = styled.h2`
    color: ${colors.primaryBlue};
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    text-transform: uppercase;
`;
const SearchByTrailerHeaderSubtitle = styled.h2`
    color: ${colors.white};
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    text-transform: uppercase;
`;
const SearchByTrailerTrucks = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;
    flex-wrap: wrap;
    border-radius: 30px;
    padding: 40px;
`;
const SearchByTrailerTruckIcon = styled.img`
    height: 50px;
    cursor: pointer;
`;
const SearchByTrailerTruckName = styled.h3`
    font-family: ${fonts.main};
    color: ${colors.commentGray};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    cursor: pointer;
`;
const SearchByTrailerManInCap = styled.img`
    height: 623px;
    width: 665px;
`;
const SearchByTrailerManInCapTitle = styled.h2`
    font-family: ${fonts.main};
    color: ${colors.primaryBlue};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 58px;
`;
const SearchByTrailerManInCapSubTitle = styled.h3`
    font-family: ${fonts.main};
    color: ${colors.commentGray};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
`;
const SearchByTrailerManInCapText = styled.p`
    font-family: ${fonts.main};
    color: ${colors.commentGray};
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    margin-top: 50px;
    max-height: 400px;
    max-width: 700px;
    overflow: hidden;
`;
const SearchByTrailer: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <SearchByTrailerComponent>
                <SearchByTrailerHeader>
                    <SearchByTrailerHeaderTitle>Search jobs by trailer</SearchByTrailerHeaderTitle>
                    <SearchByTrailerHeaderSubtitle>Best trucking companies are looking for you experience and skills</SearchByTrailerHeaderSubtitle>
                </SearchByTrailerHeader>
                <SearchByTrailerTrucks>
                    {truckTypes.map(x => (
                        <Flex.Column
                            key={x.name}
                            horizontal={HorizontalAlign.center}
                            vertical={VerticalAlign.center}
                            gap={50}
                            onClick={() => navigate({
                                pathname: routes.jobs,
                                search: createSearchParams({
                                    [SearchFilterKeys.truckType]: x.id.toString()
                                }).toString()
                            })}>
                            <SearchByTrailerTruckIcon src={x.icon} />
                            <SearchByTrailerTruckName>{x.name}</SearchByTrailerTruckName>
                        </Flex.Column>))}
                </SearchByTrailerTrucks>
            </SearchByTrailerComponent>
            <Flex.Row marginTop={70} gap={70} horizontal={HorizontalAlign.center} wrap>
                <SearchByTrailerManInCap src={manInCap} />
                <Flex.Column flexInitial>
                    <SearchByTrailerManInCapTitle>Thousands of cdl jobs with one click</SearchByTrailerManInCapTitle>
                    <SearchByTrailerManInCapSubTitle>It’s time to take the wheel and choose your career</SearchByTrailerManInCapSubTitle>
                    <SearchByTrailerManInCapText>-------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT-------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT--------------------------------------------------------------TEXT-------------------------------
                    </SearchByTrailerManInCapText>
                    <Flex.Row gap={60} marginTop={50} horizontal={HorizontalAlign.left} >
                        <Button variant="primary">Start applying</Button>
                        <Button variant="primary">Post a job</Button>
                    </Flex.Row>
                </Flex.Column>
            </Flex.Row>
        </>);
}

const ReviewsComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 80px;
`;
const ReviewsGetReview = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 125px;
    align-items: center;
    justify-content: center;
    gap: 200px;
    padding: 35px 130px;
    background-color: #5E5E5E;
    border-radius: 25px;
`;
const ReviewsGetReviewText = styled.h2`
    font-family: ${fonts.main};
    color: ${colors.white};
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    text-transform: uppercase;
`;
const Reviews: React.FC = () => {
    const navigate = useNavigate();
    const [rating, setRating] = useState<number>(0);
    const [companies, setCompanies] = useState<GetCompaniesResponse | undefined>();
    const initialize = async () => {
        const response = await CompanyService.get({
            pageSize: 4,
            pageNumber: 1
        });
        setCompanies(response);
        setDocumentTitle('Main', `The main page of the service`);
    };

    useEffect(() => { initialize(); }, []);

    return (
        <ReviewsComponent>
            <Carousel>
                {companies?.entities?.map(x => (
                    <CompanyCard
                        id={x.id || 'undefined'}
                        jobsCount={x.jobsCount}
                        name={x.name}
                        rating={x.averageCompanyRating}
                        region={x.regions?.join(', ')}
                        reviewsCount={x.companyRatingsCount}
                        description={x.description}
                        icon={FileService.downloadLink(x.avatarFileId)}
                        key={x.id}
                        textComponent="h3" />))}
            </Carousel>
            <Flex.Row horizontal={HorizontalAlign.center} flex={0} gap={60} marginTop={60}>
                <Rating value={rating} onChangeValue={x => setRating(x)} height={50} />
                <Button variant="primary" onClick={() => navigate({
                    pathname: routes.jobs,
                    search: createSearchParams({
                        [SearchFilterKeys.minCompanyRating]: rating.toString()
                    }).toString()
                })}>Search by rating</Button>
            </Flex.Row>
            <ReviewsGetReview>
                <ReviewsGetReviewText>
                    Write a review for a trucking company your worked with and
                    <span style={{ color: colors.primaryYellow }}> get unlimited job applications for one month</span>
                </ReviewsGetReviewText>
                <Button variant="primary" onClick={() => navigate(routes.rate)}>Write a company review</Button>
            </ReviewsGetReview>
        </ReviewsComponent>);
}



const FaqComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    jus
`;
const FaqImage = styled.img`
    width: 100%;
    max-height:1000px;
`;
const FaqAccordions = styled.div`
    display: flex;
    flex-direction: column;
    margin: 90px;
    gap: 10px;
`;
const Faq: React.FC = () => {
    const faqs: Array<{
        question: string,
        answer: string
    }> = [{
        question: 'Question 1',
        answer: 'Answer 1'
    }, {
        question: 'Question 2',
        answer: 'Question 2'
    }, {
        question: 'Question 3',
        answer: 'Question 3'
    }];

    const schemaScript = () => (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org/",
                    "@type": "FAQPage",
                    "mainEntity": faqs.map(x => ({
                        "@type": "Question",
                        "name": x.question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": x.answer
                        }
                    }))
                })}
            </script>
        </Helmet>);

    return (
        <FaqComponent>
            <FaqImage src={manInTheCar} />
            <FaqAccordions>
                {faqs.map(x => (<Accordion textComponent="h3" key={x.question} title={x.question}>{x.answer}</Accordion>))}
            </FaqAccordions>
            {schemaScript()}
        </FaqComponent>);
}
export default MainPage;