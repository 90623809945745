import styled from "styled-components";
import { useEffect, useState } from "react";
import { Catalogs, colors, fonts } from "../enums";
import Input from "../components/ui/Input";
import Flex from "../components/ui/flex/Flex";
import Button from "../components/ui/Button";
import { Space, VerticalAlign } from "../components/ui/flex/enums";
import { FormState } from "../types";
import AuthenticationService, { LoginRequest } from "../services/api/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { routes } from "../App";
import CompanyService, { CreateCompanyRequest } from "../services/api/CompanyService";
import Select, { SelectOptionsType } from "../components/ui/Select";
import CatalogService from "../services/api/CatalogService";

const RegisterAsCompanyPageComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
const RegisterAsCompanyPageCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    max-width: 1200px;
    flex: 1;
`;
const RegisterAsCompanyPageError = styled.div`
    display: flex;
    color: ${colors.errorTextColor};
    text-transform: uppercase;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
`;
/** Страница с логином */
const RegisterAsCompanyPage: React.FC = () => {
    const navigate = useNavigate();
    const [regions, setRegions] = useState<SelectOptionsType[] | undefined>();
    const [entity, setEntity] = useState<CreateCompanyRequest>({});
    const [formState, setFormState] = useState<FormState>(FormState.Empty());

    const initialize = async () => {
        const response = await CatalogService.getById(Catalogs.Regions);
        setRegions(response?.values?.map(x => ({ label: x.name || '', value: x.id || '' })));
    };

    /** Сохранить компанию */
    const save = async () => {
        try {
            setFormState(x => x.clear());
            await CompanyService.create(entity);
            navigate(routes.main);
        } catch (error) {
            setFormState(x => x.setError(error));
        } finally {
            setFormState(x => x.setLoading(false));
        }
    };

    useEffect(() => { initialize(); }, []);

    return (
        <RegisterAsCompanyPageComponent>
            <RegisterAsCompanyPageCard>
                <Flex.Column gap={20} flex={1}>
                    <Input
                        label="Login"
                        isRequired
                        value={entity.login || ''}
                        onChangeValue={x => setEntity({ ...entity, login: x })} />
                    <Input
                        label="Password"
                        inputType="password"
                        isRequired
                        value={entity.password || ''}
                        onChangeValue={x => setEntity({ ...entity, password: x })} />
                    <Input
                        label="Name"
                        isRequired
                        value={entity.name || ''}
                        onChangeValue={x => setEntity({ ...entity, name: x })} />
                    <Select
                        label="States"
                        value={regions?.filter(x => entity.regions?.find(r => r == x.value)) ?? []}
                        options={regions ?? []}
                        onMultiSelect={x => setEntity({ ...entity, regions: x.map(i => i.value) })} />
                    <Input
                        inputType="textarea"
                        label="Description"
                        value={entity.description || ''}
                        onChangeValue={x => setEntity({ ...entity, description: x })} />
                    <Input
                        label="Phone"
                        value={entity.phone || ''}
                        onChangeValue={x => setEntity({ ...entity, phone: x })} />
                    <Input
                        label="Email"
                        value={entity.email || ''}
                        onChangeValue={x => setEntity({ ...entity, email: x })} />
                    <Input
                        label="Address"
                        value={entity.address || ''}
                        onChangeValue={x => setEntity({ ...entity, address: x })} />
                    <Input
                        label="Site"
                        value={entity.site || ''}
                        onChangeValue={x => setEntity({ ...entity, site: x })} />
                    <Input
                        label="Usdot Number"
                        value={entity.usdotNumber || ''}
                        onChangeValue={x => setEntity({ ...entity, usdotNumber: x })} />
                    <Input
                        inputType="number"
                        label="Required Drivers Count"
                        value={entity.requiredDriversCount || ''}
                        onChangeValue={x => setEntity({ ...entity, requiredDriversCount: x && parseInt(x) || null })} />
                    <Input
                        inputType="number"
                        label="Current Drivers Count"
                        value={entity.currentDriversCount || ''}
                        onChangeValue={x => setEntity({ ...entity, currentDriversCount: x && parseInt(x) || null })} />
                    <Input
                        inputType="number"
                        label="Year Of Foundation"
                        value={entity.yearOfFoundation || ''}
                        onChangeValue={x => setEntity({ ...entity, yearOfFoundation: x && parseInt(x) || null })} />
                    {formState.error && <RegisterAsCompanyPageError>{formState.error}</RegisterAsCompanyPageError>}
                    <Flex.Row horizontal={Space.between} gap={80}>
                        <Button variant="primary" onClick={save}>Register</Button>
                        <Button variant="transparent" onClick={() => navigate(routes.login)}>Sign in</Button>
                    </Flex.Row>
                </Flex.Column>
            </RegisterAsCompanyPageCard>
        </RegisterAsCompanyPageComponent >
    );
};

export default RegisterAsCompanyPage;