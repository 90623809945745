/* eslint-disable react/prop-types */
import React, {
    forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import styled from 'styled-components';
// import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import Flex from './flex/Flex';
import { colors, fonts, MAX_SAFE_INTEGER32 } from '../../enums';
import { InputTypes } from '../../types';
import ErrorMessage from './ErrorMessage';
import { DateFormats, formatDate, formatNumber } from '../../services/utils';

type Variants = 'primary' | 'bigwhite';

/** Размер */
export enum Size {
    S = 'small',
    M = 'medium',
    L = 'large',
}

export class SizeUtility {
    static getInputFontSize(size?: Size | null | undefined) {
        switch (size) {
            case Size.S:
                return 16;
            case Size.M:
                return 18;
            case Size.L:
            default:
                return 20;
        }
    }

    static getLabelFontSize(size?: Size | null | undefined) {
        switch (size) {
            case Size.S:
                return 14;
            case Size.M:
                return 18;
            case Size.L:
            default:
                return 24;
        }
    }

    static getInputHeight(size?: Size | null | undefined) {
        switch (size) {
            case Size.S:
                return 27;
            case Size.M:
                return 32;
            case Size.L:
            default:
                return 37;
        }
    }

    static getLabelMarginBottom(size?: Size | null | undefined) {
        switch (size) {
            case Size.S:
                return 5;
            case Size.M:
                return 12;
            case Size.L:
            default:
                return 20;
        }
    }
}

interface Props {
    /** Цветовое решение */
    variant?: Variants;
    /** Размер */
    size?: Size | null | undefined;
    /** Обязательное поле */
    isRequired?: boolean;
    /** Оглавление инпута */
    label?: string;
    /** текст в инпуте */
    value?: string | number;
    /** функция обрабатывающая при изменении данных в инпуте */
    onChangeValue?: (value: string) => void;
    /** Текст всплывающей подсказки при наведении на иконку "i" */
    infoText?: string;
    /** заполнитель инпута с указанием необходимости заполнения либо иное */
    placeholder?: string;
    /** если тип поле пароль (password) */
    inputType?: InputTypes;
    /** минимальное значение для input */
    min?: number;
    /** максимальное значение для input */
    max?: number;
    /** при нажатии Enter */
    onEnterPressed?: () => void;
    /** Функция валидации поля, возвращающая текст ошибки, если есть ошибка */
    validateValue?: () => string | undefined;
    /** функция на потерю фокуса */
    onBlur?: () => void;
    /** функция на фокус */
    onFocus?: () => void;
    /** Доступность для изменения */
    isDisabled?: boolean;
    /** С фокусировкой */
    isFocused?: boolean;
    /** Новые данные для полей авторизации (для отключения автозаполнения браузером) */
    isAutoComplete?: boolean;
    /** есть ли чат */
    chatAction?: () => void;
    /** Показать рамку ошибки */
    showErrorBorder?: boolean;
    /** Класс компонента для возможности переопределения */
    className?: string;
}

/** Внешняя обертка  */
const InputContainer = styled(Flex.Column) <{ inputType?: InputTypes, className?: string }>`
    className: ${(props) => props && props.className};
    flex: 1;
    ${props => props.inputType == 'date' ? `max-width: 180px;` : ''}
`;

/** Стилизация контейнера Input компонента */
const Container = styled.div<Partial<Props & { inputType: InputTypes, showError: boolean, variant: Variants }>>`
    display: flex;
    align-items: center;
    background-color: ${(props) => (props.variant == 'primary' ? colors.selectBackgroundGray : colors.white)};
    padding: ${props => props.variant == 'bigwhite' ? 8 : 4}px 6px;
    border-radius: 2px;
    border: ${(props) => (props.showError ? `1px solid ${colors.inputErrorBorder}` : 'none')};
    position: relative;
    color: ${colors.inputText};
    border-radius: 4px;
`;

/** Стилизация компонента Input */
const InputComponent = styled.input<{ inputSize?: Size | null | undefined }>`
    width: 100%;
    background-color: inherit;
    font-family: ${fonts.main};
    border: none;
    outline: none;
    font-size:${(props) => (SizeUtility.getInputFontSize(props?.inputSize))}px;
    height: ${(props) => (SizeUtility.getInputHeight(props?.inputSize))}px;
    color: ${(props) => (props.disabled ? colors.disabledInputText : colors.inputText)};
    ::placeholder{
        color: ${colors.inputPlaceholder};
        font-family: ${fonts.main};
        text-transform: uppercase;
    };
    ::-webkit-inner-spin-button {
        display: none;
    }
    // ::-webkit-calendar-picker-indicator {
    //     color: transparent;
    //     opacity: 1;
    //     background: url(icons.calendar) no-repeat;
    //     background-size: contain;
    //     cursor: pointer;
    // }
    appearance:textfield;
`;
const TextAreaComponent = styled.textarea<{ size?: Size | null | undefined }>`
    width: 100%;
    font-family: ${fonts.main};
    background-color: inherit;
    border: none;
    outline: none;
    font-size:${(props) => (SizeUtility.getInputFontSize(props?.size))}px;
    height: 100px;
    color: ${(props) => (props.disabled ? colors.disabledInputText : colors.inputText)};
    ::placeholder{
        color:  ${colors.inputPlaceholder};
    };
`;

/** Стилизация оглавлений инпута */
export const Label = styled.label<{ isRequired?: boolean, size?: Size | null | undefined }>`
    font-size: ${(props) => (SizeUtility.getLabelFontSize(props?.size))}px;
    font-weight: 400;
    margin-bottom: ${(props) => (SizeUtility.getLabelMarginBottom(props?.size))}px;
    text-transform: uppercase;
    margin-bottom: ${(props) => (SizeUtility.getLabelMarginBottom(props?.size))}px;
    &:after {
        content: ${props => props.isRequired ? '"*"' : ''};
    }
`;

/** Вариант поля для телефонна */
const PhoneInput = styled.input<{ size?: Size | null | undefined }>`
    width: 100%;
    background-color: inherit;
    border: none;
    outline: none;
    font-size:${(props) => (SizeUtility.getInputFontSize(props?.size))}px;
    color: ${(props) => (props.disabled ? colors.disabledInputText : colors.inputText)};
    ::placeholder{
        color:  ${colors.primaryBlue};
    };
`;

/** Интерфейс для функции */
export interface WithValidation {
    validate(): string | undefined;
}

/** Компонент поля ввода input */
const Input = forwardRef<WithValidation, Props>(({
    variant = 'primary',
    size,
    label,
    isRequired = false,
    value,
    onChangeValue,
    placeholder,
    inputType,
    min,
    max = MAX_SAFE_INTEGER32,
    onEnterPressed,
    validateValue,
    onBlur,
    onFocus,
    isDisabled,
    isFocused,
    isAutoComplete = false,
    showErrorBorder,
    className,
}, ref) => {
    /**
     * Если тип поля пароль. Для возможности изменения видимости
     * при клике на кнопку показать пароль
     */
    const [type, setType] = useState(inputType);

    /** Реф для полей с автофокусировкой */
    const focusRef = React.useRef<HTMLInputElement | null>(null);

    /** Фокусировка на инпуте */
    if (isFocused) {
        focusRef?.current?.focus();
    }

    /** Видимость ошибки */
    const [showError, setShowError] = useState(false);
    /** Текст ошибки, если есть ошибка */
    const [error, setError] = useState<string | undefined>(
        validateValue
            ? validateValue()
            : undefined,
    );

    /** Функция для изменения видимости ошибки, если есть ошибка */
    const refreshError = () => {
        const err = validateValue ? validateValue() : undefined;
        setError(err);
        setShowError(!!err);
        return err;
    };

    /** Кастомное поведение для ref */
    useImperativeHandle(
        ref,
        () => ({
            validate() {
                return refreshError();
            },
        }),
    );

    let formattedValue = value || '';

    // Если тип number и есть минимальные или максимальные значения
    if (type === 'number') {
        if (value === 0) {
            formattedValue = '0';
        }

        if (min || max) {
            const toNum = (v?: string | number) => {
                const numValue = v ? +v : 0;
                return Number.isNaN(numValue) ? 0 : numValue;
            };

            const numValue = toNum(value);

            if (numValue < toNum(min)) {
                formattedValue = `${min}`;
            }
            if (numValue > toNum(max)) {
                formattedValue = `${max}`;
            }
        }
    }

    /** Отрисовка инпута в зависимости от типа */
    const renderInput = () => {
        switch (inputType) {
            case 'phone':
            // return (
            //     <NumberFormat
            //         customInput={PhoneInput}
            //         disabled={isDisabled}
            //         format="+7 (###) ###-##-##"
            //         getInputRef={focusRef}
            //         onBlur={refreshError}
            //         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //             if (onChangeValue) {
            //                 onChangeValue(e.target.value);
            //                 refreshError();
            //             }
            //         }}
            //         placeholder="Ввести"
            //         value={value?.toString().replace(/\+7/g, '') || ''}
            //     />
            // );
            case 'date':
                return (
                    <InputComponent
                        ref={focusRef}
                        disabled={isDisabled}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur();
                            }
                            if (refreshError) {
                                refreshError();
                            }
                        }}
                        onChange={(e) => {
                            if (onChangeValue) {
                                onChangeValue(e.target.value);
                            }
                            refreshError();
                        }}
                        onFocus={onFocus}
                        onKeyDown={(event) => {
                            event.preventDefault();
                        }}
                        onKeyUp={(event) => {
                            if (event.key !== 'Enter') {
                                return;
                            }
                            if (onEnterPressed) {
                                onEnterPressed();
                            }
                        }}
                        placeholder={placeholder}
                        type={type}
                        inputSize={size}
                        value={value ? formatDate(value, DateFormats.InputDefault) : ''}
                    />
                );
            case 'dateTime':
                return (
                    <InputComponent
                        ref={focusRef}
                        disabled={isDisabled}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur();
                            }
                            if (refreshError) {
                                refreshError();
                            }
                        }}
                        onChange={(e) => {
                            if (onChangeValue) {
                                const dateTime = e.target.value
                                    ? dayjs(e.target.value)
                                    : undefined;
                                onChangeValue(dateTime?.utc().format() || '');
                            }
                            refreshError();
                        }}
                        onFocus={onFocus}
                        onKeyDown={(event) => {
                            event.preventDefault();
                        }}
                        onKeyUp={(event) => {
                            if (event.key !== 'Enter') {
                                return;
                            }
                            if (onEnterPressed) {
                                onEnterPressed();
                            }
                        }}
                        placeholder={value as string}
                        type="datetime-local"
                        inputSize={size}
                        value={value ? formatDate(value, DateFormats.InputDefault, true) : ''}
                    />
                );
            case 'number':
                return (
                    <InputComponent
                        ref={focusRef}
                        disabled={isDisabled}
                        max={max}
                        min={min}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur();
                            }
                            if (refreshError) {
                                refreshError();
                            }
                        }}
                        onChange={(e) => {
                            if (onChangeValue) {
                                onChangeValue(e.target.value !== '' ? e.target.value : 'NaN');
                                refreshError();
                            }
                        }}
                        onFocus={onFocus}
                        onKeyUp={(event) => {
                            if (event.key !== 'Enter') {
                                return;
                            }
                            if (onEnterPressed) {
                                onEnterPressed();
                            }
                        }}
                        placeholder={placeholder}
                        type={type}
                        inputSize={size}
                        value={formattedValue || ''}
                    />
                );
            case 'money':
                return (
                    <InputComponent
                        ref={focusRef}
                        disabled={isDisabled}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur();
                            }
                            if (refreshError) {
                                refreshError();
                            }
                        }}
                        onChange={(e) => {
                            if (onChangeValue) {
                                onChangeValue(e.target.value !== '' ? e.target.value.replaceAll(/[^0-9]/gi, '') : 'NaN');
                                refreshError();
                            }
                        }}
                        onFocus={onFocus}
                        onKeyUp={(event) => {
                            if (event.key !== 'Enter') {
                                return;
                            }
                            if (onEnterPressed) {
                                onEnterPressed();
                            }
                        }}
                        placeholder={placeholder}
                        type={type}
                        inputSize={size}
                        value={formatNumber(value) || ''}
                    />
                );
            case 'textarea':
                return (
                    <TextAreaComponent
                        autoComplete={isAutoComplete ? undefined : 'new-password'}
                        disabled={isDisabled}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur();
                            }
                            if (refreshError) {
                                refreshError();
                            }
                        }}
                        onChange={(e) => {
                            if (onChangeValue) {
                                onChangeValue(e.target.value);
                                onChangeValue(e.target.value);
                            }
                            refreshError();
                        }}
                        onFocus={onFocus}
                        onKeyUp={(event) => {
                            if (event.key !== 'Enter') {
                                return;
                            }
                            if (onEnterPressed) {
                                onEnterPressed();
                            }
                        }}
                        placeholder={placeholder}
                        size={size}
                        value={formattedValue}
                    />
                );
            default:
                return (
                    <InputComponent
                        ref={focusRef}
                        autoComplete={isAutoComplete ? undefined : 'new-password'}
                        disabled={isDisabled}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur();
                            }
                            if (refreshError) {
                                refreshError();
                            }
                        }}
                        onChange={(e) => {
                            if (onChangeValue) {
                                onChangeValue(e.target.value);
                                onChangeValue(e.target.value);
                            }
                            refreshError();
                        }}
                        onFocus={onFocus}
                        onKeyUp={(event) => {
                            if (event.key !== 'Enter') {
                                return;
                            }
                            if (onEnterPressed) {
                                onEnterPressed();
                            }
                        }}
                        placeholder={placeholder}
                        type={type}
                        inputSize={size}
                        value={formattedValue}
                    />
                );
        }
    };

    return (
        <InputContainer inputType={inputType} className={className}>
            {
                label && (
                    <Label isRequired={isRequired} size={size}>
                        {label}
                    </Label>
                )
            }
            <Container
                inputType={inputType}
                variant={variant}
                showError={showError || showErrorBorder}
            >
                {
                    renderInput()
                }
            </Container>
            {
                showError && !!error && (
                    <ErrorMessage text={error} />
                )
            }
        </InputContainer>
    );
});

Input.displayName = 'Input';

export default Input;
