import conestoga from './assets/icons/conestoga.svg';
import dryVan from './assets/icons/dryVan.svg';
import flatBed from './assets/icons/flatBed.svg';
import refrigirated from './assets/icons/refrigirated.svg';
import specialized from './assets/icons/specialized.svg';
import stepDesk from './assets/icons/stepDesk.svg';

/** Цвета из макета проекта */
export const colors = {
    primaryBlue: '#12294F',
    commentGray: '#5E5E5E',
    white: 'white',
    inputText: 'black',
    cardDescriptionBlack: 'black',
    inputErrorBorder: 'red',
    errorTextColor: 'red',
    inputPlaceholder: 'gray',
    disabledInputText: 'gray',
    primaryYellow: '#F49916',
    cardHeaderBackgroundGray: '#757575',
    cardBodyBackgroundGray: '#DBDBDB',
    selectBackgroundGray: '#ECECEC',
    transparent: 'rgba(0, 0, 0, 0)',
    shadowGray: '#F9F9FA',
    imageBackgroundGray: '#5E5E5E',
}

/** Шрифты */
export const fonts = {
    main: 'Oswald'
};

/** int32 max value */
export const MAX_SAFE_INTEGER32 = 2_147_483_647;

/** Типы траков */
export const truckTypes: Array<{ id: number, icon: string, name: string, tag: string }> = [{
    id: 1,
    icon: conestoga,
    name: 'Conestoga',
    tag: 'conestoga'
}, {
    id: 2,
    icon: dryVan,
    name: 'Dry van',
    tag: 'dry-van'
}, {
    id: 3,
    icon: flatBed,
    name: 'Flat bed',
    tag: 'flat-bed'
}, {
    id: 4,
    icon: refrigirated,
    name: 'Refrigirated',
    tag: 'refrigirated'
}, {
    id: 5,
    icon: specialized,
    name: 'Specialized',
    tag: 'specialized'
}, {
    id: 6,
    icon: stepDesk,
    name: 'Step desk',
    tag: 'step desk'
}];

export const routeTypes: Array<{ name: string, id: number, tag: string }> = [
    {
        name: 'Local',
        id: 1,
        tag: 'local'
    }, {
        name: 'Dedicated',
        id: 2,
        tag: 'dedicated'
    }, {
        name: 'Regional',
        id: 3,
        tag: 'regional'
    }, {
        name: 'LTL',
        id: 4,
        tag: 'ltl'
    }, {
        name: 'OTR',
        id: 5,
        tag: 'otr'
    },];

export const cdlTypes: Array<{ name: string, id: number, tag: string }> = [
    {
        name: 'A',
        id: 1,
        tag: 'cdl-a-class '
    }, {
        name: 'B',
        id: 2,
        tag: 'cdl-b-class'
    }, {
        name: 'C',
        id: 3,
        tag: 'cdl-c-class'
    }];


export const JobTypes = {
    OwnerOperator: 1,
    CompanyDriver: 2,
    Student: 3
};

export const jobTypes: Array<{ name: string, id: number, tag: string }> = [
    {
        name: 'Owner operator',
        id: JobTypes.OwnerOperator,
        tag: 'owner-operator'
    }, {
        name: 'Company driver',
        id: JobTypes.CompanyDriver,
        tag: 'company-driver'
    }, {
        name: 'Student',
        id: JobTypes.Student,
        tag: 'student'
    }];


export const ExperienceTypes = {
    NoExperience: 1,
    OneYear: 2,
    LessThanThreeYears: 3,
    MoreThanThreeYears: 4
};

export const experienceTypes: Array<{ name: string, id: number, tag: string, experienceFrom: number | null, experienceTo: number | null }> = [
    {
        name: 'No experience',
        id: ExperienceTypes.NoExperience,
        tag: 'no-expereince',
        experienceFrom: null,
        experienceTo: 0,
    }, {
        name: 'One year experience',
        id: ExperienceTypes.OneYear,
        tag: '1-year-expereince',
        experienceFrom: 1,
        experienceTo: 1,
    }, {
        name: '2-3 year expereince',
        id: ExperienceTypes.LessThanThreeYears,
        tag: '2-3-expereince',
        experienceFrom: 2,
        experienceTo: 3,
    }, {
        name: '3 year expereince',
        id: ExperienceTypes.MoreThanThreeYears,
        tag: '3-year-expereince',
        experienceFrom: 3,
        experienceTo: null,
    }];

export const SalaryTypes = {
    PerMile: 1,
    Percent: 2,
    Gross: 3
};

export const salaryTypes = [
    { name: 'Per mile', id: SalaryTypes.PerMile },
    { name: 'Percent', id: SalaryTypes.Percent },
    { name: 'Gross', id: SalaryTypes.Gross }
];

export const Endorsements = {
    DoublesTriples: 1,
    Hazmat: 2,
    TankVehicles: 3,
    TankHazmat: 4,
};

export const endorsements = [
    { name: 'Doubles / Triples (T)', id: Endorsements.DoublesTriples },
    { name: 'HAZMAT (H)', id: Endorsements.Hazmat },
    { name: 'Tank Vehicles (N)', id: Endorsements.TankVehicles },
    { name: 'Tank + HAZMAT (X)', id: Endorsements.TankHazmat }
];

export const FreightTypes = {
    Box: 1,
    CarHauler: 2,
    DropHook: 3,
    DryBulk: 4,
    DryVan: 5,
    Flatbed: 6,
    HopperBottom: 7,
    Intermodal: 8,
    OilField: 9,
    OversizeLoad: 10,
    Refrigerated: 11,
    YardSpotter: 12
};

export const freightTypes = [
    { name: 'Box', id: FreightTypes.Box },
    { name: 'Car Hauler', id: FreightTypes.CarHauler },
    { name: 'Drop & Hook', id: FreightTypes.DropHook },
    { name: 'Dry Bulk', id: FreightTypes.DryBulk },
    { name: 'Dry Van', id: FreightTypes.DryVan },
    { name: 'Flatbed', id: FreightTypes.Flatbed },
    { name: 'Hopper Bottom', id: FreightTypes.HopperBottom },
    { name: 'Intermodal', id: FreightTypes.Intermodal },
    { name: 'Oil Field', id: FreightTypes.OilField },
    { name: 'Oversize Load', id: FreightTypes.OversizeLoad },
    { name: 'Refrigerated', id: FreightTypes.Refrigerated },
    { name: 'Yard Spotter', id: FreightTypes.YardSpotter }
];

export const EmploymentTypes = {
    W2: 1,
    _1099: 2,
    Temporary: 3,
    Permament: 4
};

export const employmentTypes = [
    { name: 'W-2', id: EmploymentTypes.W2 },
    { name: '1099', id: EmploymentTypes._1099 },
    { name: 'Temporary', id: EmploymentTypes.Temporary },
    { name: 'Permament', id: EmploymentTypes.Permament }
];

export const TravelPreferences = {
    Northeast: 1,
    Northwest: 2,
    Southeast: 3,
    Southwest: 4,
    Midwest: 5
};

export const travelPreferences = [
    { name: 'Northeast', id: TravelPreferences.Northeast },
    { name: 'Northwest', id: TravelPreferences.Northwest },
    { name: 'Southeast', id: TravelPreferences.Southeast },
    { name: 'Southwest', id: TravelPreferences.Southwest },
    { name: 'Midwest', id: TravelPreferences.Midwest }
];

export const TimeZones = {
    NewYork: 1
};

export const timeZones = [
    { name: 'UTC 04:00 New York', id: TimeZones.NewYork }
]

/** Типы справочников */
export const Catalogs = {
    /** Регионы */
    Regions: '01a0e2ab-0c4f-44e8-801d-3c1f980dd644'
};

export const soloTeamTypes: Array<{ name: string, id: any, tag: string }> = [
    {
        name: 'Solo',
        id: 'false',
        tag: 'solo'
    }, {
        name: 'Team',
        id: 'true',
        tag: 'team'
    }, {
        name: 'Both',
        id: 'null',
        tag: 'both'
    }
];