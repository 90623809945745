import axios from 'axios';
import qs from 'qs';
import jwt_decode from "jwt-decode";

/** Максимальный размер файла */
export const MAX_FILE_SIZE = 512 * 1024 * 1024;

/** Базовый URL */
export const appApiUrl = process.env.REACT_APP_API_URL;

/** Базовые настройки axios */
export const ApiConnection = axios.create({
  baseURL: `${appApiUrl ?? ''}`,
  headers: {
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  maxContentLength: MAX_FILE_SIZE,
  paramsSerializer: {
    serialize: function (params) {
      return qs.stringify(params, { indices: false }); // param=value1&param=value2
    }
  }
});

const unblobError = async (error: unknown) => (error instanceof Blob
  ? JSON.parse(await error.text())
  : error);

/** Порядок обработки ошибок ответа сервера */
ApiConnection.interceptors.response.use((response) => {
  if (response.status === 401 || response.status === 400) {
    throw response;
  }
  return response;
}, async (error) => {
  const errors = error?.response?.data;
  throw await unblobError(errors);
});

/** Токен авторизации */
export const getToken = () => localStorage.getItem('ApiToken');

/** Получить текущего пользователя */
export const getCurrentUserId = () => {
  const token = getToken();
  if (!token)
    return null;

  const content = jwt_decode(token) as any;
  return content.nameid;
}

/** Получить роль текущего пользователя (company/driver) */
export const getUserRole = () => {
  const token = getToken();
  if (!token)
    return null;

  const content = jwt_decode(token) as any;
  return content.role;
}

/** Является ли пользователь водителем */
export const isUserDriver = () => {
  return getUserRole() == 'Driver';
}

/** Токен авторизации */
export const setToken = (token: string) => localStorage.setItem('ApiToken', token);

/** Очистка токена */
export const removeToken = () => localStorage.removeItem('ApiToken');

/** Вставка токена в хедер запроса */
ApiConnection.interceptors.request.use((config) => {
  const token = getToken();
  config.headers!.Authorization = `Bearer ${token}`;
  return config;
});
