/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../enums';
import { HorizontalAlign } from "./flex/enums";
import Flex from "./flex/Flex";
import Checkbox from "./Checkbox";
import { Label, Size } from "./Input";

/** Типы данных в объекте для опции компонента Radio */
export type RadioOptionsType = {
    /** Текст заголовка опции */
    label: string;
    /** применяем как идентификатор опции */
    value: string;
};

export enum RadioOptionsAlign {
    /** Вертикально */
    vertical = 'vertical',
    /** Горизонтально */
    horizontal = 'horizontal',
}

interface Props {
    /** Функция на изменение */
    onChange?: (value: string) => void;
    /** Размер */
    size?: Size | null | undefined;
    /** Заголовок селекта */
    label?: string | React.ReactNode;
    /** Все опции */
    options?: RadioOptionsType[] | undefined;
    /** Значение по умолчанию */
    value?: string;
    /** Выравнивание */
    align?: RadioOptionsAlign | undefined;
}

const RadioContainer = styled.div<Props>`
    display: flex;
    flex-direction: ${(props) => props.align === RadioOptionsAlign.vertical ? 'column' : 'row'};
    align-items: ${(props) => props.align === RadioOptionsAlign.vertical ? 'flex-start' : 'center'};

    & > * {
        margin: ${(props) => props.align === RadioOptionsAlign.vertical ? '0' : '0 10px'};
    }
`;

const Radio: React.FC<Props> = ({
    onChange,
    size,
    label,
    options,
    value,
    align
}) => (
    <Flex.Column horizontal={HorizontalAlign.center}>
        <Label size={size}>{label}</Label>
        <RadioContainer align={align}>
            {options?.map(option =>
                <Checkbox
                    key={option.value}
                    size={size}
                    type="radiobutton"
                    isChecked={option.value === value}
                    onClick={() => onChange && onChange(option.value)}
                    text={option.label || option.value} />)}
        </RadioContainer>
    </Flex.Column>
);


interface BooleanProps {
    /** Функция на изменение */
    onChange?: (value: boolean) => void;
    /** Размер */
    size?: Size | null | undefined;
    /** Заголовок селекта */
    label?: string | React.ReactNode;
    /** Все опции */
    options?: RadioOptionsType[] | undefined;
    /** Значение по умолчанию */
    value?: boolean;
    /** Выравнивание */
    align?: RadioOptionsAlign | undefined;
}

export const BooleanRadio: React.FC<BooleanProps> = ({
    onChange,
    size,
    label,
    value,
    align
}) => {
    const options = [{ label: 'Yes', value: 'true' }, { value: 'false', label: 'No' }];

    return (
        <Radio
            onChange={(x) => onChange && onChange(x === 'true')}
            options={options}
            size={size}
            label={label}
            value={value?.toString()}
            align={align}
        ></Radio>
    );
};

export default Radio;
