import styled from "styled-components";
import Select, { SelectOptionsType } from "../components/ui/Select";
import Flex from "../components/ui/flex/Flex";
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import Checkbox from "../components/ui/Checkbox";
import FilterBox from "../components/FilterBox";
import CompanyCard from "../components/CompanyCard";
import { HorizontalAlign, VerticalAlign } from "../components/ui/flex/enums";
import Rating from "../components/ui/Rating";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CompanyService, { GetCompaniesResponseItem } from "../services/api/CompanyService";
import { Catalogs, experienceTypes, jobTypes, SalaryTypes, salaryTypes } from "../enums";
import CatalogService from "../services/api/CatalogService";
import InfiniteScroll from "react-infinite-scroll-component";
import FileService from "../services/api/FileService";
import { setDocumentTitle } from "../services/utils";
import { SearchFilter } from "../types";
import { Helmet } from "react-helmet";
import { routes } from "../App";

const CompaniesPageComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 90px; 40px;
    gap: 150px;
`;
const CompaniesPageFilters = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0;
    min-width: 350px;
`;
const CompaniesPageCards = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const salaryTypeOptions = salaryTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const orderByOptions = [
    { label: 'Rating (lowest first)', value: '+averageCompanyRating', orderBy: 'averageCompanyRating', isAscending: true },
    { label: 'Rating (highest first)', value: '-averageCompanyRating', orderBy: 'averageCompanyRating', isAscending: false },
    { label: 'Salary (lowest first)', value: '+salaryGross', orderBy: 'salaryGross', isAscending: true },
    { label: 'Salary (highest first)', value: '-salaryGross', orderBy: 'salaryGross', isAscending: false },
];

/** Страница со списком компаний */
const CompaniesPage: React.FC = () => {
    const { tag } = useParams<string>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [regions, setRegions] = useState<SelectOptionsType[] | undefined>();
    const [entities, setEntities] = useState<{
        list: GetCompaniesResponseItem[],
        totalCount: number | null,
        pageNumber: number
    }>({
        list: [],
        totalCount: null,
        pageNumber: 1
    });
    const [orderBy, setOrderBy] = useState<SelectOptionsType | null>(orderByOptions[0]);
    const filter = new SearchFilter().fromTag(tag).fromSearchParams(searchParams);
    filter.regionId ??= regions?.find(x => x.label?.toLowerCase() == tag)?.value ?? null;

    const initialize = async () => {
        const response = await CatalogService.getById(Catalogs.Regions);
        setRegions(response?.values?.map(x => ({ label: x.name || '', value: x.id || '' })));
        setDocumentTitle('Companies search', 'Time to search some companies');
    };

    const loadEntities = async (isReload: boolean = false) => {
        const pageNumber = isReload ? 1 : entities.pageNumber;
        const list = isReload ? [] : entities.list;

        const response = await CompanyService.get({
            jobType: filter.jobType || undefined,
            experienceFrom: experienceTypes.find(x => x.id == filter.experienceType)?.experienceFrom,
            experienceTo: experienceTypes.find(x => x.id == filter.experienceType)?.experienceTo,
            minCompanyRating: filter.minCompanyRating || undefined,
            regionId: filter.regionId ? [filter.regionId] : undefined,
            salaryAsPercent: filter.salaryType == SalaryTypes.Percent.toString() ? filter.salary : undefined,
            salaryGross: filter.salaryType == SalaryTypes.Gross.toString() ? filter.salary : undefined,
            salaryPerMile: filter.salaryType == SalaryTypes.PerMile.toString() ? filter.salary : undefined,
            searchText: filter.searchText || undefined,
            pageSize: 10,
            pageNumber: pageNumber,
            orderBy: orderBy?.orderBy as string,
            isAscending: orderBy?.isAscending as boolean,
        });

        const loaded = response?.entities ?? [];
        setEntities({
            list: list.concat(loaded.filter(entity => !list.find(x => x.id == entity.id))),
            totalCount: response?.totalCount ?? 0,
            pageNumber: pageNumber + 1
        });
    };

    useEffect(() => { initialize(); }, []);
    useEffect(() => { loadEntities(true); }, [searchParams, orderBy]);

    const schemaScript = () => (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "ItemList",
                    "numberOfItems": entities.totalCount,
                    "itemListElement": [
                        entities.list.map((x, index) => ({
                            "@type": "ListItem",
                            "position": index,
                            "url": routes.companyById(x.id!)
                        }))
                    ]
                })}
            </script>
        </Helmet>);

    return (
        <CompaniesPageComponent>
            <CompaniesPageFilters>
                <FilterBox textComponent="h2" title="Filters" size="large">
                    <Flex.Row>
                        <Select
                            options={regions}
                            value={regions?.find(x => x.value == filter.regionId)}
                            onChangeValue={x => { filter.regionId = x?.value || null; setSearchParams(filter.toSearchParams(searchParams)); }}
                            placeholder='States' />
                        <Button variant='dark' size="small" height={45}
                            onClick={() => { filter.regionId = null; setSearchParams(filter.toSearchParams(searchParams)); }}>Any</Button>
                    </Flex.Row>
                    <FilterBox textComponent="h3" title="Salary from">
                        <Flex.Row>
                            <Flex.Column flex={1}>
                                <Select
                                    variant="dark"
                                    options={salaryTypeOptions}
                                    value={salaryTypeOptions.find(x => filter.salaryType == x.value)}
                                    onChangeValue={x => { filter.salaryType = x?.value || null; setSearchParams(filter.toSearchParams(searchParams)); }}
                                    placeholder='CPM / %' />
                            </Flex.Column>
                            <Flex.Column flex={1}>
                                <Input
                                    inputType="number"
                                    value={filter.salary ?? undefined}
                                    onChangeValue={x => { filter.salary = x && parseInt(x) || null; setSearchParams(filter.toSearchParams(searchParams)); }} />
                            </Flex.Column>
                        </Flex.Row>
                    </FilterBox>
                    <FilterBox textComponent="h3" title="Minimum experience">
                        <Flex.Column>
                            {experienceTypes.map(tt => (<Checkbox
                                key={tt.id}
                                isChecked={filter.experienceType == tt.id}
                                onClick={isChecked => {
                                    if (isChecked) {
                                        filter.experienceType = tt.id;
                                        setSearchParams(filter.toSearchParams(searchParams));
                                    }
                                }}
                                text={tt.name}
                                type="radiobutton" />))}
                        </Flex.Column>
                    </FilterBox>
                    <FilterBox textComponent="h3" title="Rating">
                        <Flex.Column gap={12}>
                            {Array.from(Array(5).keys()).map(x => (
                                <Flex.Row key={x} vertical={VerticalAlign.center}>
                                    <Rating
                                        onChangeValue={() => { filter.minCompanyRating = x + 1; setSearchParams(filter.toSearchParams(searchParams)); }}
                                        value={x + 1}
                                        text={'& up'} />
                                </Flex.Row>))}
                        </Flex.Column>
                    </FilterBox>
                    <FilterBox textComponent="h3" title="For driver">
                        {jobTypes.map(tt => (<Checkbox
                            key={tt.id}
                            type="checkbox"
                            isChecked={!!filter.jobType?.find(x => x == tt.id)}
                            onClick={isChecked => {
                                filter.jobType = isChecked
                                    ? filter.jobType?.concat(tt.id)?.filter((x, i, a) => a.indexOf(x) == i) || null
                                    : filter.jobType?.filter(x => x != tt.id) || null;
                                setSearchParams(filter.toSearchParams(searchParams));
                            }}
                            text={tt.name} />))}
                    </FilterBox>
                </FilterBox>
            </CompaniesPageFilters>
            <CompaniesPageCards>
                <Flex.Row maxWidth={400} flexInitial>
                    <Select
                        variant="dark"
                        options={orderByOptions}
                        value={orderBy}
                        onChangeValue={x => setOrderBy(x)}
                        placeholder='Sort by' />
                </Flex.Row>
                <Flex.Column gap={40} horizontal={HorizontalAlign.left} marginTop={50}>
                    <InfiniteScroll
                        dataLength={entities.list.length ?? 0}
                        next={() => loadEntities()}
                        style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
                        hasMore={entities.totalCount == null || entities.list.length < entities.totalCount}
                        loader={<h4>Loading...</h4>}
                    >
                        {entities.list.map(x => (<CompanyCard
                            key={x.id}
                            id={x.id!}
                            name={x.name}
                            icon={FileService.downloadLink(x.avatarFileId)}
                            region={x.regions?.join(', ')}
                            rating={x.averageCompanyRating}
                            jobsCount={x.jobsCount}
                            reviewsCount={x.companyRatingsCount}
                            description={x.description}
                            textComponent="h3" />))}
                    </InfiniteScroll>

                </Flex.Column>
            </CompaniesPageCards>
            {schemaScript()}
        </CompaniesPageComponent>
    );
};

export default CompaniesPage;