import dayjs from "dayjs";
import { InputTypes } from "../types";

/** Возвращает денежный формат c пробелами */
export function formatNumber(num: number | string | undefined, zeroCountAfterPoint?: number) {
    let tempNum = num;
    if (typeof tempNum === 'string') {
        tempNum = tempNum.replaceAll(',', '.');
    }
    if (tempNum === undefined || tempNum === '' || Number.isNaN(+tempNum) || tempNum === null) {
        return '';
    }

    const temp = new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: zeroCountAfterPoint ?? 0,
        minimumFractionDigits: 0,
    }).format(+tempNum);
    if (zeroCountAfterPoint && typeof tempNum === 'string' && tempNum[tempNum.length - 1] === '.') {
        return `${temp}.`;
    }
    return temp;
}

/** Форматы дат */
export const DateFormats = {
    /** Формат по умолчанию в хромиуме и инпуте */
    InputDefault: 'YYYY-MM-DD',
    /** Отображаемый */
    Display: 'DD.MM.YYYY'
};

/** Сформатировать дату */
export function formatDate(
    date: string | Date | number | null | undefined,
    format: string = DateFormats.Display,
    isLocal: boolean = false) {
    if (!date)
        return '';

    return isLocal
        ? dayjs.utc(date).local().format(format)
        : dayjs(date).format(format);
}

/** Для проверки полей на валидность */
export const regExps = {
    emailUnrequired: /(^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$)|^$/i,
    email: /^([a-zA-Z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i,
    phone: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/,
    ogrn: /^\d{10,16}$/,
    inn: /^\d{10,16}$/,
    bik: /^\d{9}$/,
    checkingAccount: /^\d{20}$/,
    login: /^.{6,30}$/,
    password: /(?=^.{6,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/g,
    number: /\d+/,
    money: /[\ds]+/,
    website: /^((https?:\/\/(www\.)?)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{2,256}\.[a-zа-я]{2,64})$/i,
    name: /./,
    surname: /./,
    patronymic: /.*/,
    companyNameFull: /./,
    post: /./,
    text: /./,
    date: /./,
    file: /./,
    dateTime: /./,
    postCode: /./,
    select: /./,
    multiselect: /./,
    textarea: /./,
};

/** Для генерации текста ошибки в инпутах */
export const createInputErrorMessage = (inputType: string): string => {
    switch (inputType) {
        case 'phone':
            return 'Phone format should be +79999999999';
        case 'email':
            return 'Email should be like yourmail@mail.ru';
        case 'emailUnrequired':
            return 'Email should be like yourmail@mail.ru';
        case 'password':
            return 'Password should have at least 6 digits and letters in different cases';
        case 'text':
            return 'This field is required';
        case 'name':
            return 'This field is required';
        case 'surname':
            return 'This field is required';
        case 'login':
            return 'Login should have 6 to 30 symbols';
        case 'website':
            return 'Website format should be like website.com';
        case 'file':
            return 'Attach the file';
        default:
            return 'Field value has invalid format';
    }
};

/** Для проверки валидности полей ввода. Если не валидно, возвращает текст ошибки */
export const validateInput = (value: string | null | undefined, type: InputTypes) => {
    const isValid = regExps[type]?.test(value ?? '');
    if (!isValid) {
        return createInputErrorMessage(type);
    }
    return undefined;
};

/** Установить заголовок сайта */
export function setDocumentTitle(title: string, description: string) {
    document.title = `CDL Jobs - ${title}`;
    document.querySelector('meta[name="description"]')?.setAttribute("content", description);
}