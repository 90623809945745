/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../enums';
import check from '../../assets/icons/check.svg';
import { SizeUtility, Size } from "./Input";

type CheckboxType = 'checkbox' | 'radiobutton';
const grey = '#ECECEC';

interface Props {
    /** Состояние чекбокса */
    isChecked?: boolean;
    /** Размер */
    size?: Size | null | undefined;
    /** Функция на клик */
    onClick?: (isSelected: boolean) => void;
    /** Тип radio/checkbox */
    type?: CheckboxType;
    /** Текст чекбокса */
    text?: string;
    /** не определено */
    indeterminate?: boolean;
    /** не активно */
    disabled?: boolean;
    /** Подсветка выбора */
    highlight?: boolean;
    /** Класс компонента для возможности переопределения */
    className?: string;
}

const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex: initial;
    align-items: center;
`;

/** Стилизация компонента */
const Input = styled.div<Props>`
    min-width: 24px;
    height: 24px;
    background: ${grey} no-repeat center;
    border-radius: ${(props) => (props.type === 'checkbox' ? '5px' : '50%')};
    position: relative;
    background-size: 20px;
    background-position: center;
    ${(props) => {
        if (props.type === 'checkbox') {
            if (props.indeterminate) {
                return `
                    border: none;
                    position: relative;
                    ::before{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    height: 10px;
                    width: 10px;
                    background: ${grey};
                    content:'';
                    transform: translate(-50%,-50%)
                    }
                `;
            }
            if (props.disabled) {
                return `
                    background-color: ${grey};
                    border: none;
                    background-image: ${props.isChecked ? `url(${check})` : 'none'};
                `;
            }
            return `
                border: none;
                background-image: ${props.isChecked ? `url(${check})` : 'none'};
            `;
        }
        if (props.disabled) {
            return `
                background: ${grey};
                border: none;
                cursor: not-allowed
            `;
        }
        return `
            background: ${grey};
            &:before{
            position: absolute;
            content: '';    
            width: 12px;
            top: 6px;
            left: 6px;
            height: 12px;
            background-color: ${props.isChecked ? colors.primaryYellow : grey};
            border-radius: 50%;
            }
        `;
    }}
`;

const Text = styled.div<Props>`
    // font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    fontSize: ${(props) => SizeUtility.getInputFontSize(props.size)};
    line-height: 36px;
    text-transform: uppercase;
    ${(props) => (props.type === 'checkbox' ? `
        order: 1;
        margin: 0 15px;
        ` : `
        order: 2;
        list-style: none;
        margin-left: 15px;
        margin-right: 10px;
        `)}
`;

/** Компонент checkbox/radiobutton */
const Checkbox: React.FC<Props> = ({
    isChecked, size, onClick, type = 'checkbox', text, indeterminate, disabled, highlight, className
}) => (
    <Container
        className={className}
        onClick={disabled ? undefined : (event) => {
            if (onClick) {
                onClick(type == 'checkbox' ? !isChecked : true);
            }
            event.stopPropagation();
        }}
    >
        {
            text && (
                <Text as={type == 'radiobutton' ? 'li' : 'div'} type={type}>
                    {text}
                </Text>
            )
        }
        <Input
            disabled={disabled}
            highlight={highlight}
            indeterminate={indeterminate}
            isChecked={isChecked}
            type={type}
            size={size}
        />
    </Container>

);

export default Checkbox;
