import { ApiConnection } from './ApiConnection';

/** Сервис для запросов компаний */
class CompanyService {
    /** Базовый URL для сервиса */
    static get RoutePrefix(): string {
        return '/api/v1.0/Company';
    }

    /** Получить список сущностей по фильтру */
    static async get(request: {
        searchText?: string | null | undefined,
        jobType?: Array<number> | null | undefined,
        regionId?: Array<string> | null | undefined,
        salaryPerMile?: number | null | undefined,
        salaryGross?: number | null | undefined,
        salaryAsPercent?: number | null | undefined,
        experienceFrom?: number | null | undefined,
        experienceTo?: number | null | undefined,
        minCompanyRating?: number | null | undefined,
        pageNumber?: number | null | undefined,
        pageSize?: number | null | undefined,
        orderBy?: string | null | undefined,
        isAscending?: boolean | null | undefined
    }): Promise<GetCompaniesResponse> {
        const response = await ApiConnection.get<GetCompaniesResponse>(this.RoutePrefix, { params: request });
        return response.data;
    }

    /** Поставить лайк */
    static async like(companyId: string, ratingId: string): Promise<void> {
        return await ApiConnection.put(`${this.RoutePrefix}/${companyId}/Rate/${ratingId}/Like`);
    }

    /** Получить сущность по ИД */
    static async getById(id: string): Promise<GetCompanyByIdResponse> {
        const response = await ApiConnection.get<GetCompanyByIdResponse>(`${this.RoutePrefix}/${id}`);
        return response.data;
    }

    /** Проставить рейтинг компании */
    static async rate(id: string, request: RateCompanyRequest): Promise<void> {
        const response = await ApiConnection.put(`${this.RoutePrefix}/${id}/Rate`, request);
        return response.data;
    }

    /** Создать компанию */
    static async create(request: CreateCompanyRequest): Promise<void> {
        const response = await ApiConnection.post(`${this.RoutePrefix}`, request);
        return response.data;
    }
}
export default CompanyService;

/**
 * ФАК в запросе создания компании
 * @export
 * @interface CreateCompanyRequestFaq
 */
export interface CreateCompanyRequestFaq {
    /**
     * Вопрос
     * @type {string}
     * @memberof CreateCompanyRequestFaq
     */
    question?: string | null;
    /**
     * Ответ
     * @type {string}
     * @memberof CreateCompanyRequestFaq
     */
    answer?: string | null;
}

/**
 * Запрос на создание компании
 * @export
 * @interface CreateCompanyRequest
 */
export interface CreateCompanyRequest {
    /**
     * Логин
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    login?: string | null;
    /**
     * Хэш пароля
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    password?: string | null;
    /**
     * Название
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    name?: string | null;
    /**
     * Номер телефона
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    phone?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    email?: string | null;
    /**
     * Адрес
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    address?: string | null;
    /**
     * Сайт
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    site?: string | null;
    /**
     * USDOT
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    usdotNumber?: string | null;
    /**
     * Нужное количество водителей
     * @type {number}
     * @memberof CreateCompanyRequest
     */
    requiredDriversCount?: number | null;
    /**
     * Количество работников на данный момент
     * @type {number}
     * @memberof CreateCompanyRequest
     */
    currentDriversCount?: number | null;
    /**
     * Год Основания
     * @type {number}
     * @memberof CreateCompanyRequest
     */
    yearOfFoundation?: number | null;
    /**
     * Описание
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    description?: string | null;
    /**
     * ИД файла-аватара
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    avatarFileId?: string | null;
    /**
     * ИД файла-бэкграунда
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    backgroundFileId?: string | null;
    /**
     * ФАК
     * @type {Array<CreateCompanyRequestFaq>}
     * @memberof CreateCompanyRequest
     */
    faq?: Array<CreateCompanyRequestFaq> | null;
    /**
     * Регионы, в которых компания работает
     * @type {Array<string>}
     * @memberof CreateCompanyRequest
     */
    regions?: Array<string> | null;
    /**
     * Файлы картинок
     * @type {Array<string>}
     * @memberof CreateCompanyRequest
     */
    imageFiles?: Array<string> | null;
}

/**
 * Ответ на запрос списка компаний
 * @export
 * @interface GetCompaniesResponse
 */
export interface GetCompaniesResponse {
    /**
     * Список сущностей
     * @type {Array<GetCompaniesResponseItem>}
     * @memberof GetCompaniesResponse
     */
    entities?: Array<GetCompaniesResponseItem> | null;
    /**
     * Общее количество сущностей
     * @type {number}
     * @memberof GetCompaniesResponse
     */
    totalCount?: number;
}

/**
 * Ответ на запрос списка компаний
 * @export
 * @interface GetCompaniesResponseItem
 */
export interface GetCompaniesResponseItem {
    /**
     * ИД
     * @type {string}
     * @memberof GetCompaniesResponseItem
     */
    id?: string;
    /**
     * Название позиции
     * @type {string}
     * @memberof GetCompaniesResponseItem
     */
    name?: string | null;
    /**
     * Описание позиции
     * @type {string}
     * @memberof GetCompaniesResponseItem
     */
    description?: string | null;
    /**
     * Регионы
     * @type {Array<string>}
     * @memberof GetCompaniesResponseItem
     */
    regions?: string[] | null;
    /**
     * ИД файла-аватара компании
     * @type {string}
     * @memberof GetCompaniesResponseItem
     */
    avatarFileId?: string | null;
    /**
     * ИД файла-бэкграунда компании
     * @type {string}
     * @memberof GetCompaniesResponseItem
     */
    backgroundFileId?: string | null;
    /**
     * Количество вакансий
     * @type {number}
     * @memberof GetCompaniesResponseItem
     */
    jobsCount?: number;
    /**
     * Количество оценок
     * @type {number}
     * @memberof GetCompaniesResponseItem
     */
    companyRatingsCount?: number;
    /**
     * Рейтинг компании
     * @type {number}
     * @memberof GetCompaniesResponseItem
     */
    averageCompanyRating?: number;
}


/**
 * Рейтинг в ответе на запрос компании по ИД
 * @export
 * @interface GetCompanyByIdResponseRating
 */
export interface GetCompanyByIdResponseRating {
    /**
     * ИД 
     * @type {string}
     * @memberof GetCompanyByIdResponseRating
     */
    id?: string;
    /**
     * Дата создания рейтинга
     * @type {Date}
     * @memberof GetCompanyByIdResponseRating
     */
    createdOn?: Date;
    /**
     * Рейтинг
     * @type {number}
     * @memberof GetCompanyByIdResponseRating
     */
    rating?: number;
    /**
     * Является ли сотрудник текущим сотрудником компании
     * @type {boolean}
     * @memberof GetCompanyByIdResponseRating
     */
    isEmployeeCurrent?: boolean;
    /**
     * Дата начала работы на компанию
     * @type {Date}
     * @memberof GetCompanyByIdResponseRating
     */
    employedOn?: Date | null;
    /**
     * Дата окончания работы на компанию
     * @type {Date}
     * @memberof GetCompanyByIdResponseRating
     */
    firedOn?: Date | null;
    /**
     * Название работы, которую исполнял сотрудник
     * @type {string}
     * @memberof GetCompanyByIdResponseRating
     */
    jobTitle?: string | null;
    /**
     * Отзыв
     * @type {string}
     * @memberof GetCompanyByIdResponseRating
     */
    review?: string | null;
    /**
     * Плюсы
     * @type {Array<string>}
     * @memberof GetCompanyByIdResponseRating
     */
    pros?: Array<string> | null;
    /**
     * Минусы
     * @type {Array<string>}
     * @memberof GetCompanyByIdResponseRating
     */
    cons?: Array<string> | null;
    /**
     * Пользователи, которые залайкали ревью
     * @type {Array<string>}
     * @memberof GetCompanyByIdResponseRating
     */
    likedUsers?: Array<string> | null;
}

/**
 * Файл в ответе на запрос компании по ИД
 * @export
 * @interface GetCompanyByIdResponseImageFile
 */
export interface GetCompanyByIdResponseImageFile {
    /**
     * ИД файла
     * @type {string}
     * @memberof GetCompanyByIdResponseImageFile
     */
    fileId?: string;
    /**
     * Название файла
     * @type {string}
     * @memberof GetCompanyByIdResponseImageFile
     */
    fileName?: string | null;
    /**
     * Размер файла в байтах
     * @type {number}
     * @memberof GetCompanyByIdResponseImageFile
     */
    size?: number;
    /**
     * Mime-тип
     * @type {string}
     * @memberof GetCompanyByIdResponseImageFile
     */
    contentType?: string | null;
}

/**
 * FAQ в получении компании по ИД
 * @export
 * @interface GetCompanyByIdResponseFaq
 */
export interface GetCompanyByIdResponseFaq {
    /**
     * Вопрос
     * @type {string}
     * @memberof GetCompanyByIdResponseFaq
     */
    question?: string | null;
    /**
     * Ответ
     * @type {string}
     * @memberof GetCompanyByIdResponseFaq
     */
    answer?: string | null;
}

/**
 * Ответ на запрос компании по ИД
 * @export
 * @interface GetCompanyByIdResponse
 */
export interface GetCompanyByIdResponse {
    /**
     * ИД
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    id?: string;
    /**
     * Название
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    name?: string | null;
    /**
     * Номер телефона
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    phoneNumber?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    email?: string | null;
    /**
     * Адрес
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    address?: string | null;
    /**
     * Сайт
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    site?: string | null;
    /**
     * USDOT
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    usdotNumber?: string | null;
    /**
     * Нужное количество водителей
     * @type {number}
     * @memberof GetCompanyByIdResponse
     */
    requiredDriversCount?: number | null;
    /**
     * Количество работников на данный момент
     * @type {number}
     * @memberof GetCompanyByIdResponse
     */
    currentDriversCount?: number | null;
    /**
     * Год Основания
     * @type {number}
     * @memberof GetCompanyByIdResponse
     */
    yearOfFoundation?: number | null;
    /**
     * Описание
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    description?: string | null;
    /**
     * ИД файла-аватара
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    avatarFileId?: string | null;
    /**
     * ИД файла-бэкграунда компании
     * @type {string}
     * @memberof GetCompanyByIdResponse
     */
    backgroundFileId?: string | null;
    /**
     * Названия регионов, где работает компания
     * @type {Array<string>}
     * @memberof GetCompanyByIdResponse
     */
    regions?: Array<string> | null;
    /**
     * ФАК
     * @type {Array<GetCompanyByIdResponseFaq>}
     * @memberof GetCompanyByIdResponse
     */
    faq?: Array<GetCompanyByIdResponseFaq> | null;
    /**
     * Рейтинги
     * @type {Array<GetCompanyByIdResponseRating>}
     * @memberof GetCompanyByIdResponse
     */
    ratings?: Array<GetCompanyByIdResponseRating> | null;
    /**
     * Рейтинги
     * @type {Array<GetCompanyByIdResponseImageFile>}
     * @memberof GetCompanyByIdResponse
     */
    imageFiles?: Array<GetCompanyByIdResponseImageFile> | null;
}

/**
 * Запрос на оценку компании
 * @export
 * @interface RateCompanyRequest
 */
export interface RateCompanyRequest {
    /**
     * Рейтинг
     * @type {number}
     * @memberof RateCompanyRequest
     */
    rating?: number;
    /**
     * Является ли сотрудник текущим сотрудником компании
     * @type {boolean}
     * @memberof RateCompanyRequest
     */
    isEmployeeCurrent?: boolean;
    /**
     * Дата начала работы на компанию
     * @type {Date}
     * @memberof RateCompanyRequest
     */
    employedOn?: Date | string | null;
    /**
     * Дата окончания работы на компанию
     * @type {Date}
     * @memberof RateCompanyRequest
     */
    firedOn?: Date | string | null;
    /**
     * Название работы, которую исполнял сотрудник
     * @type {string}
     * @memberof RateCompanyRequest
     */
    jobTitle?: string | null;
    /**
     * Отзыв
     * @type {string}
     * @memberof RateCompanyRequest
     */
    review?: string | null;
    /**
     * Плюсы
     * @type {Array<string>}
     * @memberof RateCompanyRequest
     */
    pros?: Array<string> | null;
    /**
     * Минусы
     * @type {Array<string>}
     * @memberof RateCompanyRequest
     */
    cons?: Array<string> | null;
}
