import React, { useState } from 'react';
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Flex from './flex/Flex';
import { colors, fonts } from '../../enums';

/** Пропсы */
export interface Props {
    /** Табки с 'data-label' - заголовком табки */
    children: React.ReactElement<{ ['data-label']: string }>[];
    /** табки прилипнут к верху */
    stickToTop?: boolean;
}
const TabButton = styled.div<{ isActive: boolean, stickToTop: boolean }>`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 39px;
    padding: 10px 60px;
    white-space: nowrap;
    text-transform: uppercase;
    background-color: ${props => props.isActive ? colors.primaryYellow : '#5E5E5E'};
    color: ${colors.white};
    cursor: pointer;
    border-radius: ${props => props.stickToTop ? '0 0 10px 10px' : '10px 10px 0 0'};
`;
/** Компонент табпанельки */
const Tabpanel: React.FC<Props> = ({
    children,
    stickToTop = true
}) => {
    const [activeTab, setActiveTab] = useState<string>(children[0].props['data-label']);

    return (
        <Flex.Column flexInitial>
            <Flex.Row gap={15}>
                {children.map(x => (
                    <TabButton
                        stickToTop={stickToTop}
                        key={x.props['data-label']}
                        isActive={activeTab == x.props['data-label']}
                        onClick={() => setActiveTab(x.props['data-label'])}>
                        {x.props['data-label']}
                    </TabButton>))}
            </Flex.Row>
            <>
                {children.map(x => activeTab == x.props['data-label'] ? x : (<div style={{ display: 'none' }}>{x}</div>))}
            </>
        </Flex.Column>
    );
};
export default Tabpanel;
