import styled from "styled-components";
import { cdlTypes, colors, fonts, routeTypes, truckTypes } from "../enums";
import { Space } from "./ui/flex/enums";
import Flex from "./ui/flex/Flex";
import noCardLogo from '../assets/icons/noCardLogo.svg';
import Button from "./ui/Button";
import { useNavigate } from "react-router-dom";
import report from '../assets/icons/report.svg';

const DriverCardComponent = styled.div`
    display: flex;
    flex-direction: row;
    padding: 40px;
    gap: 80px;
    background-color: ${colors.cardBodyBackgroundGray};
    border-radius: 10px;
    max-width: 1000px;
    align-items: center;
`;
const DriverCardIcon = styled.img`
    height: 320px !important;
    width: 320px !important;
    border-radius: 160px;
`;
const DriverCardCounter = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 56px;
    color: ${colors.commentGray};
`;
const DriverCardTitle = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 59px;
    color: ${colors.primaryBlue};
    margin-top: 15px;
    text-align: left;
`;
const DriverCardRegion = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 49px;
    color: ${colors.commentGray};
    margin-top: 3px;
    text-align: left;
`;
const DriverCardDescription = styled.div`
    text-overflow: ellipsis;
    margin-top: 25px;
    overflow: hidden;
    color: ${colors.cardDescriptionBlack};
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-align: left;
`;

/** Карточка водителя */
const DriverCard: React.FC<{
    id: string,
    header: string | undefined,
    name: string | null | undefined,
    experience?: number | null | undefined,
    region: string | undefined,
    truckTypes: number[] | undefined,
    drivingLicenseCategory?: number | null | undefined,
    routePreference?: number | undefined,
    isTeam?: boolean | undefined,
    icon?: string | null | undefined,
}> = (props) => {
    const navigate = useNavigate();

    return (<DriverCardComponent>
        <DriverCardIcon
            src={props.icon ?? noCardLogo}
            alt='Аватар водителя'
            onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = noCardLogo;
            }} />
        <Flex.Column>
            <Flex.Row horizontal={Space.between}>
                <DriverCardCounter>{props.header}</DriverCardCounter>
                <Button icon={report} variant="transparent">Report</Button>
            </Flex.Row>
            <DriverCardTitle>{props.name}</DriverCardTitle>
            <DriverCardRegion>{props.region}</DriverCardRegion>
            <DriverCardDescription>
                <p>Experience: {props.experience}</p>
                <p>Trailer(s): {props.truckTypes?.map(x => truckTypes.find(tt => tt.id == x)?.name)?.join(', ')}</p>
                <p>CDL type: {cdlTypes.find(x => x.id == props.drivingLicenseCategory)?.name}</p>
                <p>Route preference: {routeTypes.find(x => x.id == props.routePreference)?.name}</p>
                <p>{props.isTeam ? 'Team' : 'Solo'}</p>
            </DriverCardDescription>
            <Flex.Row horizontal={Space.between} marginTop={20} gap={20}>
                <Button variant="primary">Contact</Button>
                <Button variant="primary">See all experience</Button>
            </Flex.Row>
        </Flex.Column>
    </DriverCardComponent>);
};

export default DriverCard;