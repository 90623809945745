import { ApiConnection } from './ApiConnection';

/** Сервис для запросов водителей */
class DriverService {
    /** Базовый URL для сервиса */
    static get RoutePrefix(): string {
        return '/api/v1.0/Driver';
    }

    /** Получить сущность по ИД */
    static async getById(id: string): Promise<GetDriverByIdResponse> {
        const response = await ApiConnection.get<GetDriverByIdResponse>(`${this.RoutePrefix}/${id}`);
        return response.data;
    }

    /** Создать водителя */
    static async create(request: CreateDriverRequest): Promise<void> {
        const response = await ApiConnection.post(`${this.RoutePrefix}`, request);
        return response.data;
    }

    /** Обновить данные водителя */
    static async update(request: UpdateDriverRequest): Promise<void> {
        const response = await ApiConnection.put(`${this.RoutePrefix}`, request);
        return response.data;
    }
}
export default DriverService;

/**
 * История работы водителя
 * @export
 * @interface CreateDriverRequestEmployment
 */
export interface CreateDriverRequestEmployment {
    /**
     * ИД компании
     * @type {string}
     * @memberof CreateDriverRequestEmployment
     */
    companyId?: string | null;
    /**
     * Название компании
     * @type {string}
     * @memberof CreateDriverRequestEmployment
     */
    companyName?: string | null;
    /**
     * ИД региона
     * @type {string}
     * @memberof CreateDriverRequestEmployment
     */
    regionId?: string | null;
    /**
     * Название региона
     * @type {string}
     * @memberof CreateDriverRequestEmployment
     */
    regionName?: string | null;
    /**
     * Текущая работа
     * @type {boolean}
     * @memberof CreateDriverRequestEmployment
     */
    isCurrentEmployment?: boolean | null;
    /**
     * Телефон
     * @type {string}
     * @memberof CreateDriverRequestEmployment
     */
    phone?: string | null;
    /**
     * Дата начала работы
     * @type {Date}
     * @memberof CreateDriverRequestEmployment
     */
    employeedFrom?: Date | string | null;
    /**
     * Дата окончания работы
     * @type {Date}
     * @memberof CreateDriverRequestEmployment
     */
    employeedTo?: Date | string | null;
}

/**
 * Запрос создания водителя
 * @export
 * @interface CreateDriverRequest
 */
export interface CreateDriverRequest {
    /**
     * Логин
     * @type {string}
     * @memberof CreateDriverRequest
     */
    login?: string | null;
    /**
     * Хэш пароля
     * @type {string}
     * @memberof CreateDriverRequest
     */
    password?: string | null;
    /**
     * Название
     * @type {string}
     * @memberof CreateDriverRequest
     */
    name?: string | null;
    /**
     * Номер телефона
     * @type {string}
     * @memberof CreateDriverRequest
     */
    phone?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof CreateDriverRequest
     */
    email?: string | null;
    /**
     * Адрес
     * @type {string}
     * @memberof CreateDriverRequest
     */
    address?: string | null;
    /**
     * Имя
     * @type {string}
     * @memberof CreateDriverRequest
     */
    firstName?: string | null;
    /**
     * Фамилия
     * @type {string}
     * @memberof CreateDriverRequest
     */
    lastName?: string | null;
    /**
     * Таймзона
     * @type {number}
     * @memberof CreateDriverRequest
     */
    timeZone?: number | null;
    /**
     * Город
     * @type {string}
     * @memberof CreateDriverRequest
     */
    city?: string | null;
    /**
     * Регион
     * @type {string}
     * @memberof CreateDriverRequest
     */
    regionId?: string | null;
    /**
     * Регион лицензии
     * @type {string}
     * @memberof CreateDriverRequest
     */
    licenseRegionId?: string | null;
    /**
     * Почтовый индекс
     * @type {string}
     * @memberof CreateDriverRequest
     */
    zip?: string | null;
    /**
     * Нарушения
     * @type {number}
     * @memberof CreateDriverRequest
     */
    movingViolations?: number | null;
    /**
     * Аварии
     * @type {number}
     * @memberof CreateDriverRequest
     */
    preventableAccidents?: number | null;
    /**
     * Одобрения
     * @type {number}
     * @memberof CreateDriverRequest
     */
    endorsements?: number | null;
    /**
     * Год прав
     * @type {number}
     * @memberof CreateDriverRequest
     */
    licenseSuspensionYear?: number | null;
    /**
     * Год убеждения (ХЗ)
     * @type {number}
     * @memberof CreateDriverRequest
     */
    convictionYear?: number | null;
    /**
     * Есть ли карточка твикс
     * @type {boolean}
     * @memberof CreateDriverRequest
     */
    hasTwicCard?: boolean | null;
    /**
     * Номер прав
     * @type {string}
     * @memberof CreateDriverRequest
     */
    licenseNumber?: string | null;
    /**
     * Дата рождения
     * @type {Date}
     * @memberof CreateDriverRequest
     */
    bornOn?: Date | string | null;
    /**
     * Тип шумахера
     * @type {number}
     * @memberof CreateDriverRequest
     */
    jobType?: number;
    /**
     * Права
     * @type {number}
     * @memberof CreateDriverRequest
     */
    drivingLicenseCategory?: number;
    /**
     * Опыт
     * @type {number}
     * @memberof CreateDriverRequest
     */
    totalExpirience?: number | null;
    /**
     * ИД файла CV
     * @type {string}
     * @memberof CreateDriverRequest
     */
    cvFileId?: string | null;
    /**
     * ИД файла аватара
     * @type {string}
     * @memberof CreateDriverRequest
     */
    avatarFileId?: string | null;
    /**
     * Предпочитаемый тип маршрутов
     * @type {Array<number>}
     * @memberof CreateDriverRequest
     */
    preferredRouteTypes?: Array<number> | null;
    /**
     * Предпочитаемый тип трейлеров
     * @type {Array<number>}
     * @memberof CreateDriverRequest
     */
    preferredTruckTypes?: Array<number> | null;
    /**
     * Работа в команде
     * @type {boolean}
     * @memberof CreateDriverRequest
     */
    isTeam?: boolean | null;
    /**
     * Заинтересован в лизинге
     * @type {boolean}
     * @memberof CreateDriverRequest
     */
    isInterestedInLease?: boolean | null;
    /**
     * История работы
     * @type {Array<CreateDriverRequestEmployment>}
     * @memberof CreateDriverRequest
     */
    employments?: Array<CreateDriverRequestEmployment> | null;
}

/**
 * Ответ на запрос водителя по ИД
 * @export
 * @interface GetDriverByIdResponse
 */
export interface GetDriverByIdResponse {
    /**
     * Логин
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    login?: string | null;

    /**
     * Является ли пользователь администратором
     * @type {boolean}
     * @memberof GetDriverByIdResponse
     */
    isAdministrator?: boolean | null;

    /**
     * Имя
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    name?: string | null;

    /**
     * Email
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    email?: string | null;

    /**
     * Телефон
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    phone?: string | null;

    /**
     * ИД файла-аватара
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    avatarFileId?: string | null;

    /**
     * Адрес
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    address?: string | null;

    /**
     * Имя
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    firstName?: string | null;

    /**
     * Фамилия
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    lastName?: string | null;

    /**
     * Таймзона
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    timeZone?: number | null;

    /**
     * Город
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    city?: string | null;

    /**
     * Регион лицензии
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    regionId?: string | null;

    /**
     * Регион лицензии
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    licenseRegionId?: string | null;

    /**
     * Почтовый индекс
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    zip?: string | null;

    /**
     * Нарушения
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    movingViolations?: number | null;

    /**
     * Аварии
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    preventableAccidents?: number | null;

    /**
     * Одобрения
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    endorsements?: number | null;

    /**
     * Год прав
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    licenseSuspensionYear?: number | null;

    /**
     * Год убеждения (ХЗ)
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    convictionYear?: number | null;

    /**
     * Есть ли карточка твикс
     * @type {boolean}
     * @memberof GetDriverByIdResponse
     */
    hasTwicCard?: boolean | null;

    /**
     * Номер прав
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    licenseNumber?: string | null;

    /**
     * Дата рождения
     * @type {Date}
     * @memberof GetDriverByIdResponse
     */
    bornOn?: Date | null;

    /**
     * Тип шумахера
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    jobType?: number | null;

    /**
     * Права
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    drivingLicenseCategory?: number | null;

    /**
     * Опыт
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    totalExpirience?: number | null;

    /**
     * ИД файла CV
     * @type {number}
     * @memberof GetDriverByIdResponse
     */
    cvFileId?: string | null;

    /**
     * Название файла CV
     * @type {string}
     * @memberof CreateDriverRequest
     */
    cvFileName?: string | null;

    /**
     * Предпочитаемый тип маршрутов
     * @type {number[]}
     * @memberof GetDriverByIdResponse
     */
    preferredRouteTypes?: number[] | null;

    /**
     * Предпочитаемый тип трейлеров
     * @type {number[]}
     * @memberof GetDriverByIdResponse
     */
    preferredTruckTypes?: number[] | null;

    /**
     * Работа в команде
     * @type {boolean}
     * @memberof GetDriverByIdResponse
     */
    isTeam?: boolean | null;

    /**
     * Заинтересован в лизинге
     * @type {boolean}
     * @memberof GetDriverByIdResponse
     */
    isInterestedInLease?: boolean | null;

    /**
     * История работы
     * @type {Array<CreateDriverRequestEmployment>}
     * @memberof CreateDriverRequest
     */
    employments?: Array<CreateDriverRequestEmployment> | null;

    /**
     * Отклики на вакансии
     * @type {Array<GetDriverByIdResponseJobApplication>}
     * @memberof CreateDriverRequest
     */
    jobApplications?: Array<GetDriverByIdResponseJobApplication> | null;

    /**
     * Регион
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    regionName?: string | null;

    /**
     * Регион лицензии
     * @type {string}
     * @memberof GetDriverByIdResponse
     */
    licenseRegionName?: string | null;
}

/**
 * Отклик на вакансию
 * @export
 * @interface GetDriverByIdResponseJobApplication
 */
export interface GetDriverByIdResponseJobApplication {
    /**
     * ИД
     * @type {string}
     * @memberof GetDriverByIdResponseJobApplication
     */
    id?: string | null;

    /**
     * Дата создания
     * @type {string}
     * @memberof GetDriverByIdResponseJobApplication
     */
    createdOn?: string | null;

    /**
     * Работа
     * @type {string}
     * @memberof GetDriverByIdResponseJobApplication
     */
    jobName?: string | null;

    /**
     * Компания
     * @type {string}
     * @memberof GetDriverByIdResponseJobApplication
     */
    companyName?: string | null;
}

/**
 * Запрос изменения данных водителя.
 * @export
 * @interface UpdateDriverRequest
 */
export interface UpdateDriverRequest {
    /**
     * Новый пароль
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    password?: string | null;

    /**
     * Имя
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    name?: string | null;

    /**
     * Email
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    email?: string | null;

    /**
     * Телефон
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    phone?: string | null;

    /**
     * ИД файла-аватара
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    avatarFileId?: string | null;

    /**
     * Адрес
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    address?: string | null;

    /**
     * Имя
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    firstName?: string | null;

    /**
     * Фамилия
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    lastName?: string | null;

    /**
     * Таймзона
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    timeZone?: number | null;

    /**
     * Город
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    city?: string | null;

    /**
     * Регион лицензии
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    regionId?: string | null;

    /**
     * Регион лицензии
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    licenseRegionId?: string | null;

    /**
     * Почтовый индекс
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    zip?: string | null;

    /**
     * Нарушения
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    movingViolations?: number | null;

    /**
     * Аварии
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    preventableAccidents?: number | null;

    /**
     * Одобрения
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    endorsements?: number | null;

    /**
     * Год прав
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    licenseSuspensionYear?: number | null;

    /**
     * Год убеждения (ХЗ)
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    convictionYear?: number | null;

    /**
     * Есть ли карточка твикс
     * @type {boolean}
     * @memberof UpdateDriverRequest
     */
    hasTwicCard?: boolean | null;

    /**
     * Номер прав
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    licenseNumber?: string | null;

    /**
     * Дата рождения
     * @type {Date}
     * @memberof UpdateDriverRequest
     */
    bornOn?: Date | null;

    /**
     * Тип шумахера
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    jobType?: number | null;

    /**
     * Права
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    drivingLicenseCategory?: number | null;

    /**
     * Опыт
     * @type {number}
     * @memberof UpdateDriverRequest
     */
    totalExpirience?: number | null;

    /**
     * ИД файла CV
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    cvFileId?: string | null;

    /**
     * Предпочитаемый тип маршрутов
     * @type {number[]}
     * @memberof UpdateDriverRequest
     */
    preferredRouteTypes?: number[] | null;

    /**
     * Предпочитаемый тип трейлеров
     * @type {number[]}
     * @memberof UpdateDriverRequest
     */
    preferredTruckTypes?: number[] | null;

    /**
     * Работа в команде
     * @type {boolean}
     * @memberof UpdateDriverRequest
     */
    isTeam?: boolean | null;

    /**
     * Заинтересован в лизинге
     * @type {boolean}
     * @memberof UpdateDriverRequest
     */
    isInterestedInLease?: boolean | null;

    /**
     * История работы
     * @type {Array<CreateDriverRequestEmployment>}
     * @memberof CreateDriverRequest
     */
    employments?: Array<CreateDriverRequestEmployment> | null;

    /**
     * Регион
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    regionName?: string | null;

    /**
     * Регион лицензии
     * @type {string}
     * @memberof UpdateDriverRequest
     */
    licenseRegionName?: string | null;
}