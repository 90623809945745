import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tabpanel from "../components/ui/Tabpanel";
import { Catalogs, colors, fonts, cdlTypes, jobTypes, routeTypes, freightTypes, endorsements } from "../enums";
import edit from "../assets/icons/edit.svg";
import noAvatar from "../assets/icons/noAvatar.svg";
import Flex from "../components/ui/flex/Flex";
import { HorizontalAlign, Space, VerticalAlign } from "../components/ui/flex/enums";
import Button from "../components/ui/Button";
import JobCard from "../components/JobCard";
import JobService, { GetJobsResponse } from "../services/api/JobService";
import FileService from "../services/api/FileService";
import CatalogService, { GetCatalogByIdResponseValue } from "../services/api/CatalogService";
import DriverAccountDriverInfoEditModal from './DriverAccountDriverInfoEditModal';
import DriverAccountPersonalInfoEditModal from './DriverAccountPersonalInfoEditModal';
import DriverAccountPreferencesEditModal from './DriverAccountPreferencesEditModal';
import DriverAccountEmploymentsEditModal from './DriverAccountEmploymentsEditModal';
import DriverService, { GetDriverByIdResponse, GetDriverByIdResponseJobApplication } from "../services/api/DriverService";
import { setDocumentTitle } from "../services/utils";

const DriverAccountPageComponent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 90px;
`;
const DriverAccountTab = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 40px;
    gap: 20px;
    background-color: ${colors.cardBodyBackgroundGray};
    border-radius 0 0 30px 30px;
`;
const DriverAccountTabCard = styled.div<{ noTopPadding?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${props => props.noTopPadding ? '0 40px 40px 40px' : '40px'};
    background-color: ${colors.white};
    border-radius 20px;
`;
const DriverAccountTabCardUserImage = styled.div`
    background-color: ${colors.imageBackgroundGray};
    padding: 20px;
    border-radius: 20px;
    margin-right: 20px;
`;
const DriverAccountTabCardHr = styled.hr`
    background-color: ${colors.cardBodyBackgroundGray};
    height: 6px;
    border: none;
    margin-top: 10px;
`;
const DriverAccountHeader = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: ${colors.primaryBlue};
`;
const DriverAccountSubHeader = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: ${colors.commentGray};
`;
const DriverAccountCompleteness = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 35px;
    align-items: center;
    background-color: ${colors.cardBodyBackgroundGray};
`;
const DriverAccountCompletenessReady = styled.div<{ completed: number }>`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    flex: ${props => props.completed}% 0 0;
    background-color: ${colors.primaryYellow};
    color: ${colors.white};
    text-align: center;
`;

const DriverAccountTableHeader = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    background-color: ${colors.cardHeaderBackgroundGray};
    color: ${colors.white};
    text-align: center;
    text-transform: uppercase;
    padding: 20px;
`;
const DriverAccountTableColumn = styled.div`
    display: flex;
    flex: 1;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    background-color: ${colors.cardBodyBackgroundGray};
    color: ${colors.commentGray};
    text-transform: uppercase;
    padding: 20px;
`;



/** Страница с аккаунтом водителя */
const DriverAccountPage: React.FC = () => {
    const { id } = useParams<string>();
    const [jobs, setJobs] = useState<GetJobsResponse | undefined>();
    const [companies, setCompanies] = useState<GetDriverByIdResponseJobApplication[] | undefined>();
    const [entity, setEntity] = useState<GetDriverByIdResponse>({});
    // Список штатов
    const [regions, setRegions] = useState<Array<GetCatalogByIdResponseValue> | null | undefined>();

    const [isEditDriverAccountPersonalInfoModalOpened, setIsEditDriverAccountPersonalInfoModalOpened] = useState(false);
    const [isEditDriverAccountDirverInfoModalOpened, setIsEditDriverAccountDirverInfoModalOpened] = useState(false);
    const [isEditDriverAccountPreferencesModalOpened, setIsEditDriverAccountPreferencesModalOpened] = useState(false);
    const [isEditDriverAccountEmploymentsModalOpened, setIsEditDriverAccountEmploymentsModalOpened] = useState(false);

    const initialize = async () => {
        const jobsResponse = await JobService.get({
            pageSize: 4,
            pageNumber: 1
        });
        setJobs(jobsResponse);

        // Подгружаем список штатов
        const regionsResponse = await CatalogService.getById(Catalogs.Regions);
        setRegions(regionsResponse?.values);

        const response = await DriverService.getById(id || "");
        setEntity(response);
        setCompanies(response.jobApplications || []);

        setDocumentTitle(response?.name ?? `Driver account ${id}`, `Page with Driver account ${response?.name ?? id}`);
    };

    /**
     * Распечатать дату в поле.
     * @param date Дата в формате строки или даты.
     * @param showTime Показывать ли время
     * @returns Строка с корректной датой.
     */
    const printDate = (date: Date | string | null | undefined, showTime: boolean = false) => {
        if (!date)
            return "";

        if (date instanceof Date)
            return showTime ? date.toLocaleString() : date.toDateString();

        return showTime ? new Date(date).toLocaleString() : new Date(date).toDateString();
    }

    useEffect(() => { initialize(); }, []);

    return (
        <>
            <DriverAccountPageComponent>

                <Tabpanel stickToTop={false}>
                    <div data-label='My profile'>
                        <DriverAccountTab>
                            <Flex.Column flex={1}>
                                <DriverAccountTabCard>
                                    <Flex.Row gap={20} horizontal={Space.between} flex={0} margin="0 0 20px">
                                        <DriverAccountTabCardUserImage>
                                            <img src={noAvatar} height={100} width={100} />
                                        </DriverAccountTabCardUserImage>
                                        <Flex.Column>
                                            <DriverAccountHeader>{entity.name}</DriverAccountHeader>
                                            <DriverAccountSubHeader>Company name</DriverAccountSubHeader>
                                        </Flex.Column>
                                        <Button icon={edit} size="nopadding" height={30} width={30} onClick={() => setIsEditDriverAccountPersonalInfoModalOpened(true)} />
                                    </Flex.Row>
                                    <DriverAccountTabCardHr />
                                    <Flex.Column gap={15} flexInitial margin="20px 0">
                                        <DriverAccountHeader>Personal information</DriverAccountHeader>
                                        <DriverAccountStats name="Address" value={entity.address} />
                                        <DriverAccountStats name="Email address" value={entity.email} />
                                        <DriverAccountStats name="Phone number" value={entity.phone} />
                                        <DriverAccountStats name="Date of birth" value={printDate(entity.bornOn)} />
                                    </Flex.Column>
                                    <DriverAccountTabCardHr />
                                    <Button variant="primary-transparent">Delete account</Button>
                                </DriverAccountTabCard>
                            </Flex.Column>
                            <Flex.Column flex={1} gap={20}>
                                <Flex.Row flexInitial>
                                    <DriverAccountTabCard>
                                        <DriverAccountHeader>Profile completeness</DriverAccountHeader>
                                        <DriverAccountCompleteness>
                                            <DriverAccountCompletenessReady completed={60}>60%</DriverAccountCompletenessReady>
                                        </DriverAccountCompleteness>
                                    </DriverAccountTabCard>
                                </Flex.Row>
                                <Flex.Row>
                                    <DriverAccountTabCard noTopPadding>
                                        <Flex.Column margin={'0 25px'}>
                                            <Tabpanel stickToTop={true}>
                                                <div data-label='Driver information'>
                                                    <Flex.Column gap={10}>
                                                        <Flex.Row horizontal={HorizontalAlign.right} marginTop={20}>
                                                            <Button icon={edit} size="nopadding" height={30} width={30} onClick={() => setIsEditDriverAccountDirverInfoModalOpened(true)} />
                                                        </Flex.Row>
                                                        <DriverAccountStats hasBorder name="Driver type" value={jobTypes.find(x => x.id == entity.jobType)?.name} />
                                                        <DriverAccountStats hasBorder name="Endorsements" value={endorsements.find(x => x.id == entity.endorsements)?.name} />
                                                        <DriverAccountStats hasBorder name="CDL Number" value={entity.licenseNumber} />
                                                        <DriverAccountStats hasBorder name="CDL State" value={regions?.find(x => x.id == entity.licenseRegionId)?.name} />
                                                        <DriverAccountStats hasBorder name="CDL Class" value={cdlTypes.find(x => x.id == entity.drivingLicenseCategory)?.name} />
                                                        <DriverAccountStats hasBorder name="Accidents" value={entity.preventableAccidents} />
                                                        <DriverAccountStats hasBorder name="Moving violations" value={entity.movingViolations} />
                                                        <DriverAccountStats hasBorder name="Suspended" value={entity.licenseSuspensionYear} />
                                                        <DriverAccountStats hasBorder name="DUI" value={entity.convictionYear} />
                                                        <DriverAccountStats hasBorder name="Twic card" value={entity.hasTwicCard ? "Yes" : "No"} />
                                                    </Flex.Column>
                                                </div>
                                                <div data-label='Experience'>
                                                    <Flex.Column gap={10}>
                                                        <Flex.Row horizontal={HorizontalAlign.right} marginTop={20}>
                                                            <Button icon={edit} size="nopadding" height={30} width={30} onClick={() => setIsEditDriverAccountEmploymentsModalOpened(true)} />
                                                        </Flex.Row>
                                                        <DriverAccountStats hasBorder name="Freight types" value="25.07.1993" />
                                                        <DriverAccountStats hasBorder name="Total experience" value={entity.totalExpirience} />
                                                        <DriverAccountHeader>Uploaded resume</DriverAccountHeader>
                                                        <DriverAccountStatValue>{entity.cvFileName || "-"}</DriverAccountStatValue>

                                                        <DriverAccountHeader>Employment history</DriverAccountHeader>
                                                        {!entity.employments?.length &&
                                                            <DriverAccountStatValue>Empty history</DriverAccountStatValue>}

                                                        {entity.employments && entity.employments.map(employment =>
                                                            <DriverAccountStatValue>{employment.companyName}: {printDate(employment.employeedFrom)} - {employment.isCurrentEmployment ? "until now" : printDate(employment.employeedTo)}</DriverAccountStatValue>
                                                        )}

                                                    </Flex.Column>
                                                </div>
                                                <div data-label='Preferences'>
                                                    <Flex.Column gap={10}>
                                                        <Flex.Row horizontal={HorizontalAlign.right} marginTop={20}>
                                                            <Button icon={edit} size="nopadding" height={30} width={30} onClick={() => setIsEditDriverAccountPreferencesModalOpened(true)} />
                                                        </Flex.Row>
                                                        <DriverAccountStats hasBorder name="Employment types" value="25.07.1993" />
                                                        <DriverAccountStats hasBorder name="Preferef freight types" value={entity.preferredTruckTypes?.map(t => freightTypes.find(x => x.id == t)?.name)?.join(", ")} />
                                                        <DriverAccountStats hasBorder name="Roure preference" value={entity.preferredRouteTypes?.map(t => routeTypes.find(x => x.id == t)?.name)?.join(", ")} />
                                                        <DriverAccountStats hasBorder name="Travel preference" value="25.07.1993" />
                                                        <DriverAccountStats hasBorder name="Interested in lease purchase" value={entity.isInterestedInLease ? "Yes" : "No"} />
                                                        <DriverAccountStats hasBorder name="Solo/team" value={entity.isTeam ? "Yes" : entity.isTeam === false ? "No" : "Both"} />
                                                    </Flex.Column>
                                                </div>
                                            </Tabpanel>
                                        </Flex.Column>
                                    </DriverAccountTabCard>
                                </Flex.Row>
                            </Flex.Column>
                        </DriverAccountTab>
                    </div>
                    <div data-label='Dashboard'>
                        <DriverAccountTab>
                            <DriverAccountTabCard>
                                <DriverAccountHeader>Recommended jobs</DriverAccountHeader>
                                <DriverAccountSubHeader>These jobs were picked just for you:</DriverAccountSubHeader>
                                <DriverAccountTabCardHr />
                                <Flex.Column gap={20} horizontal={HorizontalAlign.center} marginTop={40}>
                                    {jobs?.entities?.map(x => (
                                        <JobCard
                                            key={x.id}
                                            id={x.id}
                                            name={x.name}
                                            logo={FileService.downloadLink(x.companyAvatarFileId)}
                                            background={FileService.downloadLink(x.companyBackgroundFileId)}
                                            regionName={x.regions?.join(', ')}
                                            rating={x.averageCompanyRating}
                                            jobsCount={x.companyJobsCount}
                                            reviewsCount={x.companyRatingsCount}
                                            salariesCount={x.companyJobsCount}
                                            jobType={x.jobType}
                                            truckType={x.truckType}
                                            drivingLicenseCategory={x.drivingLicenseCategory}
                                            experienceRequired={x.experienceRequired}
                                            description={x.description} />
                                    ))}
                                </Flex.Column>
                            </DriverAccountTabCard>
                        </DriverAccountTab>
                    </div>
                    <div data-label='Job applications sent'>
                        <DriverAccountTab>
                            {companies && (<DriverAccountTabCard>
                                <Flex.Row horizontal={Space.between} vertical={VerticalAlign.center}>
                                    <DriverAccountSubHeader>Showing 1 to {companies.length} of {companies.length} entries</DriverAccountSubHeader>
                                    {/* <Flex.Row flex={0}>
                                        <Button variant="primary" width={100}>Previous</Button>
                                        <Button variant="primary" width={50}>Current</Button>
                                        <Button variant="primary" width={50}>Next</Button>
                                    </Flex.Row> */}
                                </Flex.Row>
                                <DriverAccountTabCardHr />
                                <Flex.Column gap={10} margin='40px 0'>
                                    <Flex.Row gap={10}>
                                        <DriverAccountTableHeader>Company</DriverAccountTableHeader>
                                        <DriverAccountTableHeader>Job</DriverAccountTableHeader>
                                        <DriverAccountTableHeader>Date/Time</DriverAccountTableHeader>
                                    </Flex.Row>
                                    {companies.map(x => (
                                        <Flex.Row gap={10}>
                                            <DriverAccountTableColumn>{x.companyName}</DriverAccountTableColumn>
                                            <DriverAccountTableColumn>{x.jobName}</DriverAccountTableColumn>
                                            <DriverAccountTableColumn>{printDate(x.createdOn, true)}</DriverAccountTableColumn>
                                        </Flex.Row>))}
                                </Flex.Column>
                                <DriverAccountTabCardHr />
                                <Flex.Row horizontal={Space.between} vertical={VerticalAlign.center} marginTop={10}>
                                    <DriverAccountSubHeader>Showing 1 to {companies.length} of {companies.length} entries</DriverAccountSubHeader>
                                    {/* <Flex.Row flex={0}>
                                        <Button variant="primary" width={100} size="small">Previous</Button>
                                        <Button variant="primary" width={50} size="small">Current</Button>
                                        <Button variant="primary" width={50} size="small">Next</Button>
                                    </Flex.Row> */}
                                </Flex.Row>
                            </DriverAccountTabCard>)
                            }
                        </DriverAccountTab>
                    </div>
                </Tabpanel>
            </DriverAccountPageComponent >

            {isEditDriverAccountPersonalInfoModalOpened && <DriverAccountPersonalInfoEditModal id={id!} setIsOpen={setIsEditDriverAccountPersonalInfoModalOpened}></DriverAccountPersonalInfoEditModal>}
            {isEditDriverAccountDirverInfoModalOpened && <DriverAccountDriverInfoEditModal id={id!} setIsOpen={setIsEditDriverAccountDirverInfoModalOpened}></DriverAccountDriverInfoEditModal>}
            {isEditDriverAccountPreferencesModalOpened && <DriverAccountPreferencesEditModal id={id!} setIsOpen={setIsEditDriverAccountPreferencesModalOpened}></DriverAccountPreferencesEditModal>}
            {isEditDriverAccountEmploymentsModalOpened && <DriverAccountEmploymentsEditModal id={id!} setIsOpen={setIsEditDriverAccountEmploymentsModalOpened}></DriverAccountEmploymentsEditModal>}
        </>
    );
};

const DriverAccountStat = styled.div<{ hasBorder: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    border-bottom: ${props => props.hasBorder ? `3px solid ${colors.cardBodyBackgroundGray}` : 'none'};
`;
const DriverAccountStatName = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    color: ${colors.commentGray};
    flex: 50% 0 0;
`;
const DriverAccountStatValue = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: ${colors.commentGray};
    text-overflow: ellipsis;
    overflow: hidden;
`;
const DriverAccountStats: React.FC<{ name: string, value: string | number | null | undefined, hasBorder?: boolean }> = (props) => (
    <DriverAccountStat hasBorder={props.hasBorder ?? false}>
        <DriverAccountStatName>{props.name}</DriverAccountStatName>
        <DriverAccountStatValue>{props.value}</DriverAccountStatValue>
    </DriverAccountStat>);

export default DriverAccountPage;