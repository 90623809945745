import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../enums';

/** Положение иконки от текста */
export type ButtonIconPosition = 'left' | 'right' | 'center';
export type ButtonVariants = 'primary' | 'dark' | 'gray' | 'transparent' | 'primary-transparent';
export type ButtonSize = 'small' | 'large' | 'nopadding';

export interface Props {
    /** Состояние активности */
    isDisabled?: boolean;
    /** Размер */
    size?: ButtonSize;
    /** Иконка кнопки */
    icon?: string;
    /** Позиция иконки (справа/слева от текста) */
    iconPosition?: ButtonIconPosition;
    /** Позиция иконки (справа/слева от текста) */
    variant?: ButtonVariants;
    /** Состояние загрузки спиннер */
    isLoading?: boolean;
    /** Текст кнопки */
    children?: string | React.ReactNode;
    /** Действие при клике */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    /** перевёрнута ли кнопка (для сорта)*/
    iconUpsideDown?: boolean;
    /** сделать ли иконку серой */
    iconGrayScale?: boolean;
    /** Высота кнопки */
    height?: number;
    /** Ширина кнопки */
    width?: number;
    /** Текст в тултипе */
    title?: string;
    /** Класс компонента для возможности переопределения */
    className?: string;
}

/** Стилизация компонента Button */
const ButtonComponent = styled.button<Props>`
    className: ${(props) => props && props.className};
    font-family: ${fonts.main};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props) => props.size == 'large' ? 15 : (props.size == 'nopadding' ? 0 : 8)}px ${(props) => props.size == 'large' ? 40 : (props.size == 'nopadding' ? 0 : 35)}px;
    color: ${colors.primaryBlue};
    background-color: inherit; 
    border: none;
    border-radius: 4px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 37px;
    outline: none;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
    height: ${(props) => (props.height ? `${props.height}px` : 'fit-content')};
    ${(props) => {
        switch (props.variant) {
            case 'primary':
                return `
                    color: ${colors.primaryBlue};
                    background-color: ${colors.primaryYellow};`;
            case 'dark':
                return `
                    color: ${colors.white};
                    background-color: ${colors.primaryBlue};`;
            case 'gray':
                return `
                    color: ${colors.inputText};
                    background-color: ${colors.selectBackgroundGray};`;
            case 'transparent':
                return `
                    color: ${colors.primaryBlue};
                    background-color: ${colors.transparent};`;
            case 'primary-transparent':
                return `
                    color: ${colors.primaryYellow};
                    background-color: ${colors.transparent};`;
            default:
                return '';
        }
    }};
`;

/** Стилизация иконки. Позиционирование слева либо справа от текста. */
export const Icon = styled.img<Omit<Props, 'children'>>`
    ${(props) => {
        switch (props.iconPosition) {
            case 'left':
                return 'margin-right: 8px;';
            case 'center':
                return 'margin: 0';
            default:
                return 'order: 2; margin-left: 8px;';
        }
    }};
    transform: scaleY(${(props) => (props.iconUpsideDown ? '-1' : '1')}); 
    ${(props) => (props.iconGrayScale ? 'filter: grayscale(1);' : '')}
    max-width: 60px;
    max-height: 60px;
`;

/** Компонент кнопки */
const Button: React.FC<Props> = ({
    isLoading,
    children,
    isDisabled,
    onClick,
    icon,
    iconPosition = 'left',
    iconUpsideDown,
    height,
    size,
    variant,
    width,
    iconGrayScale = false,
    title,
    className
}) => (
    <ButtonComponent
        variant={variant}
        disabled={isDisabled}
        height={height}
        icon={icon}
        iconPosition={iconPosition}
        isLoading={isLoading}
        onClick={onClick}
        size={size}
        title={title}
        width={width}
        className={className}
    >
        <>
            {
                icon && (
                    <Icon
                        alt="icon"
                        iconGrayScale={iconGrayScale}
                        iconPosition={iconPosition}
                        iconUpsideDown={iconUpsideDown}
                        src={icon}
                    />
                )
            }
            {children}
        </>
    </ButtonComponent>
);

export default Button;
