import { ApiConnection } from './ApiConnection';

/** Сервис для запросов файлов */
class FileService {
    /** Базовый URL для сервиса */
    static get RoutePrefix(): string {
        return '/api/v1.0/File';
    }

    /** Получить список сущностей по фильтру */
    static downloadLink(id: string | null | undefined): string | null {
        if (!id)
            return null;
        return `${ApiConnection.getUri() || ''}${this.RoutePrefix}/${id}/Download`;
    }

    /** Получить список сущностей по фильтру */
    static async upload(files: Array<File> | null | undefined): Promise<UploadFileResponse> {
        if (!files || !files.length)
            throw "Empty files collection";

        let formData = new FormData();

        [...files].forEach((file) => {
            formData.append("files", file);
        });

        const response = await ApiConnection.postForm(`${this.RoutePrefix}`, formData);
        return response.data;
    }
}
export default FileService;


/**
 * История работы водителя
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * Соотношение имен файлов и идентификаторов созданных записей в БД.
     * @type {string}
     * @memberof UploadFileResponse
     */
    fileNameToFileId?: Record<string, string> | null;
}