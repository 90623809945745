import styled from "styled-components";
import Flex from "../components/ui/flex/Flex";
import Button from "../components/ui/Button";
import { Space, VerticalAlign } from "../components/ui/flex/enums";
import { colors, fonts, truckTypes } from "../enums";
import star from '../assets/icons/star.svg';
import Tabpanel from "../components/ui/Tabpanel";
import Gallery from "react-photo-gallery";
import ReviewCard from "../components/ReviewCard";
import Accordion from "../components/ui/Accordion";
import { useEffect, useState } from "react";
import CompanyService, { GetCompanyByIdResponse, GetCompanyByIdResponseRating } from "../services/api/CompanyService";
import noCardLogo from '../assets/icons/noCardLogo.svg';
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import FileService from "../services/api/FileService";
import { formatNumber, setDocumentTitle } from "../services/utils";
import { routes } from "../App";
import { SearchFilterKeys } from "../types";
import { Helmet } from "react-helmet";
import AuthenticationService from "../services/api/AuthenticationService";
import { getCurrentUserId } from "../services/api/ApiConnection";

const CompanyPageComponent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const CompanyPageText = styled.div<{ color?: string }>`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 57px;
    margin: 3px;
    text-transform: uppercase;
    color: ${props => props.color || colors.commentGray}; 
`;
const CompanyPageUl = styled.ul`
    list-style: none;
    text-transform: none;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 57px;
    margin: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${colors.commentGray}; 
    &:before {
        content:"";
        background-color: ${colors.commentGray}; 
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin-right: 15px;
    }
`;
const CompanyPageHeaderImage = styled.div<{ background: string | null | undefined }>`
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    background: ${props => props.background ? `url(${props.background})` : colors.cardBodyBackgroundGray};
    background-size: cover;
    background-position: center;
`;
const CompanyPageHeaderImageLogo = styled.img`
    margin: 66px; 100px;
    height: 350px;
    width: 350px;
    border-radius: 50%;
`;
const CompanyPageHeaderStats = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 150px;
    border-radius: 0 0 30px 30px;
    background-color: ${colors.cardBodyBackgroundGray};
`;
const CompanyPageHeaderStatsStar = styled.div`
    background-image: url(${star});
    background-size: contain;
    height: 30px;
    width: 33px;
    margin-left: 7px;
`;
/** Страница с просмотром компании */
const CompanyPage: React.FC = () => {
    const [entity, setEntity] = useState<GetCompanyByIdResponse | undefined>();
    const { id } = useParams<string>();
    const navigate = useNavigate();

    const initialize = async () => {
        if (!id)
            return;
        const response = await CompanyService.getById(id);
        setEntity(response);
        setDocumentTitle(response?.name ?? `Company ${id}`, `Page with company ${response?.name ?? id}`);
    };

    useEffect(() => { initialize(); }, []);

    if (!entity)
        return (<CompanyPageComponent>Entity not found</CompanyPageComponent>);

    const averageCompanyRating = entity.ratings?.length
        ? entity.ratings.reduce((sum, x) => sum + (x.rating ?? 0), 0) / entity.ratings.length
        : 0;

    const schemaScript = () => (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org/",
                    "@type": "Organization",
                    "name": entity.name,
                    "logo": FileService.downloadLink(entity.avatarFileId),
                    "url": entity.site,
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": entity.address,
                        "addressLocality": entity.address,
                        "addressRegion": entity.address,
                        "postalCode": entity.address,
                        "addressCountry": "United States"
                    }
                })}
            </script>
            <script type="application/ld+json">
                {JSON.stringify({
                    "aggregateRating": {
                        "@context": "https://schema.org/",
                        "@type": "EmployerAggregateRating",
                        "itemReviewed": {
                            "@type": "Organization",
                            "sameAs": entity.site,
                            "name": entity.name
                        },
                        "ratingValue": averageCompanyRating,
                        "bestRating": Math.max(...entity.ratings?.map(x => x.rating ?? 0) ?? []) ?? 0,
                        "worstRating": Math.min(...entity.ratings?.map(x => x.rating ?? 0) ?? []) ?? 0,
                        "ratingCount": entity.ratings?.length ?? 0
                    }
                })}
            </script>
            {entity.ratings?.map(rating => (
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": rating.rating ?? 0
                        },
                        "author": {
                            "@type": "Person",
                            "name": `${rating.isEmployeeCurrent ? 'Current' : 'Former'} Employee - ${rating.jobTitle}`
                        },
                        "reviewBody": rating.review,
                        "itemReviewed": {
                            "@context": "https://schema.org/",
                            "@type": "Organization",
                            "name": entity.name,
                            "sameAs": entity.site
                        }
                    })}
                </script>))}
        </Helmet>);

    /** Обновить данные после лайка */
    const onLike = async (ratingId: string) => {
        // await CompanyService.like(id!, ratingId!);
        const rate = entity.ratings?.find(x => x.id == ratingId);
        if (!rate || !getCurrentUserId())
            return;

        const newRate = { ...rate, likedUsers: [...(rate.likedUsers ?? []), getCurrentUserId()] };
        setEntity(entity => ({
            ...entity,
            ratings: [...entity!.ratings!.filter(x => x.id != ratingId), newRate]
        }));
    }

    return (
        <CompanyPageComponent>
            <CompanyPageHeaderImage background={FileService.downloadLink(entity.backgroundFileId)}>
                <CompanyPageHeaderImageLogo
                    src={FileService.downloadLink(entity.avatarFileId) ?? noCardLogo}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noCardLogo;
                    }} />
            </CompanyPageHeaderImage>
            <CompanyPageHeaderStats>
                <Flex.Column flexInitial>
                    <Flex.Row gap={45} vertical={VerticalAlign.center}>
                        <Flex.Row gap={7} vertical={VerticalAlign.center}>
                            <CompanyPageText as='h1' color={colors.primaryYellow}>{formatNumber(averageCompanyRating, 1)}</CompanyPageText>
                            <CompanyPageHeaderStatsStar />
                        </Flex.Row>
                        <CompanyPageText>{entity.ratings?.length ?? 0} reviews</CompanyPageText>
                    </Flex.Row>
                    <Flex.Row gap={45} vertical={VerticalAlign.center}>
                        <CompanyPageText color={colors.primaryBlue}>{entity.name}</CompanyPageText>
                        <CompanyPageText>{entity.regions?.join(', ')}</CompanyPageText>
                    </Flex.Row>
                </Flex.Column>
                <Flex.Column vertical={Space.between} flexInitial>
                    <Button variant="primary" onClick={() => navigate(routes.rateCompany(id!))}>Write a review</Button>
                    <Button variant="primary" onClick={() => navigate({
                        pathname: routes.jobs,
                        search: createSearchParams({
                            [SearchFilterKeys.companyId]: id!
                        }).toString()
                    })}>See all jobs</Button>
                </Flex.Column>
            </CompanyPageHeaderStats>
            <Flex.Column margin='0 100px'>
                <Tabpanel>
                    <div data-label='Overview'>
                        <CompanyPageTabHeader
                            founded={entity.yearOfFoundation}
                            employeesCount={entity.currentDriversCount}
                            salaryGross={0}
                            salaryPercent={0} />
                        <Flex.Column marginTop={40}>
                            <CompanyPageText as='h2' color={colors.primaryBlue}>Trucking company description:</CompanyPageText>
                            <CompanyPageText as='p'>{entity.description}</CompanyPageText>
                        </Flex.Column>
                        {/* <Flex.Row wrap marginTop={40}>
                            <Flex.Column flex={1}>
                                <CompanyPageText color={colors.primaryBlue}>Pros:</CompanyPageText>
                                {entity.pros.map(x => (
                                    <CompanyPageUl
                                        key={x}
                                        color={colors.commentGray}>{x}</CompanyPageUl>))}
                            </Flex.Column>
                            <Flex.Column flex={1}>
                                <CompanyPageText color={colors.primaryBlue}>Cons:</CompanyPageText>
                                {entity.cons.map(x => (
                                    <CompanyPageUl
                                        key={x}
                                        color={colors.commentGray}>{x}</CompanyPageUl>))}
                            </Flex.Column>
                        </Flex.Row> */}
                        <Flex.Column marginTop={40}>
                            <CompanyPageText color={colors.primaryBlue}>The trucking company works in: {entity.regions?.join(', ')}</CompanyPageText>
                        </Flex.Column>
                        <Flex.Column gap={10} marginTop={40}>
                            {entity.faq?.map(x => (<Accordion textComponent="h3" key={x.question} title={x.question}>{x.answer}</Accordion>))}
                        </Flex.Column>
                    </div>
                    <div data-label='Reviews'>
                        <CompanyPageTabHeader
                            founded={entity.yearOfFoundation}
                            employeesCount={entity.currentDriversCount}
                            salaryGross={0}
                            salaryPercent={0} />
                        <Flex.Column marginTop={60} gap={60}>
                            {entity?.ratings?.map(x => <ReviewCard
                                cons={x.cons}
                                createdOn={x.createdOn}
                                id={x.id}
                                likedUsers={x.likedUsers}
                                onLike={onLike}
                                companyId={id ?? ''}
                                jobTitle={x.jobTitle}
                                pros={x.pros}
                                rating={x.rating}
                                review={x.review}
                                key={x.id}
                                textComponent="h4" />)}
                        </Flex.Column>
                    </div>
                    <div data-label='Photo'>
                        <CompanyPageTabHeader
                            founded={entity.yearOfFoundation}
                            employeesCount={entity.currentDriversCount}
                            salaryGross={0}
                            salaryPercent={0} />
                        <Gallery photos={entity.imageFiles?.map(x => ({
                            src: FileService.downloadLink(x.fileId) ?? '',
                            width: 2,
                            height: 2
                        })) || []} />
                    </div>
                </Tabpanel>
            </Flex.Column>
            {schemaScript()}
        </CompanyPageComponent>
    );
};

const CompanyPageTabHeader: React.FC<{
    salaryPercent?: number | null,
    salaryGross?: number | null,
    founded?: number | null,
    employeesCount?: number | null,
}> = ({
    salaryPercent,
    salaryGross,
    founded,
    employeesCount,
}) => (
        <Flex.Column vertical={VerticalAlign.center} marginTop={50}>
            <CompanyPageText as='h3' color={colors.primaryBlue}>
                {'Salary company drivers: ' + [(salaryPercent ? (`${salaryPercent}% CPM`) : null), (salaryGross ? (`${salaryGross} GROSS`) : null)].filter(x => x).join('/')}
            </CompanyPageText>
            <Flex.Row flexInitial marginTop={30}>
                <Flex.Column flex={1}>
                    <CompanyPageText color={colors.commentGray}>Founded: {founded}</CompanyPageText>
                </Flex.Column>
                <Flex.Column flex={1}>
                    <CompanyPageText color={colors.commentGray}>Employees: {employeesCount}</CompanyPageText>
                </Flex.Column>
            </Flex.Row>
        </Flex.Column>
    );

export default CompanyPage;