import styled from "styled-components";
import Select, { SelectOptionsType } from "../components/ui/Select";
import Flex from "../components/ui/flex/Flex";
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import Checkbox from "../components/ui/Checkbox";
import FilterBox from "../components/FilterBox";
import JobCard from "../components/JobCard";
import { HorizontalAlign } from "../components/ui/flex/enums";
import { useParams, useSearchParams } from "react-router-dom";
import { Catalogs, cdlTypes, experienceTypes, jobTypes, routeTypes, SalaryTypes, salaryTypes, truckTypes } from "../enums";
import CatalogService from "../services/api/CatalogService";
import { useEffect, useState } from "react";
import JobService, { GetJobsResponseItem } from "../services/api/JobService";
import InfiniteScroll from 'react-infinite-scroll-component';
import FileService from "../services/api/FileService";
import { setDocumentTitle } from "../services/utils";
import { SearchFilter } from "../types";
import { Helmet } from "react-helmet";
import { routes } from "../App";

const JobsPageComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 90px; 40px;
    gap: 150px;
`;
const JobsPageFilters = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0;
    min-width: 350px;
`;
const JobsPageCards = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const salaryTypeOptions = salaryTypes.map(x => ({ label: x.name, value: x.id.toString() }));
const orderByOptions = [
    { label: 'Rating (lowest first)', value: '+averageCompanyRating', orderBy: 'averageCompanyRating', isAscending: true },
    { label: 'Rating (highest first)', value: '-averageCompanyRating', orderBy: 'averageCompanyRating', isAscending: false },
    { label: 'Salary (lowest first)', value: '+salaryGross', orderBy: 'salaryGross', isAscending: true },
    { label: 'Salary (highest first)', value: '-salaryGross', orderBy: 'salaryGross', isAscending: false },
];

/** Страница со списком вакансий */
const JobsPage: React.FC = () => {
    const { tag } = useParams<string>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [regions, setRegions] = useState<SelectOptionsType[] | undefined>();
    const [entities, setEntities] = useState<{
        list: GetJobsResponseItem[],
        totalCount: number | null,
        pageNumber: number
    }>({
        list: [],
        totalCount: null,
        pageNumber: 1
    });
    const [orderBy, setOrderBy] = useState<SelectOptionsType | null>(orderByOptions[0]);
    const filter = new SearchFilter().fromTag(tag).fromSearchParams(searchParams);
    filter.regionId ??= regions?.find(x => x.label?.toLowerCase() == tag)?.value ?? null;

    const initialize = async () => {
        const response = await CatalogService.getById(Catalogs.Regions);
        setRegions(response?.values?.map(x => ({ label: x.name || '', value: x.id || '' })));
        setDocumentTitle('Jobs search', `It's time to search some jobs`);
    };

    const loadEntities = async (isReload: boolean = false) => {
        const pageNumber = isReload ? 1 : entities.pageNumber;
        const list = isReload ? [] : entities.list;

        const response = await JobService.get({
            companyId: filter.companyId || undefined,
            drivingLicenseCategory: filter.drivingLicenseCategory || undefined,
            experienceFrom: experienceTypes.find(x => x.id == filter.experienceType)?.experienceFrom,
            experienceTo: experienceTypes.find(x => x.id == filter.experienceType)?.experienceTo,
            jobType: filter.jobType || undefined,
            minCompanyRating: filter.minCompanyRating || undefined,
            regionId: filter.regionId ? [filter.regionId] : undefined,
            routeType: filter.routeType,
            salaryAsPercent: filter.salaryType == SalaryTypes.Percent.toString() ? filter.salary : undefined,
            salaryGross: filter.salaryType == SalaryTypes.Gross.toString() ? filter.salary : undefined,
            salaryPerMile: filter.salaryType == SalaryTypes.PerMile.toString() ? filter.salary : undefined,
            truckType: filter.truckType || undefined,
            searchText: filter.searchText || undefined,
            isTeam: filter.isTeam,
            pageSize: 10,
            pageNumber: pageNumber,
            orderBy: orderBy?.orderBy as string,
            isAscending: orderBy?.isAscending as boolean,
        });

        const loaded = response?.entities ?? [];
        setEntities({
            list: list.concat(loaded.filter(entity => !list.find(x => x.id == entity.id))),
            totalCount: response?.totalCount ?? 0,
            pageNumber: pageNumber + 1
        });
    };

    useEffect(() => { initialize(); }, []);
    useEffect(() => { loadEntities(true); }, [searchParams, orderBy]);

    const schemaScript = () => (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "ItemList",
                    "numberOfItems": entities.totalCount,
                    "itemListElement": [
                        entities.list.map((x, index) => ({
                            "@type": "ListItem",
                            "position": index,
                            "url": routes.jobByIdAndName(x.id!, x.name!)
                        }))
                    ]
                })}
            </script>
        </Helmet>);

    return (
        <JobsPageComponent>
            <JobsPageFilters>
                <FilterBox title="Filters" size="large" textComponent="h2">
                    <Flex.Row>
                        <Select
                            options={regions}
                            value={regions?.find(x => x.value == filter.regionId)}
                            onChangeValue={x => { filter.regionId = x?.value || null; setSearchParams(filter.toSearchParams(searchParams)); }}
                            placeholder='States' />
                        <Button variant='dark' size="small" height={45}
                            onClick={() => { filter.regionId = null; setSearchParams(filter.toSearchParams(searchParams)); }}>Any</Button>
                    </Flex.Row>
                    <FilterBox title="Salary from" textComponent="h3">
                        <Flex.Row>
                            <Flex.Column flex={1}>
                                <Select
                                    variant="dark"
                                    options={salaryTypeOptions}
                                    value={salaryTypeOptions.find(x => filter.salaryType == x.value)}
                                    onChangeValue={x => { filter.salaryType = x?.value || null; setSearchParams(filter.toSearchParams(searchParams)); }}
                                    placeholder='CPM / %' />
                            </Flex.Column>
                            <Flex.Column flex={1}>
                                <Input
                                    inputType="number"
                                    value={filter.salary ?? undefined}
                                    onChangeValue={x => { filter.salary = x && parseInt(x) || null; setSearchParams(filter.toSearchParams(searchParams)); }} />
                            </Flex.Column>
                        </Flex.Row>
                    </FilterBox>
                    <FilterBox title="Minimum experience" textComponent="h3">
                        <Flex.Column>
                            {experienceTypes.map(tt => (<Checkbox
                                key={tt.id}
                                isChecked={filter.experienceType == tt.id}
                                onClick={isChecked => {
                                    if (isChecked) {
                                        filter.experienceType = tt.id;
                                        setSearchParams(filter.toSearchParams(searchParams));
                                    }
                                }}
                                text={tt.name}
                                type="radiobutton" />))}
                        </Flex.Column>
                    </FilterBox>
                    <FilterBox title="Trailer" textComponent="h3">
                        <Flex.Column>
                            {
                                truckTypes.map(tt => (<Checkbox
                                    key={tt.id}
                                    type="checkbox"
                                    isChecked={!!filter.truckType.find(x => x == tt.id)}
                                    onClick={isChecked => {
                                        filter.truckType = isChecked
                                            ? filter.truckType.concat(tt.id).filter((x, i, a) => a.indexOf(x) == i)
                                            : filter.truckType.filter(x => x != tt.id);
                                        setSearchParams(filter.toSearchParams(searchParams));
                                    }}
                                    text={tt.name} />))}
                        </Flex.Column>
                    </FilterBox>
                    <FilterBox title="Route preference" textComponent="h3">
                        <Flex.Column>
                            {routeTypes.map(tt => (<Checkbox
                                key={tt.id}
                                type="checkbox"
                                isChecked={!!filter.routeType.find(x => x == tt.id)}
                                onClick={isChecked => {
                                    filter.routeType = isChecked
                                        ? filter.routeType.concat(tt.id).filter((x, i, a) => a.indexOf(x) == i)
                                        : filter.routeType.filter(x => x != tt.id);
                                    setSearchParams(filter.toSearchParams(searchParams));
                                }}
                                text={tt.name} />))}
                        </Flex.Column>
                    </FilterBox>
                    <FilterBox title="CDL type" textComponent="h3">
                        <Flex.Row gap={40}>
                            {cdlTypes.map(tt => (<Checkbox
                                key={tt.id}
                                type="checkbox"
                                isChecked={!!filter.drivingLicenseCategory.find(x => x == tt.id)}
                                onClick={isChecked => {
                                    filter.drivingLicenseCategory = isChecked
                                        ? filter.drivingLicenseCategory.concat(tt.id).filter((x, i, a) => a.indexOf(x) == i)
                                        : filter.drivingLicenseCategory.filter(x => x != tt.id);
                                    setSearchParams(filter.toSearchParams(searchParams));
                                }}
                                text={tt.name} />))}
                        </Flex.Row>
                    </FilterBox>
                    <FilterBox title="Team" textComponent="h3">
                        <Checkbox
                            type="checkbox"
                            isChecked={filter.isTeam ?? false}
                            onClick={isChecked => { filter.isTeam = isChecked; setSearchParams(filter.toSearchParams(searchParams)); }}
                            text='Team' />
                    </FilterBox>
                    <FilterBox title="For driver" textComponent="h3">
                        {jobTypes.map(tt => (<Checkbox
                            key={tt.id}
                            type="checkbox"
                            isChecked={!!filter.jobType.find(x => x == tt.id)}
                            onClick={isChecked => {
                                filter.jobType = isChecked
                                    ? filter.jobType.concat(tt.id).filter((x, i, a) => a.indexOf(x) == i)
                                    : filter.jobType.filter(x => x != tt.id);
                                setSearchParams(filter.toSearchParams(searchParams));
                            }}
                            text={tt.name} />))}
                    </FilterBox>
                </FilterBox>
            </JobsPageFilters>
            <JobsPageCards>
                <Flex.Row maxWidth={400} flexInitial>
                    <Select
                        variant="dark"
                        options={orderByOptions}
                        value={orderBy}
                        onChangeValue={x => setOrderBy(x)}
                        placeholder='Sort by' />
                </Flex.Row>
                <Flex.Column gap={40} horizontal={HorizontalAlign.left} marginTop={50}>
                    <InfiniteScroll
                        dataLength={entities.list.length ?? 0}
                        next={() => loadEntities()}
                        style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
                        hasMore={entities.totalCount == null || entities.list.length < entities.totalCount}
                        loader={<h4>Loading...</h4>}
                    >
                        {entities.list.map(x => (<JobCard
                            key={x.id}
                            id={x.id}
                            name={x.name}
                            logo={FileService.downloadLink(x.companyAvatarFileId)}
                            background={FileService.downloadLink(x.companyBackgroundFileId)}
                            regionName={x.regions?.join(', ')}
                            rating={x.averageCompanyRating}
                            jobsCount={x.companyJobsCount}
                            reviewsCount={x.companyRatingsCount}
                            salariesCount={x.companyJobsCount}
                            jobType={x.jobType}
                            truckType={x.truckType}
                            drivingLicenseCategory={x.drivingLicenseCategory}
                            experienceRequired={x.experienceRequired}
                            description={x.description}
                            textComponent="h3" />))}
                    </InfiniteScroll>
                </Flex.Column>
            </JobsPageCards>
            {schemaScript()}
        </JobsPageComponent>
    );
};

export default JobsPage;