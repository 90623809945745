import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/ui/Button";
import { HorizontalAlign, VerticalAlign } from "../components/ui/flex/enums";
import Flex from "../components/ui/flex/Flex";
import Input, { Label } from "../components/ui/Input";
import Rating from "../components/ui/Rating";
import Select, { SelectOptionsType } from "../components/ui/Select";
import { colors, fonts, MAX_SAFE_INTEGER32 } from "../enums";
import CompanyService from '../services/api/CompanyService';
import FileService from "../services/api/FileService";
import { setDocumentTitle } from "../services/utils";
import { FormState } from "../types";
import { getCurrentUserId } from "../services/api/ApiConnection";

const RateCompanyPageComponent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 90px;
`;
const RateCompanyText = styled.div<{ color?: string, variant: 'title' | 'comment' }>`
    text-transform: uppercase;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: ${props => props.variant == 'title' ? 38 : 24}px;
    line-height:${props => props.variant == 'title' ? 57 : 40}px;
    color: ${props => props.color || colors.primaryBlue};
    margin-top: 10px;
`;
const RateCompanyLogo = styled.div<{ image?: string | null | undefined }>`
    display: flex;
    height: 100px;
    width: 100px;
    background: ${props => props.image ? (`url(${props.image})`) : '#5E5E5E'};
    color: #B2B2B2;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
`;
const RateCompanyBoolean = styled.div<{ isActive?: boolean }>`
    display: flex;
    height: 45px;
    width: 45px;
    background: ${props => props.isActive ? '#5E5E5E' : '#DDDDDD'};
    color: ${props => props.isActive ? colors.white : '#5E5E5E'};
    padding: 0 60px;
    text-transform: uppercase;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
`;

const pros: SelectOptionsType[] = ['pro1', 'pro2', 'pro3'].map(x => ({ label: x, value: x }));
const cons: SelectOptionsType[] = ['con1', 'con2', 'con3'].map(x => ({ label: x, value: x }));

/** Страница для простановки рейтинга компании */
const RateCompanyPage: React.FC = () => {
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const [formState, setFormState] = useState<FormState>(FormState.Empty());
    const [companies, setCompanies] = useState<SelectOptionsType[]>([]);
    const [entity, setEntity] = useState<{
        companyId?: string | null,
        rating?: number | null,
        employedOn?: Date | string | null | undefined,
        firedOn?: Date | string | null | undefined,
        jobTitle?: string | null | undefined,
        review?: string | null | undefined,
        pros: string[],
        cons: string[],
        isEmployeeCurrent: boolean
    }>({
        isEmployeeCurrent: true,
        companyId: id,
        pros: [],
        cons: [],
    });

    /** Загрузить необходимые справочники */
    const initialize = async () => {
        const response = await CompanyService.get({
            pageNumber: 1,
            pageSize: MAX_SAFE_INTEGER32,
            orderBy: 'name',
            isAscending: true
        });
        const options = response.entities?.map(x => ({
            label: x.name || '',
            value: x.id || '',
            avatarFileId: x.avatarFileId
        }));
        setCompanies(options || []);
        setDocumentTitle('Rate a company', `Writing a review for a company`);
    };

    /** Сохранить отзыв */
    const save = async () => {
        try {
            setFormState(x => x.clear());

            if (!getCurrentUserId()) {
                setFormState(x => x.setError('Only authenticated users can leave a company review'));
                return;
            }

            await CompanyService.rate(entity.companyId!, {
                cons: entity.cons,
                employedOn: entity.employedOn,
                firedOn: entity.firedOn,
                isEmployeeCurrent: entity.isEmployeeCurrent,
                jobTitle: entity.jobTitle,
                pros: entity.pros,
                rating: entity.rating || 0,
                review: entity.review,
            });
            navigate(-1);
        } catch (error) {
            setFormState(x => x.setError(error));
        } finally {
            setFormState(x => x.setLoading(false));
        }
    };

    useEffect(() => { initialize(); }, []);

    const company = companies.find(x => x.value == entity.companyId);
    return (
        <RateCompanyPageComponent>
            <RateCompanyText variant="title">Rate a company</RateCompanyText>
            {formState.error && <RateCompanyText variant="comment" color={colors.errorTextColor}>{formState.error}</RateCompanyText>}
            <RateCompanyText variant="comment" color={colors.commentGray}>
                It only takes a minute! and your anonymous review will help other job seekers.
            </RateCompanyText>
            <Flex.Row marginTop={40} vertical={VerticalAlign.bottom} gap={40} flexInitial>
                <RateCompanyLogo image={FileService.downloadLink(company?.avatarFileId as string)}>
                    {company?.avatarFileId ? null : 'LOGO'}
                </RateCompanyLogo>
                <Select
                    label="Company"
                    isDisabled={!!id}
                    value={companies.find(x => x.value == entity.companyId)}
                    onChangeValue={x => setEntity({ ...entity, companyId: x?.value })}
                    options={companies}
                    placeholder="Search the company" />
            </Flex.Row>
            <Flex.Row marginTop={40} flexInitial>
                <Rating height={60}
                    value={entity.rating}
                    onChangeValue={x => setEntity({ ...entity, rating: x })}
                    label="Overall rating"
                    isRequired={true} />
            </Flex.Row>
            <Flex.Column marginTop={40} flexInitial>
                <Label>Are you a current or former employee?</Label>
                <Flex.Row gap={20}>
                    <RateCompanyBoolean
                        isActive={entity.isEmployeeCurrent}
                        onClick={() => setEntity({ ...entity, isEmployeeCurrent: true })}>Current</RateCompanyBoolean>
                    <RateCompanyBoolean
                        isActive={!entity.isEmployeeCurrent}
                        onClick={() => setEntity({ ...entity, isEmployeeCurrent: false })}>Former</RateCompanyBoolean>
                </Flex.Row>
            </Flex.Column>
            <Flex.Column marginTop={40} flexInitial>
                <Label>Years of employment</Label>
                <Flex.Row gap={10} horizontal={HorizontalAlign.left}>
                    <Input
                        value={entity.employedOn?.toString()}
                        onChangeValue={x => setEntity({ ...entity, employedOn: x })}
                        label="From"
                        inputType="date" />
                    <Input
                        value={entity.firedOn?.toString()}
                        onChangeValue={x => setEntity({ ...entity, firedOn: x })}
                        label="To"
                        inputType="date" />
                </Flex.Row>
            </Flex.Column>
            <Flex.Column marginTop={40} flexInitial>
                <Input value={entity.jobTitle || ''} onChangeValue={x => setEntity({ ...entity, jobTitle: x })} label="Your job title" />
            </Flex.Column>
            <Flex.Column marginTop={40} flexInitial>
                <Input
                    label="Review text"
                    value={entity.review || ''}
                    onChangeValue={x => setEntity({ ...entity, review: x })}
                    inputType="textarea"
                    placeholder="5 words minimum"
                    isRequired />
            </Flex.Column>
            <Flex.Column marginTop={40} flexInitial>
                <Select label="Pros"
                    value={entity.pros.map(x => ({ label: x, value: x }))}
                    options={pros}
                    placeholder="Share some of the best reasons to work at"
                    onCreateOption={x => { pros.push(x); setEntity({ ...entity, pros: entity.pros.concat(x.value) }) }}
                    onMultiSelect={x => setEntity({ ...entity, pros: x.map(i => i.value) })} />
            </Flex.Column>
            <Flex.Column marginTop={40} flexInitial>
                <Select label="Cons"
                    value={entity.cons.map(x => ({ label: x, value: x }))}
                    options={cons}
                    placeholder="Share some of the downsides of working at"
                    onCreateOption={x => { cons.push(x); setEntity({ ...entity, cons: entity.cons.concat(x.value) }) }}
                    onMultiSelect={x => setEntity({ ...entity, cons: x.map(i => i.value) })} />
            </Flex.Column>
            <Flex.Row marginTop={40} gap={30} flexInitial horizontal={HorizontalAlign.center}>
                <Button variant="transparent" onClick={() => navigate(-1)}>Cancel</Button>
                <Button variant="primary" onClick={save}>Post review</Button>
            </Flex.Row>
        </RateCompanyPageComponent>
    );
};

export default RateCompanyPage;