import styled from "styled-components";
import { colors, fonts } from "../enums";
import { Space } from "./ui/flex/enums";
import Flex from "./ui/flex/Flex";
import star from '../assets/icons/star.svg';
import noCardLogo from '../assets/icons/noCardLogo.svg';
import Button from "./ui/Button";
import { createSearchParams, useNavigate } from "react-router-dom";
import { routes } from "../App";
import { SearchFilterKeys } from "../types";
import { formatNumber } from "../services/utils";

const CompanyCardComponent = styled.div`
    display: flex;
    flex-direction: row;
    padding: 40px;
    gap: 80px;
    background-color: ${colors.cardBodyBackgroundGray};
    border-radius: 10px;
    max-width: 1000px;
    align-items: center;
`;
const CompanyCardIcon = styled.img`
    height: 320px !important;
    width: 320px !important;
    border-radius: 160px;
`;
const CompanyCardRating = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 59px;
    color: ${colors.primaryYellow};
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const CompanyCardRatingStar = styled.div`
    background-image: url(${star});
    height: 35px;
    width: 35px;
    margin-left: 7px;
    background-size: cover;
`;
const CompanyCardCounter = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 56px;
    color: ${colors.commentGray};
`;
const CompanyCardTitle = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 59px;
    color: ${colors.primaryBlue};
    margin-top: 15px;
    text-align: left;
`;
const CompanyCardRegion = styled.div`
    font-family: ${fonts.main};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 49px;
    color: ${colors.commentGray};
    margin-top: 3px;
    text-align: left;
`;
const CompanyCardDescription = styled.div`
    text-overflow: ellipsis;
    margin-top: 25px;
    overflow: hidden;
    color: ${colors.cardDescriptionBlack};
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-align: left;
`;
const CompanyCard: React.FC<{
    id: string,
    rating: number | undefined,
    name: string | null | undefined,
    description?: string | null | undefined,
    region: string | undefined,
    reviewsCount?: number | undefined,
    jobsCount?: number | undefined,
    icon?: string | null | undefined,
    textComponent?: keyof JSX.IntrinsicElements,
}> = (props) => {
    const navigate = useNavigate();

    return (<CompanyCardComponent>
        <CompanyCardIcon
            src={props.icon ?? noCardLogo}
            alt='Иконка компании'
            onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = noCardLogo;
            }} />
        <Flex.Column>
            <Flex.Row horizontal={Space.between}>
                <CompanyCardRating>
                    {formatNumber(props.rating, 1)}
                    <CompanyCardRatingStar />
                </CompanyCardRating>
                <CompanyCardCounter>{props.reviewsCount} reviews</CompanyCardCounter>
                <CompanyCardCounter>{props.jobsCount} jobs</CompanyCardCounter>
            </Flex.Row>
            <CompanyCardTitle as={props.textComponent ?? 'div'}>{props.name}</CompanyCardTitle>
            <CompanyCardRegion as={props.textComponent ?? 'div'}>{props.region}</CompanyCardRegion>
            <CompanyCardDescription>{props.description}</CompanyCardDescription>
            <Flex.Row horizontal={Space.between} marginTop={20} gap={20}>
                <Button variant="primary" onClick={() => navigate({
                    pathname: routes.jobs,
                    search: createSearchParams({
                        [SearchFilterKeys.companyId]: props.id
                    }).toString()
                })}> See jobs</Button>
                <Button variant="primary" onClick={() => navigate(routes.companyById(props.id))}>Read reviews</Button>
            </Flex.Row>
        </Flex.Column>
    </CompanyCardComponent>);
};

export default CompanyCard;