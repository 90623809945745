import { Space, VerticalAlign } from "../components/ui/flex/enums";
import styled from 'styled-components';
import { useEffect, useState, useRef, ChangeEvent } from "react";
import Flex from "../components/ui/flex/Flex";
import { Catalogs, cdlTypes, colors, fonts, jobTypes, routeTypes, freightTypes } from "../enums";
import { SelectOptionsType } from "../components/ui/Select";
import { Size } from "../components/ui/Input";
import Modal, { ModalRow, ModalSelect, ModalInput, ModalFooterButton, ModalButton } from "../components/Modal";
import { BooleanRadio, RadioOptionsAlign } from "../components/ui/Radio";
import CatalogService from "../services/api/CatalogService";
import DriverService, { UpdateDriverRequest, CreateDriverRequestEmployment } from "../services/api/DriverService";
import FileService from "../services/api/FileService";
import { deleteMetadata } from "core-js/fn/reflect";

const ScrollableBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
    overflow: auto;
`;

const EmploymentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;
`;

const EmploymentTitle = styled.label`
    font-size: 23px;
    font-weight: 400;
    text-transform: uppercase;
`;

const EmploymentButton = styled.label`
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 20px;
`;

const freightTypesOptions = freightTypes.map(x => ({ label: x.name, value: x.id.toString() }));

const DriverAccountEmploymentsEditModal: React.FC<{
    id: string,
    setIsOpen?: (isOpen: boolean) => void
}> = (props) => {
    // Список штатов
    const [regions, setRegions] = useState<SelectOptionsType[] | undefined>();

    // Информация об аккаунте водителя
    const [entity, setEntity] = useState<UpdateDriverRequest>({});

    // Upload поле для CV
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [cvFileName, setCvFileName] = useState<string | null>();

    /**
     * Метод инициализации страницы.
     */
    const initialize = async () => {
        // Подгружаем список штатов
        const response = await CatalogService.getById(Catalogs.Regions);
        setRegions(response?.values?.map(x => ({ label: x.name || '', value: x.id || '' })));

        // Подгружаем профиль пользователя
        const entityResponse = await DriverService.getById(props.id);
        setEntity(entityResponse);
        setCvFileName(entityResponse?.cvFileName);
    };

    /**
     * Обновить данные о профиле пользователя
     */
    const update = async () => {
        try {
            // setFormState(x => x.clear());
            await DriverService.update(entity);
            props.setIsOpen && props.setIsOpen(false);
        } catch (error) {
            // setFormState(x => x.setError(error));
        } finally {
            // setFormState(x => x.setLoading(false));
        }
    };

    const addNewEmployment = () => {
        setEntity({ ...entity, employments: entity?.employments?.concat({}) || [{}] })
    };

    const updateEmployment = (employmentToUpdate: CreateDriverRequestEmployment, employmentState: CreateDriverRequestEmployment) => {
        const newEmployments = entity?.employments?.map(employment => {
            if (employment == employmentToUpdate) {
                return employmentState;
            }
            return employment;
        });

        setEntity({ ...entity, employments: newEmployments || [] });
    }

    const deleteEmployment = (employmentToDelete: CreateDriverRequestEmployment) => {
        setEntity({ ...entity, employments: entity?.employments?.filter(x => x != employmentToDelete) || [] })
    }

    /**
     * We redirect the click event onto the hidden input element.
     */
    const handleUploadClick = () => {
        inputRef.current?.click();
    };

    /**
     * Загрузить файл на сервер и получить его Id.
     * @param e Выбранный файл.
     */
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) {
            return;
        }

        const selectedFile = e.target.files[0];

        try {
            const response = await FileService.upload([selectedFile]);
            if (!response || !response.fileNameToFileId)
                throw "Unexpected error during file upload";

            // Преобразовали все названия файлов к нижнему регистру, чтобы он не мешал сравнивать элементы
            const lowerCaseDictionary = Object.fromEntries(Object.entries(response.fileNameToFileId).map(([k, v]) => [k.toLowerCase(), v]));

            const fileId = lowerCaseDictionary
                ? lowerCaseDictionary[selectedFile.name.toLowerCase()]
                : null;

            if (!fileId)
                throw "Unexpected error during file upload";

            setEntity({ ...entity, cvFileId: fileId });
            setCvFileName(selectedFile.name);

        } catch (error) {

        }
    };


    useEffect(() => { initialize(); }, []);

    return (
        <Modal headerText="Edit experience" setIsOpen={props.setIsOpen}>
            <ScrollableBody>
                <Flex.Column>
                    <ModalRow>
                        <ModalSelect
                            variant="primary"
                            label='Freight types (select all that apply)'
                            placeholder='Freight types'
                            size={Size.S} />

                        <ModalInput
                            inputType="number"
                            label='Total experience (years)'
                            placeholder='Total experience'
                            value={entity?.totalExpirience ?? undefined}
                            onChangeValue={x => setEntity({ ...entity, totalExpirience: x && parseInt(x) || null })}
                            size={Size.S} />
                    </ModalRow>

                    <ModalRow>
                        <input
                            type="file"
                            ref={inputRef}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />

                        <ModalInput
                            label='Upload CV'
                            value={cvFileName ?? undefined}
                            placeholder='Select file to upload'
                            isDisabled={true}
                            size={Size.S} />

                        <ModalButton variant="gray" onClick={handleUploadClick}>Browse</ModalButton>
                    </ModalRow>

                    {entity?.employments?.map(employment => (
                        <EmploymentContainer>
                            <ModalRow>
                                <EmploymentTitle>{employment.companyName || "New company"}</EmploymentTitle>
                                <EmploymentButton onClick={() => deleteEmployment(employment)}>Delete</EmploymentButton>
                            </ModalRow>

                            <ModalRow>
                                <ModalInput
                                    inputType="text"
                                    value={employment.companyName || undefined}
                                    onChangeValue={(x) => updateEmployment(employment, { ...employment, companyName: x })}
                                    label='Company'
                                    placeholder='Company'
                                    size={Size.S} />

                                <BooleanRadio
                                    label="Current employer"
                                    size={Size.S}
                                    value={employment.isCurrentEmployment || false}
                                    onChange={(x) => updateEmployment(employment, { ...employment, isCurrentEmployment: x })}
                                    align={RadioOptionsAlign.horizontal}
                                ></BooleanRadio>

                                <ModalSelect
                                    label="State"
                                    variant="primary"
                                    options={regions ?? []}
                                    value={regions?.find(x => x.value == employment.regionId)}
                                    onChangeValue={(x) => updateEmployment(employment, { ...employment, regionId: x?.value })}
                                    placeholder='State'
                                    size={Size.S} />
                            </ModalRow>

                            <ModalRow>
                                <ModalInput
                                    label='Phone'
                                    placeholder='Phone'
                                    value={employment.phone ?? undefined}
                                    onChangeValue={(x) => updateEmployment(employment, { ...employment, phone: x })}
                                    size={Size.S} />

                                <ModalInput
                                    value={employment.employeedFrom?.toString()}
                                    onChangeValue={(x) => updateEmployment(employment, { ...employment, employeedFrom: x })}
                                    label="Employed from"
                                    inputType="date"
                                    size={Size.S} />

                                <ModalInput
                                    value={employment.employeedTo?.toString()}
                                    onChangeValue={(x) => updateEmployment(employment, { ...employment, employeedTo: x })}
                                    label="Employed to"
                                    inputType="date"
                                    size={Size.S} />
                            </ModalRow>
                        </EmploymentContainer>
                    ))}

                    <EmploymentButton onClick={(addNewEmployment)}>+ Add more</EmploymentButton>

                </Flex.Column>

                <Flex.Row horizontal={Space.between} vertical={VerticalAlign.center} flex={0}>
                    <ModalFooterButton variant="dark" onClick={() => props.setIsOpen && props.setIsOpen(false)}>Cancel</ModalFooterButton>
                    <ModalFooterButton variant="primary" onClick={update}>Save</ModalFooterButton>
                </Flex.Row>
            </ScrollableBody>
        </Modal >
    );
};

export default DriverAccountEmploymentsEditModal;