import React from 'react';
import styled from 'styled-components';
import star from '../../assets/icons/star.svg';
import starEmpty from '../../assets/icons/starEmpty.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Flex from './flex/Flex';
import { colors, fonts } from '../../enums';
import { Label } from './Input';

export interface Props {
    /** текст справа */
    text?: string;
    /** задизаблено */
    disabled?: boolean;
    /** обязательное поле */
    isRequired?: boolean;
    /** текст в инпуте */
    value?: number | null | undefined;
    /** лейбл */
    label?: string | null;
    /** высота звездочки в пикселях */
    height?: number;
    /** функция обрабатывающая при изменении данных в инпуте */
    onChangeValue?: (value: number) => void;
}

const RatingComponent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;
    flex-wrap: nowrap;
    align-items: center;
    color: ${colors.commentGray};
    &:hover {
        color: ${colors.primaryYellow};
    }
`;
const RatingStar = styled.div<{ isFilled: boolean, height: number, disabled: boolean }>`
    background-image: url(${props => props.isFilled ? star : starEmpty});
    height: ${props => props.height}px;
    width: ${props => props.height + 2}px;
    cursor: ${props => props.disabled ? 'none' : 'pointer'};
    background-size: cover;
`;
const RatingText = styled.div`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    margin-left: 10px;
    white-space: nowrap;
    text-transform: uppercase;
`;
/** Компонент рейтинга со звездами */
const Rating: React.FC<Props> = ({
    text,
    value = 0,
    height = 30,
    isRequired,
    label,
    disabled = false,
    onChangeValue
}) => (
    <Flex.Column flexInitial>
        {label && (<Label isRequired={isRequired}>{label}</Label>)}
        <RatingComponent>
            {Array.from(Array(5).keys()).map(x => (
                <RatingStar
                    disabled={disabled}
                    key={x}
                    isFilled={(value ?? 0) >= x + 1}
                    height={height}
                    onClick={() => onChangeValue && onChangeValue(x + 1)} />))}
            {text && <RatingText>{text}</RatingText>}
        </RatingComponent>
    </Flex.Column>
);

export default Rating;
