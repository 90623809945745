import styled from 'styled-components';
import { HorizontalAlign, VerticalAlign, Space } from './enums';

/** Пропсы флекс контейнера */
interface ContainerProps {
  /** Установить по умолчанию flex-grow, flex-shrink, flex-basis */
  flexInitial?: boolean;
  /** Установить flex в значение */
  flex?: number | string;
  /** Установить ширину */
  maxWidth?: number | string;
  /** Применять ли адаптацию сносом вниз */
  wrap?: boolean;
  /** Промежутки между строками в пикселях */
  gap?: number;
  /** Маргин для контейнера в пикселях */
  margin?: number | string;
  /** Маргин для верха контейнера в пикселях */
  marginTop?: number;
}

/** Пропсы флекс строки */
interface RowProps {
  /** Расположение контента по горизонтали */
  horizontal?: HorizontalAlign | Space;
  /** Расположение контента по вертикали */
  vertical?: VerticalAlign | 'baseline' | Space.stretch;
  /** Класс компонента для возможности переопределения */
  className?: string;
}

/** Пропсы флекс столбца */
interface ColumnProps {
  /** Расположение контента по горизонтали */
  horizontal?: HorizontalAlign | Space.stretch;
  /** Расположение контента по вертикали */
  vertical?: VerticalAlign | Space;
  /** Класс компонента для возможности переопределения */
  className?: string;
}

/** Флекс контейнер */
const Container = styled.div<ContainerProps>`
  className: ${(props) => props && props.className};
  display: flex;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  flex: ${(props) => (props.flexInitial ? 'initial' : (props.flex == undefined ? 1 : props.flex))};
  ${(props) => props.maxWidth ? (Number.isInteger(props.maxWidth) ? `max-width: ${props.maxWidth}px;` : `max-width: ${props.maxWidth};`) : ''}
  ${(props) => props.gap ? `gap: ${props.gap}px` : ''};
  ${(props) => props.margin ? (Number.isInteger(props.margin) ? `margin: ${props.margin}px;` : `margin: ${props.margin};`) : ''}
  ${(props) => props.marginTop ? (`margin-top: ${props.marginTop}px;`) : ''};
`;

/** Флекс строка */
const Row = styled(Container) <RowProps>`
  className: ${(props) => props && props.className};
  flex-direction: row;
  justify-content: ${(props) => props.horizontal};
  align-items: ${(props) => props.vertical};
`;

/** Флекс столбец */
const Column = styled(Container) <ColumnProps>`  
  flex-direction: column;
  justify-content: ${(props) => props.vertical};
  align-items: ${(props) => props.horizontal};
`;

export default {
  Container, Row, Column,
};
