import { cdlTypes, experienceTypes, jobTypes, routeTypes, truckTypes } from "./enums";

/** варианты типов данных инпута */
export type InputTypes =
    | 'date'
    | 'dateTime'
    | 'text'
    | 'phone'
    | 'email'
    | 'checkingAccount'
    | 'password'
    | 'number'
    | 'name'
    | 'surname'
    | 'patronymic'
    | 'companyNameFull'
    | 'postCode'
    | 'emailUnrequired'
    | 'website'
    | 'money'
    | 'login'
    | 'file'
    | 'select'
    | 'multiselect'
    | 'textarea';

/**  класс состояния формы */
export class FormState {
    /** зыгрузка */
    isLoading?: boolean;

    /** ошибка */
    error?: string;

    /** Код ошибки */
    errorCode?: number;

    constructor(
        isLoading: boolean = false,
        error: string | undefined = undefined,
        errorCode: number | undefined = undefined) {
        this.isLoading = isLoading;
        this.error = error;
        this.errorCode = errorCode;
    }

    /** Добавить ошибку */
    setError(error?: unknown): FormState {
        console.error(error);
        this.isLoading = false;
        this.error = (error as ServerError)?.title || (error as string) || 'Unexpected error occurred';
        this.errorCode = (error as ServerError)?.status;
        return this.clone();
    };

    /** Установить лоадинг */
    setLoading(isLoading: boolean = true): FormState {
        this.isLoading = isLoading;
        return this.clone();
    };

    /** Очистить */
    clear(): FormState {
        this.isLoading = true;
        this.error = undefined;
        this.errorCode = undefined;
        return this.clone();
    };

    /** Клонировать */
    clone(): FormState {
        return new FormState(this.isLoading, this.error, this.errorCode);
    }

    /** Пустое состояние */
    static Empty(): FormState {
        return new FormState(
            false,
            undefined,
            undefined);
    };
}

/** Интерфейс для объекта ошибки */
export interface ServerError extends Error {
    /** Тип ошибки */
    type?: string;

    /** Заголовок ошибки */
    title?: string;

    /** Номер статуса ошибки */
    status?: number;

    /** Детали ошибки */
    detail?: string;

    /** Пример ошибки */
    instance?: string;

    /** id ошибки */
    errorId?: string;
}

export const SearchFilterKeys = {
    salary: 'salary',
    salaryType: 'salaryType',
    companyId: 'companyId',
    searchText: 'searchText',
    minCompanyRating: 'minCompanyRating',
    truckType: 'truckType',
    routeType: 'routeType',
    regionId: 'regionId',
    jobType: 'jobType',
    isTeam: 'isTeam',
    drivingLicenseCategory: 'drivingLicenseCategory',
    experienceType: 'experienceType',
};

/** Фильтр для поиска */
export class SearchFilter {
    minCompanyRating: number | null;
    searchText: string | null;
    salary: number | null;
    salaryType: string | null;
    regionId: string | null;
    companyId: string | null;
    experienceType: number | null;
    jobType: number[];
    truckType: number[];
    routeType: number[];
    drivingLicenseCategory: number[];
    isTeam: boolean | null;

    constructor() {
        this.minCompanyRating = null;
        this.searchText = null;
        this.salary = null;
        this.salaryType = null;
        this.regionId = null;
        this.companyId = null;
        this.jobType = [];
        this.truckType = [];
        this.routeType = [];
        this.experienceType = null;
        this.drivingLicenseCategory = [];
        this.isTeam = null;
    }

    /** Заполнить из querystring */
    fromSearchParams(searchParams: URLSearchParams): SearchFilter {
        this.minCompanyRating = searchParams.get('minCompanyRating')
            ? parseInt(searchParams.get('minCompanyRating')!)
            : null;
        this.companyId = searchParams.get('companyId');
        this.searchText = searchParams.get('searchText');
        this.salary = searchParams.get('salary')
            ? parseInt(searchParams.get('salary')!)
            : null;
        this.salaryType = searchParams.get('salaryType');
        this.truckType = (this.truckType ?? []).concat(searchParams.get('truckType')?.split(',')?.map(x => parseInt(x)) ?? []);
        this.routeType = (this.routeType ?? []).concat(searchParams.get('routeType')?.split(',')?.map(x => parseInt(x)) ?? []);
        this.regionId = searchParams.get('regionId');
        this.jobType = (this.routeType ?? []).concat(searchParams.get('jobType')?.split(',')?.map(x => parseInt(x)) ?? []);
        this.isTeam = searchParams.get('isTeam') == 'true'
            ? true
            : (searchParams.get('isTeam') == 'false' ? false : null);
        this.drivingLicenseCategory = (this.drivingLicenseCategory ?? []).concat(
            searchParams.get('drivingLicenseCategory')
                ?.split(',')
                ?.map(x => parseInt(x)) ?? []);
        this.experienceType = searchParams.get('experienceType') && parseInt(searchParams.get('experienceType')!) || null;
        return this;
    }

    /** Заполнить фильтр из тэга */
    fromTag(tag: string | undefined): SearchFilter {
        if (!tag)
            return this;

        if (truckTypes.find(x => x.tag == tag))
            (this.truckType ??= []).push(truckTypes.find(x => x.tag == tag)!.id);
        if (jobTypes.find(x => x.tag == tag))
            (this.jobType ??= []).push(jobTypes.find(x => x.tag == tag)!.id);
        if (experienceTypes.find(x => x.tag == tag))
            this.experienceType = experienceTypes.find(x => x.tag == tag)!.id;
        if (routeTypes.find(x => x.tag == tag))
            (this.routeType ??= []).push(routeTypes.find(x => x.tag == tag)!.id);
        if (cdlTypes.find(x => x.tag == tag))
            (this.drivingLicenseCategory ??= []).push(cdlTypes.find(x => x.tag == tag)!.id);
        if (tag == 'team')
            this.isTeam = true;
        if (tag == 'solo')
            this.isTeam = false;
        return this;
    }

    /** Закинуть параметры обратно в querystring */
    toSearchParams(searchParams: URLSearchParams): URLSearchParams {
        for (let key in this) {
            let serializedValue = this[key] as any;
            if (Array.isArray(serializedValue))
                serializedValue = serializedValue.join(',');

            if (serializedValue == null || serializedValue == '' || serializedValue == undefined)
                searchParams.delete(key);
            else
                searchParams.set(key, serializedValue.toString());
        }
        return searchParams;
    };
};