import styled from "styled-components";
import Flex from "../components/ui/flex/Flex";
import Button from "../components/ui/Button";
import { Space, VerticalAlign } from "../components/ui/flex/enums";
import { colors, fonts, JobTypes, truckTypes } from "../enums";
import star from '../assets/icons/star.svg';
import Tabpanel from "../components/ui/Tabpanel";
import Gallery from "react-photo-gallery";
import noCardLogo from '../assets/icons/noCardLogo.svg';
import ReviewCard from "../components/ReviewCard";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JobService, { GetJobByIdResponse } from "../services/api/JobService";
import FileService from "../services/api/FileService";
import { formatNumber, setDocumentTitle } from "../services/utils";
import { Helmet } from "react-helmet";
import { getCurrentUserId, isUserDriver } from "../services/api/ApiConnection";
import CompanyService from "../services/api/CompanyService";

const JobPageComponent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const JobPageText = styled.div<{ color?: string }>`
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 57px;
    margin: 3px;
    text-transform: uppercase;
    color: ${props => props.color || colors.commentGray}; 
`;
const JobPageUl = styled.li`
    list-style: none;
    text-transform: none;
    font-family: ${fonts.main};
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 57px;
    margin: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${colors.commentGray}; 
    &:before {
        content:"";
        background-color: ${colors.commentGray}; 
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin-right: 15px;
    }
`;
const JobPageHeaderImage = styled.div<{ background: string | null | undefined }>`
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    background: ${props => props.background ? `url(${props.background})` : colors.cardBodyBackgroundGray};
    background-size: cover;
    background-position: center;
`;
const JobPageHeaderImageLogo = styled.img`
    margin: 66px; 100px;
    height: 350px;
    width: 350px;
    border-radius: 50%;
`;
const JobPageHeaderStats = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 150px;
    border-radius: 0 0 30px 30px;
    background-color: ${colors.cardBodyBackgroundGray};
`;
const JobPageHeaderStatsStar = styled.div`
    background-image: url(${star});
    background-size: contain;
    height: 30px;
    width: 33px;
    margin-left: 7px;
`;
const JobPageHeaderStatsTruck = styled.img`
    width: 170px;
`;
/** Страница с просмотром вакансии */
const JobPage: React.FC = () => {
    const [entity, setEntity] = useState<GetJobByIdResponse | undefined>();
    const { id } = useParams<string>();
    const navigate = useNavigate();

    const initialize = async () => {
        if (!id)
            return;
        const response = await JobService.getById(id);
        setEntity(response);
        setDocumentTitle(response?.name ?? `Job ${id}`, `Page with job ${response?.name ?? id}`);
    };

    useEffect(() => { initialize(); }, []);

    if (!entity)
        return (<JobPageComponent>Entity not found</JobPageComponent>);

    const averageCompanyRating = entity.ratings?.length
        ? entity.ratings.reduce((sum, x) => sum + (x.rating ?? 0), 0) / entity.ratings.length
        : 0;

    const truckTypeValue = truckTypes.find(x => x.id == entity.truckType);

    const schemaScript = () => (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org/",
                    "@type": "JobPosting",
                    "title": entity.name,
                    "description": entity.description,
                    "datePosted": entity.createdOn,
                    "hiringOrganization": {
                        "@type": "Organization",
                        "name": entity.companyName,
                        "sameAs": entity.companySite
                    },
                    "employmentType": ["CONTRACTOR", "FULL_TIME"],
                    "baseSalary": {
                        "@type": "MonetaryAmount",
                        "currency": "USD",
                        "value": {
                            "@type": "QuantitativeValue",
                            "value": entity.salaryGross,
                            "minValue": entity.salaryPerMile ?? 0 * 2000,
                            "maxValue": entity.salaryPerMile ?? 0 * 3000,
                            "unitText": "WEEK"
                        }
                    },
                    "jobLocation": {
                        "@type": "Place",
                        "address": {
                            "streetAddress": "[company address]",
                            "addressLocality": "[company city]",
                            "addressRegion": "[company state]",
                            "postalCode": "[company zip]",
                            "addressCountry": "United States"
                        }
                    }
                })}
            </script>
            {entity.ratings?.map(rating => (
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": rating.rating ?? 0
                        },
                        "author": {
                            "@type": "Person",
                            "name": `${rating.isEmployeeCurrent ? 'Current' : 'Former'} Employee - ${rating.jobTitle}`
                        },
                        "reviewBody": rating.review,
                        "itemReviewed": {
                            "@context": "https://schema.org/",
                            "@type": "Organization",
                            "name": entity.companyName,
                            "sameAs": entity.companySite
                        }
                    })}
                </script>))}
        </Helmet>);

    /** Обновить данные после лайка */
    const onLike = async (ratingId: string) => {
        // await CompanyService.like(entity.companyId!, ratingId!);
        const rate = entity.ratings?.find(x => x.id == ratingId);
        if (!rate || !getCurrentUserId())
            return;

        const newRate = { ...rate, likedUsers: [...(rate.likedUsers ?? []), getCurrentUserId()] };
        setEntity(entity => ({
            ...entity,
            ratings: [...entity!.ratings!.filter(x => x.id != ratingId), newRate]
        }));
    }

    /** Податься на вакансию */
    const apply = async () => {
        if (!isUserDriver()) {
            console.log('Пользовтель не водитель');
            return;
        }
        if (!id) {
            console.log('Не задан ид вакансии');
            return;
        }

        await JobService.apply({
            jobId: id
        });
        console.log('Заявка на вакансию отправлена');
    };

    return (
        <JobPageComponent>
            <JobPageHeaderImage background={FileService.downloadLink(entity.companyBackgroundFileId)}>
                <JobPageHeaderImageLogo
                    src={FileService.downloadLink(entity.companyAvatarFileId) ?? noCardLogo}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noCardLogo;
                    }} />
            </JobPageHeaderImage>
            <JobPageHeaderStats>
                <Flex.Column flexInitial>
                    <Flex.Row gap={45} vertical={VerticalAlign.center}>
                        <Flex.Row gap={7} vertical={VerticalAlign.center}>
                            <JobPageText color={colors.primaryYellow}>{formatNumber(averageCompanyRating, 1)}</JobPageText>
                            <JobPageHeaderStatsStar />
                        </Flex.Row>
                        <JobPageText>{entity.ratings?.length ?? 0} reviews</JobPageText>
                    </Flex.Row>
                    <Flex.Row gap={45} vertical={VerticalAlign.center}>
                        <JobPageText as="h1" color={colors.primaryBlue}>{entity.name}</JobPageText>
                        <JobPageText>{entity.regions?.join(', ')}</JobPageText>
                    </Flex.Row>
                    <JobPageText as="h2">
                        {entity.jobType == JobTypes.OwnerOperator ? 'Owner operator job' : 'Company driver job'}
                    </JobPageText>
                </Flex.Column>
                {truckTypeValue && <Flex.Column vertical={Space.between} flexInitial>
                    <JobPageHeaderStatsTruck src={truckTypeValue.icon}></JobPageHeaderStatsTruck>
                    <JobPageText as="h3">{truckTypeValue.name}</JobPageText>
                </Flex.Column>}
            </JobPageHeaderStats>
            <Flex.Column margin='0 100px'>
                <Tabpanel>
                    <div data-label='Overview'>
                        <JobPageTabHeader salaryGross={entity.salaryGross} salaryPercent={entity.salaryAsPercent} apply={apply} />
                        <Flex.Row wrap marginTop={40}>
                            <Flex.Column flex={1}>
                                <JobPageText as="h3" color={colors.primaryBlue}>Benefits:</JobPageText>
                                {entity.benefits?.map(x => (
                                    <JobPageUl key={x} color={colors.commentGray}>{x}</JobPageUl>))}
                            </Flex.Column>
                            <Flex.Column flex={1}>
                                <JobPageText color={colors.primaryBlue}>Requirements:</JobPageText>
                                {entity.requirements?.map(x => (
                                    <JobPageUl key={x} color={colors.commentGray}>{x}</JobPageUl>))}
                            </Flex.Column>
                        </Flex.Row>
                        <Flex.Column marginTop={40}>
                            <JobPageText as="h2" color={colors.primaryBlue}>Job description:</JobPageText>
                            <JobPageText as="p">{entity.description}</JobPageText>
                        </Flex.Column>
                        <Flex.Column marginTop={40}>
                            <JobPageText as="h2" color={colors.primaryBlue}>States to work in: {entity.regions?.join(', ')}</JobPageText>
                        </Flex.Column>
                    </div>
                    <div data-label='Reviews'>
                        <JobPageTabHeader salaryGross={entity.salaryGross} salaryPercent={entity.salaryAsPercent} apply={apply} />
                        <Flex.Column marginTop={60} gap={60}>
                            {entity.ratings?.map(x => <ReviewCard
                                cons={x.cons}
                                companyId={entity.companyId ?? ''}
                                createdOn={x.createdOn}
                                id={x.id}
                                jobTitle={x.jobTitle}
                                pros={x.pros}
                                rating={x.rating}
                                review={x.review}
                                likedUsers={x.likedUsers}
                                onLike={id => onLike(id)}
                                key={x.id}
                                textComponent="h3" />)}
                        </Flex.Column>
                    </div>
                    <div data-label='Photo'>
                        <JobPageTabHeader salaryGross={entity.salaryGross} salaryPercent={entity.salaryAsPercent} apply={apply} />
                        <Gallery photos={entity.imageFiles?.map(x => ({
                            src: FileService.downloadLink(x.fileId)!,
                            width: 2,
                            height: 2
                        })) || []} />
                    </div>
                </Tabpanel>
            </Flex.Column>
            {schemaScript()}
        </JobPageComponent>
    );
};

const JobPageTabHeader: React.FC<{
    salaryPercent?: number | null,
    salaryGross?: number | null,
    apply: React.MouseEventHandler<HTMLButtonElement>,
}> = ({
    salaryPercent,
    salaryGross,
    apply,
}) => (
        <Flex.Row vertical={VerticalAlign.center} horizontal={Space.between} marginTop={50}>
            <JobPageText as="h3" color={colors.primaryBlue}>
                {'Salary: ' + [(salaryPercent ? (`${salaryPercent}% CPM`) : null), (salaryGross ? (`${salaryGross} GROSS`) : null)].filter(x => x).join('/')}
            </JobPageText>
            <Flex.Row gap={20} flexInitial>
                <Button variant="primary" onClick={apply} isDisabled={!isUserDriver()} > Apply for the job</Button>
                <Button variant="primary" onClick={apply} isDisabled={!isUserDriver()}>Apply with one click</Button>
            </Flex.Row>
        </Flex.Row >
    );

export default JobPage;