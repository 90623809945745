import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import previous from '../../assets/icons/previous.svg';
import next from '../../assets/icons/next.svg';

export interface Props {
    /** Отображаемые элементы */
    children: React.ReactNode | Array<React.ReactNode>;
    /** Действие при клике */
    onCarouselPageChange?: (index: number, item: React.ReactNode) => void
}

/** Компонент карусели */
const CarouselComponent: React.FC<Props> = ({
    onCarouselPageChange,
    children
}) => {
    const childrenArray = Array.isArray(children) ? children : [children];

    return (
        <div style={{
            maxWidth: 1000,
            margin: 'auto'
        }}>
            <Carousel
                onChange={onCarouselPageChange}
                autoPlay={false}
                infiniteLoop={false}
                showArrows={true}
                showStatus={false}
                showIndicators={true}
                renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                    hasPrev && (
                        <img
                            onClick={clickHandler}
                            style={{
                                height: "60px",
                                width: "35px",
                                cursor: 'pointer',
                                top: '40%',
                                left: 0,
                                position: 'absolute',
                                pointerEvents: 'all',
                                zIndex: 999999999999999999
                            }}
                            src={previous} />
                    )
                }
                renderArrowNext={(clickHandler, hasNext, labelNext) =>
                    hasNext && (
                        <img
                            onClick={clickHandler}
                            style={{
                                height: "60px",
                                width: "35px",
                                cursor: 'pointer',
                                top: '40%',
                                right: 0,
                                position: 'absolute',
                                pointerEvents: 'all',
                                zIndex: 999999999999999999
                            }}
                            src={next} />
                    )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = {
                        marginLeft: 13,
                        backgroundColor: "#DBDBDB",
                        cursor: "pointer",
                        borderRadius: '50%',
                        width: 13,
                        height: 13,
                        display: 'inline-block'
                    };
                    const style = isSelected
                        ? { ...defStyle, backgroundColor: "rgba(18, 41, 79, 0.94902)" }
                        : { ...defStyle };
                    return (
                        <span
                            style={style}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            key={index}
                            role="button"
                            tabIndex={0}
                            aria-label={`${label} ${index + 1}`}
                        ></span>
                    );
                }}
                showThumbs={false}>
                {childrenArray?.map((x, index) => (
                    <div key={index} data-interval={5} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 50,
                        marginLeft: 50,
                        marginRight: 50,
                        height: '100%'
                    }}>
                        {x}
                    </div>))}
            </Carousel>
        </div >
    );
};

export default CarouselComponent;
