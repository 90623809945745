import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const LayoutHeader = styled.div`
    display: flex;
    flex: 0;
`;
const LayoutBody = styled.div`
    display: flex;
    flex: 1;
`;
const LayoutFooter = styled.div`
    display: flex;
    flex: 0;
`;

/** Основной компонент с контентом */
const MainLayout: React.FC = () => (
    <Layout>
        <LayoutHeader>
            <Header />
        </LayoutHeader>
        <LayoutBody>
            <Outlet />
        </LayoutBody>
        <LayoutFooter>
            <Footer />
        </LayoutFooter>
    </Layout>
);

export default MainLayout;
